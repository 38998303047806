import React, { useState, useEffect,useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { Navbar, Col, Row, Card, Button, Container, Image } from "react-bootstrap";
import Loader from "../Loader";
import { commonUtil } from "../../helper/CommonUtils";
import { faCalendar, faChevronCircleDown , faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactReadMoreReadLess from "react-read-more-read-less";
import eventPlaceholder from "../../images/event-placeholder.png";
import coursePlaceholder from "../../images/course-placeholder.png";
import subscriptionPlaceholder from "../../images/subscription-placeholder.png";
import EventModal from "../Events/EventModal";
import moment from "moment";

import {
  subadminInfo,
  subscribefree,
  GetSalesPage, GetSalesPageEventsListForMember,
  GetSalesPageCoursesListForMember,
  filteredSubscriptionList,
} from "../../helper/service";
import AnonymousFooter from "../common/AnonymousFooter";
import { shortenFileName, stripHtml } from "../../helper/fileHelper";
import ReactPlayer from "react-player";
const SalesPage = () => {
  const eventRef = useRef(null);
  const courseRef = useRef(null);
  const subscriptionRef = useRef(null);

  const { subadminId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [membershipName, setMembershipName] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");
  const [subscriptionData, setSubscriptionData] = useState("");
  const [selectedCourseData, setSelectedCourseData] = useState();
  const [selectedEventData, setSelectedEventData] = useState();
  const [brandColor, setBrandColor] = useState("");
  const [banner, setBanner] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [plansTitle, setPlanTitle] = useState("Explore Subscriptions");
  const [productTitle, setProductTitle] = useState("Explore Products");
  const [eventTitle, setEventTitle] = useState("Explore Events");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedCourse,setSelectedCourse] = useState(null);
  const [expanded, setExpanded] = useState("");
  const [selectedSubscription,setSelectedSubscription] = useState(null);
  const [selectedItem,setSelectedItem] = useState(null);
  const scrollToDiv = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleSelectCategory = (item,type) => {
    setSelectedItem(item._id);
    if(type === "course"){
      setExpanded("course");
      setSelectedCourse(item);
    }else if(type === "event"){
      setExpanded("event");
      setSelectedEvent(item);
    }else if(type === "subscription"){
      setExpanded("subscription");
      setSelectedSubscription(item);
    }
  };
  useEffect(() => {
    if (expanded === "course" && courseRef.current) {
      scrollToDiv(courseRef);
    } else if (expanded === "event" && eventRef.current) {
      scrollToDiv(eventRef);
    } else if (expanded === "subscription" && subscriptionRef.current) {
      scrollToDiv(subscriptionRef);
    }
  }, [expanded,selectedItem]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        localStorage.setItem("subadminId", subadminId);

        const data = await subadminInfo(subadminId);
        let brandColor;
        if (data?.result?.validUser) {
          brandColor = data?.result?.validUser.brandColor;
        }
        const salesPage = await GetSalesPage(subadminId);
        //console.log(salesPage.result, "salesPage.result")
        if (salesPage.result.selectedCourse) {
          GetSalesPageCoursesListForMember(
            salesPage.result.selectedCourse
          ).then((courses) => {
            saleCoursesList(
              courses?.result?.selectedCoursesSale,
              brandColor
            );
          });
        }
        if (salesPage.result.selectedEvent) {
          GetSalesPageEventsListForMember(
            salesPage.result.selectedEvent
          ).then((events) => {
            const futureEvents = events?.result?.selectedEvantsSale?.filter((event) => {
              return new Date(event?.eventDate) > new Date();
            });
            saleEventsList(
              futureEvents,
              brandColor
            );
          });
        }
        if (!salesPage?.result?.isLive) {
          commonUtil.redirect("/");
        }

        if (salesPage?.result?.accessLevels) {
          const subs = await filteredSubscriptionList(subadminId);
          const newSubs = subs.result ? subs.result.filter(item => !item.hideMembershipLevel && !item.pauseMembershipLevel)  : []
          var filteredSubscriptions = newSubs.filter((item) =>
            salesPage?.result?.accessLevels?.includes(item._id)
          );
          subscriptionList(
            filteredSubscriptions,
            brandColor
          );

          setContent(salesPage.result.copy);
          setTitle(salesPage.result.title);
          setBanner(salesPage.result.image);
          setPlanTitle(
            salesPage?.result?.plansTitle || "Explore Subscriptions"
          );
          setProductTitle(
            salesPage?.result?.productsTitle || "Explore Products"
          );
          setEventTitle(
            salesPage?.result?.eventTitle || "Explore Events"
          );

          setBrandColor(data.result.validUser.brandColor);
          localStorage.setItem("partnerBrandColor", brandColor);
          setMembershipName(data.result.validUser.membershipName);

          var hasCompany =
            data.result.validUser.company &&
            data.result.validUser.company.length > 0;
          if (hasCompany) setCompanyName(data.result.validUser.company[0].name);

          var logo =
            hasCompany && data.result.validUser.company[0].logo
              ? data.result.validUser.company[0].logo
              : data.result.validUser.image;
          setLogo(logo);

          var salesEmail = hasCompany && data.result.validUser.company[0].email
            ? data.result.validUser.company[0].email
            : data.result.validUser.email;

          setEmail(salesEmail);

          var salesCountryCode = hasCompany && data.result.validUser.company[0].countryCode
            ? data.result.validUser.company[0].countryCode
            : data.result.validUser.countryCode;
          setCountryCode(salesCountryCode);

          var salesPhone = hasCompany && data.result.validUser.company[0].contactNumber
            ? data.result.validUser.company[0].contactNumber
            : data.result.validUser.phoneNumber;
          setPhone(salesPhone);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const selectPlan = (planId, tier) => {
    let url = `/checkout/${subadminId}/${planId}/${tier}`;
    commonUtil.redirect(url);
  };
  const selectCourse = (courseId) => {
    let url = `/coursecheckout/${subadminId}/${courseId}/onetime`;
    commonUtil.redirect(url);
  };
  const selectEvent = (eventId) => {
    let url = `/eventcheckout/${subadminId}/${eventId}/onetime`;
    commonUtil.redirect(url);
  };
  
  const subscriptionList = (
    subscription,
    partnerBrandColor
  ) => {
    if (subscription) {
      const datas = subscription?.map((subs) => {
        const monthlyFeeForYearlyPlan = (subs.amountYearly / 12).toFixed(2);
        return (
          <>
            <div className="col-lg-3 col-md-4 col-sm-6 col-sm-12 mb-3">
              <Card className="explore-plans-card" style={{height:"540px"}}>
                <Card.Body>
                  <div className="sales-page-image d-flex justify-content-center p-1">
                    <Image src={subs.thumbnailImg ?? subscriptionPlaceholder} alt="Event Image" className="img-fluid" />
                  </div>
                  <div className="plan-name mt-2">
                    <span>{subs.tier}</span>
                  </div>
                  <div className="mt-3 mb-3">
                    <h6 className="fw-bold">Description</h6>
                    <p style={{fontSize:"14px"}}>{shortenFileName(stripHtml(subs.description))}</p>
                  </div>
                  <div className="d-flex flex-column justify-content-end align-items-center w-100 mt-3" style={{height:"43%"}}>
                  {subs.amountOneTime !== undefined &&
                    subs.amountOneTime !== null &&
                    subs.amountOneTime > 0 && (subs.amountMonthly === undefined ||
                      subs.amountMonthly === null &&
                      subs.amountMonthly < subs.amountOneTime) && (
                      <div className="text-center w-100">
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                          }}
                          onClick={() => selectPlan(subs._id, "onetime")}
                        >
                          <span>
                            One time purchase at {`$${subs.amountOneTime}`} AUD
                          </span>
                        </Button>
                      </div>
                    )}
                  {subs.amountYearly !== undefined &&
                    subs.amountYearly !== null &&
                    subs.amountYearly > 0 && (
                      <div className="text-center w-100">
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                            marginTop: "10px",
                            fontSize: "14px",
                          }}
                          onClick={() => selectPlan(subs._id, "yearly")}
                        >
                          <span>
                            Purchase at {`$${monthlyFeeForYearlyPlan}`} AUD / Month
                          </span>
                          <br />
                          <span>
                            <small>Charged {`$${subs.amountYearly}`} / Yearly</small>
                          </span>
                        </Button>
                      </div>
                    )}
                  {subs.amountMonthly !== undefined &&
                    subs.amountMonthly !== null &&
                    subs.amountMonthly > 0 && (
                      <div className="text-center w-100">
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                            marginTop: "10px",
                          }}
                          onClick={() => selectPlan(subs._id, "monthly")}
                        >
                          Purchase at {`$${subs.amountMonthly}`} AUD / Month
                        </Button>
                      </div>
                    )}
                  {(subs.amountMonthly == null || subs.amountMonthly <= 0) &&
                    (subs.amountYearly == null || subs.amountYearly <= 0) &&
                    (subs.amountOneTime == null || subs.amountOneTime <= 0) && (
                      <div className="text-center w-100">
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                          }}
                          onClick={() => selectPlan(subs._id, "yearly")}
                        >
                          Subscribe for Free
                        </Button>
                      </div>
                    )}
                  <div className="text-center w-100">
                    <FontAwesomeIcon
                      icon={faChevronCircleDown}
                      className="mt-3 text-center"
                      color={partnerBrandColor}
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        borderRadius: "50px",
                        boxShadow: `0px 1px 8px 1px ${partnerBrandColor}`,
                      }}
                      onClick={() => handleSelectCategory(subs,"subscription")}
                    />
                  </div>
                  </div>
                </Card.Body>
              </Card>
            </div >
          </>
        );
      });
      setSubscriptionData(datas);
    } else {
      setSubscriptionData(null);
    }
  };

  const saleCoursesList = (courses, partnerBrandColor) => {
    const datas = courses.map((course) => {
      return (
        <>
          <div className="col-lg-3 col-md-4 col-sm-6 col-sm-12 mb-3">
            <Card className="explore-plans-card">
              <Card.Body>
                <div className="sales-page-image d-flex justify-content-center p-1">
                  <Image src={course.courseImage ?? coursePlaceholder} alt="Event Image" className="img-fluid" />
                </div>
                <div className="sales-page-content" style={{height:"auto"}}>
                  <div className="plan-name">
                    <span>{course.title}</span>
                  </div>
                  <p className="mt-2 text-justify">{shortenFileName(course.shortDescription)}</p>
                  <div className="mt-2">
                    <h6 className="fw-bold">Description</h6>
                    <p className="mt-2" style={{fontSize:"14px"}} >{shortenFileName(stripHtml(course.description))}</p>
                  </div>
                  
                  {course.amount !== undefined &&
                    course.amount !== null &&
                    course.amount > 0 ? (
                    <>
                      <div className="text-center" style={{ width: "100%" }}>
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                            marginTop: "10px",
                          }}
                          onClick={() => selectCourse(course._id)}
                        >
                          Purchase at {`$${course.amount}`}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-center" style={{ width: "100%" }}>
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                            marginTop: "10px",
                          }}
                          onClick={() => selectCourse(course._id)}
                        >
                          Enroll for Free
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                <div className="text-center">
                  <FontAwesomeIcon  
                    icon={faChevronCircleDown} 
                    className="mt-3 h-50 text-center" 
                    color={partnerBrandColor} 
                    style={{ width: "30px", cursor: "pointer", borderRadius: "50px", boxShadow: `0px 1px 8px 1px ${partnerBrandColor}`,
                    }} 
                    onClick={() => handleSelectCategory(course,"course")}
                    />
                </div>
              </Card.Body>
            </Card>
          </div>
        </>
      );
    });
    setSelectedCourseData(datas);
  };

  const saleEventsList = (events, partnerBrandColor) => {
    if (events?.length >= 0) {
      const datas = events?.map((event) => {
        const eventStartDate = new Date(event.eventDate);
        const eventEndDate = new Date(event.eventEndDate);
        const startDate = eventStartDate.toLocaleDateString();
        const startTime = eventStartDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const endDate = eventEndDate.toLocaleDateString();
        const endTime = eventEndDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 col-sm-12 mb-3">
            <Card className="explore-plans-card cursor-pointer">
              <Card.Body>
              <div className="sales-page-image d-flex justify-content-center p-1">
                  {event?.image &&
                  /\.(png|jpeg|jpg|jfif|webp|gif|bmp|svg)$/i.test(event.image) ? (
                    <Image src={event.image ?? eventPlaceholder} alt="Event Image" className="img-fluid" />
                  ) : /\.(mp4|mov|avi|mkv|webm)$/i.test(event.image) ? ( 
                    <ReactPlayer
                      config={{ file: { attributes: { controlsList: "nodownload" } } }}
                      url={event.image}
                      controls={true}
                      playing={false}
                      width="100%"
                      height="100%"
                    />
                  ) : /\.(mp3|wav|ogg|flac)$/i.test(event.image) ? (
                    <audio controls>
                      <source src={event.image} />
                      Your browser does not support the audio element.
                    </audio>
                  ) : null }
                </div>

                <div className="plan-name">
                  <span>{event.name}</span>
                </div>

                <div className="mt-3 d-flex justify-content-between align-items-start flex-wrap">
                  <FontAwesomeIcon icon={faCalendar} className='mr-1' color={brandColor} />
                  <div className="event-date mr-auto">
                    {startDate} - {endDate}<br/>({startTime} - {endTime})
                  </div>
                </div>
                
                  <div className="mt-2">
                    <h6 className="fw-bold">Description</h6>
                    <p className="mt-2" style={{fontSize:"14px"}} >{shortenFileName(stripHtml(event.description))}</p>
                  </div>
                {event.eventAmount !== undefined &&
                  event.eventAmount !== null &&
                  event.eventAmount > 0 ?
                  <div className="text-center">
                    <Button
                      className="purchase-button"
                      style={{
                        backgroundColor: partnerBrandColor,
                        marginTop: "10px",
                      }}
                      onClick={() => selectEvent(event._id)}
                    >
                      Purchase at {`$${event.eventAmount}`}
                    </Button>
                  </div>
                  :
                  <div className="text-center">
                    <Button
                      className="purchase-button"
                      style={{
                        backgroundColor: partnerBrandColor,
                        marginTop: "10px",
                      }}
                      onClick={() => selectEvent(event._id)}
                    >
                      Register for Free
                    </Button>
                  </div>
                }
                <div className="text-center">
                  <FontAwesomeIcon
                    icon={faChevronCircleDown}
                    className="mt-3 h-50 text-center"
                    color={partnerBrandColor}
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      borderRadius: "50px",
                      boxShadow: `0px 1px 8px 1px ${partnerBrandColor}`,
                    }}
                    onClick={() => handleSelectCategory(event,"event")}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        );
      });
      setSelectedEventData(datas);
    }
  };
  const renderEventDetails = (event) => {
    if (!event) return null;
  
    const timeZone = moment(event.eventDate).format("ZZ"); 
    const eventDate = moment(event.eventDate).format("YYYY-MM-DD");
    const eventEndDate = moment(event.eventEndDate).format("YYYY-MM-DD");
    const start = moment(event.eventDate).format("HH:mm");
    const finish = moment(event.eventEndDate).format("HH:mm");
  
    return (
      <>
        <span className="close">
          <FontAwesomeIcon icon={faTimes} onClick={() => setExpanded("")} />
        </span>
        <h5 className="modal-title text-capitalize mb-2">
          {event?.name?.length <= 15 ? (
            event.name
          ) : (
           event?.name?.substring(0, 13) + "..."
          )}
        </h5>
  
        <div className="sales-page-image d-flex justify-content-center p-1 mb-3">
          {event.image &&
            (/\.(png|jpeg|jpg|jfif|webp|gif|bmp|svg)$/i.test(event.image) ? (
              <img src={event.image} className="img-fluid" alt="Event" />
            ) : /\.(mp4|mov|mkv|webm)$/i.test(event.image) ? (
              <ReactPlayer
                config={{ file: { attributes: { controlsList: "nodownload" } } }}
                url={event.image}
                controls={true}
                playing={false}
                width="400"
                height="200px"
              />
            ) : /\.(mp3|wav|ogg|flac)$/i.test(event.image) ? (
              <audio controls>
                <source src={event.image} />
                Your browser does not support the audio element.
              </audio>
            ) : null)}
        </div>
  
        <div className="d-flex justify-content-between align-items-start flex-wrap">
          <FontAwesomeIcon icon={faCalendar} className='mr-1' color={brandColor} />
          <div className="event-date mr-auto">
            {eventDate} - {eventEndDate}
            <br />
            Event from: {start} - {finish} {timeZone}
          </div>
        </div>
  
        <div className="event-description">
          <h6 className="title">Description</h6>
          <div
            className="description-content text sun-editor-editable"
            style={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{ __html: event.description }}
          />
        </div>
        {event.eventAmount !== undefined &&
          event.eventAmount !== null &&
          event.eventAmount > 0 ?
          <div className="text-center">
            <Button
              className="purchase-button"
              style={{
                backgroundColor: brandColor,
                marginTop: "10px",
                width:"25%",
                minWidth:"25%",
              }}
              onClick={() => selectEvent(event._id)}
            >
              Purchase at {`$${event.eventAmount}`}
            </Button>
          </div>
          :
          <div className="text-center">
            <Button
              className="purchase-button"
              style={{
                backgroundColor: brandColor,
                marginTop: "10px",
                width:"25%",
                minWidth:"25%",
              }}
              onClick={() => selectEvent(event._id)}
            >
              Register for Free
            </Button>
          </div>
        }
      </>
    );
  };
  
  const renderCourseDetails = (course) => {
    if (!course) return null;
  
    return (
      <>
        <span className="close">
          <FontAwesomeIcon icon={faTimes} onClick={() => setExpanded("")} />
        </span>
        <h5 className="text-capitalize mb-2">
            {course.title}
        </h5>
        <div className="sales-page-image d-flex justify-content-center p-1 mb-3">
          <Image src={course.courseImage ?? coursePlaceholder} alt="Course" className="img-fluid" />
        </div>
        <div className="sales-page-content" style={{ height: "auto"}}>
          <h6 className="fw-bold">Short Description</h6>
          <p className="text-justify">{course.shortDescription}</p>
          <div className="mt-2">
            <h6 className="fw-bold">Description</h6>
            <div
              className="mt-2 description-content"
              style={{ fontSize: "14px" }}
              dangerouslySetInnerHTML={{ __html: course.description }}
            />
          </div>

          {course.amount !== undefined && course.amount !== null && course.amount > 0 ? (
            <div className="d-flex align-items-center justify-content-center my-2" style={{ width: "100%" }}>
              <Button
                className="purchase-button"
                style={{
                  backgroundColor: brandColor,
                  marginTop: "10px",
                  width:'25%',
                  minWidth:"25%",
                }}
                onClick={() => selectCourse(course._id)}
              >
                Purchase at {`$${course.amount}`}
              </Button>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center my-2" style={{ width: "100%" }}>
              <Button
                className="purchase-button"
                style={{
                  backgroundColor: brandColor,
                  marginTop: "10px",
                  width:'25%',
                  minWidth:"25%",
                }}
                onClick={() => selectCourse(course._id)}
              >
                Enroll for Free
              </Button>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderSubscriptionDetails = (subscription) => {
    if(!subscription) return null;
    const monthlyFeeForYearlyPlan = (subscription.amountYearly / 12).toFixed(2);
    return (
      <>
        <span className="close">
          <FontAwesomeIcon icon={faTimes} onClick={() => setExpanded("")} />
        </span>
        <h5 className="text-capitalize mb-2">
          {subscription.tier}
        </h5>
        <div className="sales-page-image d-flex justify-content-center p-1 mb-3">
          <Image src={subscription.thumbnailImg ?? subscriptionPlaceholder} alt="Subscription" className="img-fluid" />
        </div>
        <div className="sales-page-content" style={{ height: "auto"}}>
          <h6 className="fw-bold">Description</h6>
          <div
            className="mt-2 description-content"
            style={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{ __html: subscription.description }}
          />
          <div className="d-flex justify-content-center gap-4 align-items-center flex-wrap w-100 mt-3">
            {subscription.amountOneTime !== undefined &&
              subscription.amountOneTime !== null &&
              subscription.amountOneTime > 0 && (
                <div className="text-center">
                  <Button
                    className="purchase-button"
                    style={{
                      backgroundColor: brandColor,
                    }}
                    onClick={() => selectPlan(subscription._id, "onetime")}
                  >
                    <span>
                      One time purchase at {`$${subscription.amountOneTime}`} AUD
                    </span>
                  </Button>
                </div>
              )}
            {subscription.amountYearly !== undefined &&
              subscription.amountYearly !== null &&
              subscription.amountYearly > 0 && (
                <div className="text-center">
                  <Button
                    className="purchase-button"
                    style={{
                      backgroundColor: brandColor,
                      marginTop: "10px",
                    }}
                    onClick={() => selectPlan(subscription._id, "yearly")}
                  >
                    <span>
                      Purchase at {`$${monthlyFeeForYearlyPlan}`} AUD / Month
                    </span>
                    <br />
                    <span>
                      <small>Charged {`$${subscription.amountYearly}`} / Yearly</small>
                    </span>
                  </Button>
                </div>
              )}
            {subscription.amountMonthly !== undefined &&
              subscription.amountMonthly !== null &&
              subscription.amountMonthly > 0 && (
                <div className="text-center">
                  <Button
                    className="purchase-button"
                    style={{
                      backgroundColor: brandColor,
                      marginTop: "10px",
                    }}
                    onClick={() => selectPlan(subscription._id, "monthly")}
                  >
                    Purchase at {`$${subscription.amountMonthly}`} AUD / Month
                  </Button>
                </div>
              )}
            {(subscription.amountMonthly == null || subscription.amountMonthly <= 0) &&
              (subscription.amountYearly == null || subscription.amountYearly <= 0) &&
              (subscription.amountOneTime == null || subscription.amountOneTime <= 0) && (
                <div className="text-center">
                  <Button
                    className="purchase-button"
                    style={{
                      backgroundColor: brandColor,
                    }}
                    onClick={() => selectPlan(subscription._id, "yearly")}
                  >
                    Subscribe for Free
                  </Button>
                </div>
              )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Navbar
        expand="lg"
        className="sales-navbar"
        style={{ backgroundColor: brandColor }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
        >
          <Navbar.Brand href="#">
            <img
              src={logo}
              alt="Company Logo"
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Brand href="#">
            <div>
              <div style={{ color: "white" }}>{companyName}</div>
              <div style={{ color: "white" }}>{membershipName}</div>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
        </div>
      </Navbar>
      <div style={{ padding: "0 2em", width: "100%" }}>
        <section className="mt-3">
          <h4>{title}</h4>
          <img
            src={banner}
            alt="Sales banner"
            className="img-fluid"
            style={{ width: "100%" }}
          />
        </section>
        <section className="mt-3">
          <p className="text-justify"
            dangerouslySetInnerHTML={{ __html: content }}>
          </p>
        </section>

        <div className="d-flex align-items-center flex-wrap">
          <p className="returning-customer mb-0">
            Returning customer? <Link to="/login">Click here to login</Link>
          </p>
        </div>
        {subscriptionData?.length <= 0 && selectedCourseData?.length <= 0 && selectedEventData?.length <= 0 ? (
          <div className="mt-3">
            <h3>No products available</h3>
          </div>
        ) : (
          <>
            {selectedCourseData?.length > 0 ? (
              <>
                <div className="mt-3">
                  <h3>{productTitle}</h3>
                </div>
                <div className="row mt-3">{selectedCourseData}</div>
                {expanded === "course" && selectedCourse ? (
                  <div ref={courseRef} className="mt-3 p-4 border rounded-3 salespage-product-description" style={{ boxShadow: `0 0 5px 0px ${brandColor}`}}>
                    {renderCourseDetails(selectedCourse)}
                  </div>
                ) : null}
              </>
            ) : (
              <></>
            )}
            
            {selectedEventData?.length > 0 ? (
              <>
                <div className="mt-3">
                  <h3>{eventTitle}</h3>
                </div>
                <div className="row mt-3">{selectedEventData}</div>
                {expanded === "event" && selectedEvent ? (
                  <div ref={eventRef} className="mt-3 p-4 border rounded-3 salespage-product-description" style={{ boxShadow: `0 0 5px 0 ${brandColor}`}}>
                    {renderEventDetails(selectedEvent)}
                  </div>
                ) : null}
              </>
            ) : (
              <></>
            )}
            {subscriptionData?.length > 0 ? (
              <>
                <div className="mt-3">
                  <h3>{plansTitle}</h3>
                </div>
                <div className="row mt-3">{subscriptionData}</div>
                {expanded === "subscription" && selectedSubscription ? (
                  <div ref={subscriptionRef} className="mt-3 p-4 border rounded-3 salespage-product-description" style={{ boxShadow: `0 0 5px 0 ${brandColor}`}}>
                    {renderSubscriptionDetails(selectedSubscription)}
                  </div>
                ) : null}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        {/* {isOpen && (
          <EventModal
            isOpen={isOpen}
            closeModal={() => {
              setIsOpen(false);
              setSelectedEvent({});
            }}
            event={selectedEvent}
            eventId={selectedEvent.eventId}
            source="checkout"
          />
        )} */}
      </div>
      <AnonymousFooter brandColor={brandColor} companyName={companyName} email={email} phone={phone} countryCode={countryCode} subadminId={subadminId} />
    </>
  );
};

export default SalesPage;
