import React, { useState, useEffect } from "react";

import Header from "../common/Header";
import Loader from "../Loader";
import SocialwallGrid from "./SocialwallGrid";

import searchDark from "../../images/enduser/search-dark.svg";

import { PopularSocialList, socialWallUsers } from "../../helper/service";

export default function Socialwall() {
  const [isLoader, setIsLoader] = useState(false);
  const [walls, setWalls] = useState([]);
  const [originalWalls, setOriginalWalls] = useState([]);

  useEffect(() => {
    fetchPopularSocialWall();
  }, []);

  const fetchPopularSocialWall = async () => {
    try {
      const idd = localStorage.getItem("user_id");
      setIsLoader(true);
      var datas = await PopularSocialList(idd);

      for (const item of datas.result) {
        const selectedUsers = item.userId.map((user) => user.userid);
        const socialWallUsersAndMemAccessLevel = {
          users: selectedUsers,
          memberAccessLevel: item.memberAccessLevel,
        };

        const res = await socialWallUsers(socialWallUsersAndMemAccessLevel);
        if (res?.data.result && res?.data.result.length > 0) {
          let users = res.data.result[0];
          if (Array.isArray(users)) {
            item.socialWallUsers = res.data.result[0];
          } else {
            item.socialWallUsers = res.data.result;
          }
        }
      }
      setWalls(datas.result);
      setOriginalWalls(datas.result);
    } catch (error) {
      console.error("Error fetching walls:", error);
    } finally {
      setIsLoader(false);
    }
  };

  const searchData = (event) => {
    event.preventDefault();
    const { value } = event.target;

    if (value !== "") {
      let filteredWalls = originalWalls.filter((ele) =>
        ele.title.toLowerCase().includes(value.toLowerCase())
      );

      setWalls(filteredWalls);
    } else {
      setWalls(originalWalls);
    }
  };

  return (
    <div className="wrapper">
      <Header />
      {isLoader ? (
        <Loader />
      ) : (
        <div className="content-wrapper enduser-social-wall">
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 mt-3 ml-3">
                  <h2 className="title">SOCIAL WALL</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 ml-auto text-right mt-3">
                  <div className="search-socialWall position-relative d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control bg-white pr-5"
                      placeholder="Search"
                      name="search"
                      onChange={searchData}
                    />
                    <span className="search-icon-social position-absolute">
                      <img src={searchDark} alt="search" />
                    </span>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-md-12">
                  <div className="tab-content" id="socialWallTabContent">
                    <SocialwallGrid postData={walls} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
