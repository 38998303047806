import React from 'react';
import book1 from "../../images/admin/book.svg";
import { getSubFolder } from "../../helper/service";



const SubFolder = (props) => {


console.log(props.folder)
  return (           
  <div className="folder">
  <h5 className="folder-title">{props.folder.title} - Sub folder</h5>
  <div className="row book align-items-center">
    <div className="col-3">
      <img src={book1} className="img-fluid" />
    </div>
    <div className="col-9">
      <h5 className="book-title">Miss Makeover Steps</h5>
      <p className="book-text">352 Pages</p>
    </div>
  </div>
  <div className="row book align-items-center">
    <div className="col-3">
      <img src={book1} className="img-fluid" />
    </div>
    <div className="col-9">
      <h5 className="book-title">Miss Makeover Steps</h5>
      <p className="book-text">352 Pages</p>
    </div>
  </div>
  <div className="row book align-items-center">
    <div className="col-3">
      <img src={book1} className="img-fluid" />
    </div>
    <div className="col-9">
      <h5 className="book-title">New York</h5>
      <p className="book-text">352 Pages</p>
    </div>
  </div>
</div> );
}
 
export default SubFolder;