import React, { useEffect, useState } from "react";
import {
  subadminInfo,
  subadminSubscriptions,
  subscribefree,
  GetSalesPage,
  userPurcahseCourse,
  GetSalesPageCoursesListForMember,
} from "../../helper/service";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Mousewheel, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import { commonUtil } from "../../helper/CommonUtils";
const SalesCoursesList = ({ searchCour }) => {
  const [notPurchaseCourse, setNotPurchaseCourse] = useState([]);
  const fetchData = async () => {
    let subadminId = localStorage.getItem("subadminId");
    let userId = localStorage.getItem("user_id");
    try {
      const salesCour = await GetSalesPage(subadminId);
      if (salesCour.result.selectedCourse) {
        GetSalesPageCoursesListForMember(salesCour.result.selectedCourse).then(
          async (courses) => {
            let purchaseCour = await userPurcahseCourse(userId);
            if (courses.result.selectedCoursesSale) {
              const purchaseCourseIds = purchaseCour?.result.map(
                (user) => user.purchaseCourseId
              );
              // Filtering courses based on purchaseCourseIds
              const filteredCourses = courses.result.selectedCoursesSale.filter(
                (course) => !purchaseCourseIds.includes(course?._id)
              );
              setNotPurchaseCourse(filteredCourses);
            }
          }
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const selectCourse = (courseId) => {
    let subadminId = localStorage.getItem("subadminId");
    let url = `/coursecheckout/${courseId}`;
    commonUtil.redirect(url);
  };

  return (
    <Swiper
      modules={[Navigation, Mousewheel, Pagination]}
      navigation={true}
      spaceBetween={25}
      pagination={true}
      slidesPerView={1}
      breakpoints={{
        400: {
          width: 400,
          slidesPerView: 2,
        },
        768: {
          width: 768,
          slidesPerView: 3,
        },
      }}
    >
      {notPurchaseCourse
        ?.filter((course) =>
          course?.title?.toLowerCase()?.includes(searchCour?.toLowerCase())
        )
        ?.map((course) => {
          const numberOfStudents = course?.students?.length || 0;
          if (course.acceptIndPayment) {
            return (
              <SwiperSlide
                key={course._id}
                className="discover-slider courses-slider owl-carousel owl-theme"
              >
                <div className="item">
                  {/* <Link to={`courses-details/${course._id}`}> */}
                  <a>
                    <div
                      className="main-img w-100"
                      onClick={() => {
                        alert("You can't access course until you purchase it.");
                      }}
                    >
                      <img
                        src={course.courseImage}
                        className="img-over w-100"
                        alt={course.title}
                      />
                      <h5 className="slide-title mt-2">{course.title}</h5>{" "}
                    </div>
                  </a>

                  <p className="slide-text">
                    <span>${course?.amount}</span>
                  </p>
                  <p className="slide-text">
                    <span>
                      {course?.tLesson > 1
                        ? `${course?.tLesson}Lessons`
                        : "0 Lesson"}
                    </span>
                  </p>
                  <p className="slide-text float-left">
                    <span>
                      {numberOfStudents}{" "}
                      {numberOfStudents > 1 ? "Students" : "Student"}
                    </span>
                  </p>
                  <div
                    className="float-right d-flex cursor-pointer"
                    onClick={() => selectCourse(course._id)}
                  >
                    <span>Purchase</span>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </SwiperSlide>
            );
          }
        })}
    </Swiper>
  );
};

export default SalesCoursesList;
