import React, { useState, useEffect } from "react";
import RecentChallenges from "./RecentChallenges";
import { useQueryClient } from "@tanstack/react-query";
import searchDark from '../../images/enduser/search-dark.svg';
import ChallengesGrid from "./ChalengesGrid";

export default function FolderComponent(props) {
  const queryClient = useQueryClient();
  const [searchChallenge, setSearchChallenge] = useState()
  const [activeView, setActiveView] = useState("list");
  const [brandColor, setBrandColor] = useState("");

  useEffect(() => {
    const color = localStorage.getItem("subadminBrandColor") || "#919294";
    setBrandColor(color);
  }, []);

  return (
    <section class="courses-folders">
      <div class="container-fluid">
        {/*<div class="row">*/}
        {/*<div class="col-xl-8">*/}
        {/*<div class="d-flex justify-content-left align-items-center flex-wrap">*/}
        {/*<label>Sort by :</label>*/}
        {/*<select class="form-control sort">*/}
        {/*<option> Name </option>*/}
        {/*</select>  */}
        {/*<img src="images/admin/Group 68030.svg" class="mx-3" />   */}
        {/*<img src="images/admin/calendar (8).svg" class="mx-3" />   */}
        {/*<input type="text" name="" class="form-control mx-3 date" placeholder="21 - Jan - 2020" />  */}
        {/*</div>      */}
        {/*</div>*/}
        {/*<div class="col-xl-4">*/}
        {/*<div class="d-flex justify-content-end align-items-center">*/}
        {/*<button type="button" class="btn btn-custom">View All</button>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}

        <div class="row">
          <div class="col-md-12">
            <ul class="nav nav-tabs" id="coursesTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="recentlyAdded-tab"
                  data-toggle="tab"
                  onClick={() => {
                    queryClient.invalidateQueries(["getRecentChallenges"]);
                  }}
                  role="tab"
                  aria-controls="recentlyAdded"
                  aria-selected="true"
                >
                  Recently Added
                </a>
              </li>

              {/* <li class="nav-item" role="presentation">
                      <a class="nav-link" id="scheduled-tab" data-toggle="tab"  onClick={props.getScheduledChallenges} role="tab" aria-controls="scheduled" aria-selected="false">Scheduled Challenges</a>
                    </li> */}

              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="top-tab"
                  data-toggle="tab"
                  onClick={props.getTopCourses}
                  role="tab"
                  aria-controls="top"
                  aria-selected="false"
                >
                  Top Challenges
                </a>
              </li>
              <li className="ml-auto">
                <div className="search-course">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Challenges"
                    name="search"
                    onChange={(e) => setSearchChallenge(e.target.value)}
                  />
                  <span className="search-icon">
                    <img src={searchDark} />
                  </span>
                </div>
              </li>
              {/*<li class="nav-item">*/}
              {/*<a class="nav-link" id="viewAll-tab" data-toggle="tab" href="#" role="tab" aria-controls="viewall" aria-selected="false">View All <i class="fas fa-angle-right"></i></a>*/}
              {/*</li>*/}
              {/*<li class="ml-auto">*/}
              {/*<div class="search-course">*/}
              {/*<input type="text" name="" class="form-control" placeholder="Search Course" />*/}
              {/*<span class="search-icon"><img src={magnifiyingGlass} /></span>*/}
              {/*</div>*/}
              {/*</li>*/}
            </ul>
            <div className="row mt-3">
              <div className="d-flex justify-content-end">
                <button
                  className="icon-button mr-2"
                  aria-label="List View"
                  onClick={() => setActiveView("list")}
                  style={{
                    color: activeView === "list" ? brandColor : "#919294",
                  }}
                >
                  <i className="fas fa-list fa-2x"></i>
                </button>
                <button
                  className="mr-2 icon-button"
                  aria-label="Grid View"
                  onClick={() => setActiveView("grid")}
                  style={{
                    color: activeView === "grid" ? brandColor : "#919294",
                  }}
                >
                  <i className="fas fa-th-large fa-2x"></i>
                </button>
              </div>
            </div>
            {activeView === "grid" ? (
              <ChallengesGrid searchChallenge={searchChallenge} />
            ) : (
              <RecentChallenges searchChallenge={searchChallenge} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
