import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/main.css";

import "./js/admin.min.js";
import { QueryClientProvider } from "@tanstack/react-query";
import { getQueryClient } from "./helper/QueryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SearchQueryProvider } from "./contexts/SearchContext";
import { NotificationProvider } from "./contexts/NotificationContext";

const queryClient = getQueryClient();

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);


root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SearchQueryProvider>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </SearchQueryProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
