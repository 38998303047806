import React from "react";
import genKey from "../../helper/genKey";
import { INewResource } from "../../interfaces/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faCircleArrowDown, faFileLines, faImages, faMicrophone, faVideo } from "@fortawesome/free-solid-svg-icons";

enum AttachedLessonFilesType {
  Docs = 0,
  Video = 1,
  Audio = 2,
  Image = 3,
}

type AttachedLessonFilesProps = {
  files: INewResource[] | undefined;
};
    const brandColor = localStorage.getItem("subadminBrandColor") || "#000000";

function AttachedLessonFiles(props: AttachedLessonFilesProps) {
  const [activeTab, setActiveTab] = React.useState(AttachedLessonFilesType.Docs);
  if(!props.files) return <></>;

  return (
    <div className="attatchedLessonFiles__root">
      <h6 className="mt-4 mb-3">Attached files</h6>
      <div className="attachedLessonFiles__tab-btns">
        <div
          className={`attachedLessonFiles__tab-btn ${
            activeTab === AttachedLessonFilesType.Docs
              ? "attachedLessonFiles__tab-btn--active"
              : ""
          } `}
          onClick={() => setActiveTab(AttachedLessonFilesType.Docs)}
        >
          <FontAwesomeIcon
            className={
              activeTab === AttachedLessonFilesType.Docs
                ? "wisdome-icon text-white"
                : "wisdome-icon"
            }
            icon={faFileLines}
            size={"2x"}
            color={brandColor}
          />
        </div>
        <div
          className={`attachedLessonFiles__tab-btn ${
            activeTab === AttachedLessonFilesType.Video
              ? "attachedLessonFiles__tab-btn--active"
              : ""
          }`}
          onClick={() => setActiveTab(AttachedLessonFilesType.Video)}
        >
          <FontAwesomeIcon
            className={
              activeTab === AttachedLessonFilesType.Video
                ? "wisdome-icon text-white"
                : "wisdome-icon"
            }
            icon={faVideo}
            size={"2x"}
            color={brandColor}
          />
        </div>
        <div
          className={`attachedLessonFiles__tab-btn 
          ${
            activeTab === AttachedLessonFilesType.Audio
              ? "attachedLessonFiles__tab-btn--active"
              : ""
          }
        `}
          onClick={() => setActiveTab(AttachedLessonFilesType.Audio)}
        >
          <FontAwesomeIcon
            className={
              activeTab === AttachedLessonFilesType.Audio
                ? "wisdome-icon text-white"
                : "wisdome-icon"
            }
            icon={faMicrophone}
            size={"2x"}
            color={brandColor}
          />
        </div>
        <div
          className={`attachedLessonFiles__tab-btn
        ${
          activeTab === AttachedLessonFilesType.Image
            ? "attachedLessonFiles__tab-btn--active"
            : ""
        }
        `}
          onClick={() => setActiveTab(AttachedLessonFilesType.Image)}
        >
          <FontAwesomeIcon
            className={
              activeTab === AttachedLessonFilesType.Image
                ? "wisdome-icon text-white"
                : "wisdome-icon"
            }
            icon={faImages}
            size={"2x"}
            color={brandColor}
          />
        </div>
      </div>
      <div className="attachedLessonFiles__tab-content">
        {activeTab === AttachedLessonFilesType.Docs && (
          <>
            {props.files.length === 0 && <p>No docs found!</p>}
            {props.files.map((file) => {
              const fileName =
                file?.resourceUrl?.split("/").pop()?.split(".")[0] || "";
              const fileExtension =
                file?.resourceUrl?.split("/").pop()?.split(".")[1] || "";
              return (
                <div
                  key={genKey()}
                  className="attachedLessonFiles__tab-content-doc"
                >
                  <div
                    className="attachedLessonFiles__file-name"
                    title={`${fileName}.${fileExtension.toUpperCase()}`}
                  >
                    {fileName.length > 12
                      ? fileName.slice(0, 12) + "..."
                      : fileName}
                    .{fileExtension.toUpperCase()}
                  </div>
                  <div className="attachedLessonFiles__file-download">
                    <a
                      href={file.resourceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faCircleArrowDown}
                        size={"1x"}
                        color="#fff"
                      />
                    </a>
                  </div>
                </div>
              );
            })}
          </>
        )}
        {activeTab === AttachedLessonFilesType.Video && (
          <>
            <p>No video found!</p>
          </>
        )}
        {activeTab === AttachedLessonFilesType.Audio && (
          <>
            <p>No audio found!</p>
          </>
        )}
        {activeTab === AttachedLessonFilesType.Image && (
          <>
            <p>No image found!</p>
          </>
        )}
      </div>
    </div>
  );
}

export default AttachedLessonFiles;
