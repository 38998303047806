import React, { useState, useEffect } from "react";
import logo from "../images/logo.png";
import membership from "../images/membership-bg.svg";
import { NavLink } from "react-router-dom";

const brandColor = localStorage.getItem("subadminBrandColor");
export default function Membership() {
  const [membershipData, setMembershipData] = useState("");
  const [isRedirect, setIsRedirect] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    const subadmins = JSON.parse(localStorage.getItem("subadmins"));
    iterateMemberShip(subadmins);

    const searchParams = new URLSearchParams(window.location.search);
    const redirectUrl = searchParams.get("redirect");
    setRedirectUrl(redirectUrl);
  }, []);

  const onChange = (key) => {
    let subadmins = JSON.parse(localStorage.getItem("subadmins"));
    let res = subadmins[key];

    setIsSelect(true);
    localStorage.setItem("subadminEmail", res.subadminId.email);
    localStorage.setItem("subadminId", res.subadminId._id);
    localStorage.setItem("subadminLogo", res.subadminId.logo);
    localStorage.setItem("subadminFirstName", res.subadminId.firstName);
    localStorage.setItem("subadminLastName", res.subadminId.lastName);
    localStorage.setItem("subadminBrandColor", res.subadminId.brandColor);
    localStorage.setItem("subadminBusiness", res.subadminId.businessName);
    localStorage.setItem("membershipName", res.subadminId.membershipName);
    localStorage.setItem("companyName", res.subadminId.company[0]["name"]);
    localStorage.setItem("welcomeNote", res.subadminId.welcomeNote);
    localStorage.setItem("newsData", res.subadminId.newsData);
    localStorage.setItem(
      "subscriptionId",
      res.subadminId.subscriptionId[0]["_id"]
    );

    if (res.subadminId.company[0]["logo"]) {
      localStorage.setItem("subadminImage", res.subadminId.company[0]["logo"]);
    } else {
      localStorage.setItem("subadminImage", res.subadminId.image);
    }
  };

  const submit = () => {
    if (isSelect) {
      setIsRedirect(true);
    } else {
      alert("Please select a membership.");
    }
  };

  const iterateMemberShip = (datas) => {
    if (datas.length > 0) {
      datas = datas.map(function (mem, key) {
        //console.log(key);
        var memId = "mem_" + mem.subadminId._id;
        var image =
          mem.subadminId.company &&
          mem.subadminId.company.length > 0 &&
          mem.subadminId.company[0].logo
            ? mem.subadminId.company[0].logo
            : mem.subadminId.image;

        return (
          <div
            className="company-info d-flex align-items-center"
            style={{ backgroundColor: mem.subadminId.brandColor }}
          >
            <div className="custom-control custom-checkbox mr-5">
              <input
                type="radio"
                name="memberName"
                className="custom-control-input"
                id={memId}
                onChange={() => onChange(key)}
              />
              <label className="custom-control-label" htmlFor={memId} />
            </div>
            <img
              src={image}
              className="mr-2 img-fluid"
              alt=""
              width="70"
              height="70"
            />
            <p className="company-name">
              {mem.subadminId.company[0]["name"]} <br />
              <small>{mem.subadminId.membershipName}</small>
            </p>
          </div>
        );
      }, this);
    } else {
      datas = "No Record Found.";
    }

    setMembershipData(datas);
  };

  document.body.style.backgroundImage = "url(" + membership + ")";

  if (isRedirect) {
    if (redirectUrl !== null) window.location = redirectUrl;
    else window.location = "/welcome";
  }

  return (
    <section id="multiple-membership" className="d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card">
              <div className="card-body text-center">
                <div className="multiple-membership-logo">
                  <img
                    src={logo}
                    className="img-fluid mx-auto d-block"
                    alt="logo"
                  />
                </div>
                <h2 className="multiple-membership-title">Your Membership/s</h2>
                <p className="multiple-membership-msg">
                  Your email may be registered to multiple memberships on
                  Wisdome. If so, please select which Membership you wish to log
                  into today by selecting the check corresponding check box.
                </p>
                {/* <form action method="post"> */}
                {/* {this.state.membershipData} 
                    <div className="text-center"> 
                      <button type="submit" className="btn btn-info" onClick={this.submit.bind(this)}>Next</button> */}
                {/* <form action method="post"> */}
                <div className="scroll">{membershipData}</div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-info"
                    style={{ backgroundColor: brandColor }}
                    onClick={submit}
                  >
                    Next
                  </button>
                </div>
                <div className="col-12">
                  <div className="text-center mt-2">
                    <NavLink to="/login">Back to login</NavLink>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
