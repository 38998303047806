import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../common/Header";
import Loader from "../Loader";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import UpcomingEvents from "./UpcomingEvents";
import EventModal from "./EventModal";
import EventSearchBox from "./EventSearchBox";
import ConfirmationModal from "../Setting/ConfirmationModal";
import {
  getAllCommentsofEvents,
  getAllUpcomingEvents,
  saveCommentData,
  getAllEvents,
  eventDetails,
  deleteEventCommentByUserId,
  editEventCommentByUserId,
} from "../../helper/service";
import EventsCalendar from "./EventsCalendar";

export default function Events() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const id = pathSegments[pathSegments.length - 1];
  const [userAlreadyJoinedEvent, setUserAlreadyJoinedEvent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [eventDetailsData, setEventDetailsData] = useState({});
  const [is_loader, setIsLoader] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [allEventData, setallEventData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [commentsData, setCommentsData] = useState([]);
  const [deleteCommentId, setDeleteCommentId] = useState(null);
  const [deleteEventId, setDeleteEventId] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningModalTitle, setWarningModalTitle] = useState('');
  const [message, setMessage] = useState('');
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    if(id !== 'events')
    {
      getEventDetails(id);
    }
    getAndProcessEvents();
    getUpcomingEvents();
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleRegisterSuccess = () => {
    setEventDetailsData({
      ...eventDetailsData,
      url: eventDetailsData.url,
    });
    setUserAlreadyJoinedEvent(true);
    getUpcomingEvents();
  };

  const handleSelectEvent = (event) => {
    getEventDetails(event.eventId);
  };

  const detailsEvent = (event) => {
    getEventDetails(event);
  };
  const handleEditComment = async (eventId, commentId, updatedComment) => {
    try {
      await editEventCommentByUserId(eventId, commentId, updatedComment);

      const updatedComments = commentsData.map(comment =>
        comment.id === commentId ? { ...comment, text: updatedComment } : comment
      );
      totalComment(eventId);
    } catch (error) {
      console.error("Error editing comment:", error.message);
    }
  };
  const handleDelete = (commentId) => {
    setShowWarningModal(true);
    setWarningModalTitle('Confirm Deletion');
    setMessage('Are you sure you want to delete this comment?');
    setModalType('delete');
    setDeleteCommentId(commentId);
    setDeleteEventId(selectedEvent.eventId);
  };
  
  const handleDeleteCancel = () => {
    setShowWarningModal(false);    
    setMessage('');                
    setWarningModalTitle('');      
    setModalType('');             
    setDeleteCommentId(null);     
    setDeleteEventId(null);       
  };
  
  
  const deleteComment = async () => {
    if (deleteCommentId && deleteEventId) {
      try {
        setIsLoader(true);
        await deleteEventCommentByUserId(deleteEventId, deleteCommentId);
        setShowWarningModal(false);
        totalComment(deleteEventId)
      } finally {
        setIsLoader(false);
      }
    }
  };
  const getEventDetails = (eventId) => {
    setIsLoader(true);
    eventDetails(eventId)
      .then((data) => {
        let timeZone = moment(data.result.eventDate);
        timeZone = timeZone._d.toString().split(" ")[5];

        var originalUtcTimeStart = new Date(data.result.eventDate);
        var newUtcTimestart = originalUtcTimeStart.toISOString();
        var originalUtcTimeEnd = new Date(data.result.eventEndDate);
        var newUtcTimeEnd = originalUtcTimeEnd.toISOString();
        let eventDate = moment(newUtcTimestart).format("YYYY-MM-DD");
        let eventEndDate = moment(newUtcTimeEnd).format("YYYY-MM-DD");
        let start = moment(newUtcTimestart).format("HH:mm");
        let finish = moment(newUtcTimeEnd).format("HH:mm");

        let userJoinedEvent = data?.result?.audience?.some(
          (audience) => audience?.user === localStorage.getItem("user_id")
        );
        setUserAlreadyJoinedEvent(userJoinedEvent);
        const event = {
          eventId: data.result._id,
          eventName: data.result.name,
          description: data.result.description,
          eventDate: eventDate,
          eventEndDate: eventEndDate,
          startTime: start,
          endTime: finish,
          timeZone: timeZone,
          eventImg: data.result.image,
          coverGalleryEvent: data.result.eventFiles ? data.result.eventFiles : data.result.coverGalleryEvent,
          url: data.result.liveStreamUrl,
          comments: data.result.commentByUserId,
          userJoinedEvent: userJoinedEvent,
          eventAcceptIndPayment: data.result.eventAcceptIndPayment ? data.result.eventAcceptIndPayment : false
        };
        setCommentsData(data.result.commentByUserId);
        setSelectedEvent(event);
        setIsOpen(true);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getUpcomingEvents = () => {
    setIsLoader(true);
    getAllUpcomingEvents()
      .then((data) => {
        if (data.result.length > 0) {
          setUpcomingEvents(data.result);
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const savePostcomment = async (comment, eventId) => {
    if (comment) {
      setIsLoader(true);
      let user_id = localStorage.getItem("user_id");
      let subsId = localStorage.getItem("subscriptionId");
      const commentData = {
        eventId: eventId,
        subsId: subsId,
        commentByUserId: {
          userId: user_id,
          comment: comment,
        },
      };
      await saveCommentData(commentData);
      totalComment(eventId);
      setIsLoader(false);
    }
  };

  const totalComment = (idd) => {
    getAllCommentsofEvents(idd).then((data) => {
      setCommentsData(data.result.commentByUserId);
    });
  };

  const eventLists = (data) => {
    var arr = [];
    if (data.result.length > 0) {
      arr = data.result.map(function (events) {
        var originalUtcTimeStart = new Date(events.eventDate);
        var newUtcTimestart = originalUtcTimeStart.toISOString();
        var originalUtcTimeEnd = new Date(events.eventEndDate);
        var newUtcTimeEnd = originalUtcTimeEnd.toISOString();
        let eventDate = moment(newUtcTimestart);
        let eventEndDate = moment(newUtcTimeEnd);
        const userAlreadyJoinedEvent = events?.audience?.some(
          (audience) => audience?.user === localStorage.getItem("user_id")
        );
        if(!events.hiddenForMembers || userAlreadyJoinedEvent)
        return {
            title: events.name,
            start: new Date(Date.parse(eventDate)),
            end: new Date(Date.parse(eventEndDate)),
            eventId: events._id,
          };
      });
    }
    setIsLoader(false);
    setEvents(arr);
  };

  const getAndProcessEvents = () => {
    setIsLoader(true);
    getAllEvents()
      .then((data) => {
        setallEventData(data);
        eventLists(data);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const searchData = (event) => {
    var value = event.target.value.toLowerCase();
    var allEvent = allEventData;
    setIsLoader(true);
    if (value) {
      let filteredNames1 = allEvent.result.filter((ele) =>
        ele.name.toLowerCase()?.includes(value.toLowerCase())
      );
      var filteredNames = { result: filteredNames1 };
      eventLists(filteredNames);
    } else {
      eventLists(allEvent);
    }
    setIsLoader(false);
  };

  return (
    <div className="wrapper">
      {is_loader ? <Loader /> : <></>}
      <Header />
      <div className="content-wrapper bg-white">
        <section className="admin-event mt-5">
          <div className="container-fluid">
            <div className="row top-header">
              <div className="col-md-12">
                <h5 className="title">Events</h5>
              </div>
            </div>
            <div className="row">
              <EventSearchBox searchData={searchData} />
            </div>

            <div className="calendar-section mb-5">
              <div className="row">
                <EventsCalendar
                  events={events}
                  handleSelectEvent={handleSelectEvent}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-7">
                {upcomingEvents?.length > 0 && (
                  <UpcomingEvents
                    events={upcomingEvents}
                    detailsEvent={detailsEvent}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      {isOpen && (
        <EventModal
          isOpen={isOpen}
          closeModal={closeModal}
          event={selectedEvent}
          comments={commentsData}
          userJoinedEvent={userAlreadyJoinedEvent}
          handleRegisterSuccess={handleRegisterSuccess}
          savePostcomment={savePostcomment}
          handleDelete={handleDelete}
          handleEditComment={handleEditComment}
          eventId={selectedEvent.eventId}
        />
      )}
      {showWarningModal && (
        <ConfirmationModal
          isOpen={showWarningModal}
          type={modalType}
          title={warningModalTitle}
          message={message}
          onConfirm={() => {
              deleteComment();  
          }}
          onClose={handleDeleteCancel}  
        />
      )}
    </div>
  );

}
