import React, { useEffect, useState } from 'react'
import Group72115 from '../../images/enduser/submit-button.svg';
import { Modal, Button } from "react-bootstrap";
import close from '../../images/enduser/modal-close-icon.svg';
import Loader from '../Loader';
import $ from 'jquery';
import { MentionsInput, Mention } from 'react-mentions'
import defaultStyle from '../../js/default.js'
import {
  savePostcommentData,
} from "../../helper/service";

const CommentSection = ({ showModal, socialWallId, postIdd, selectCurrentWallPost, specificWallMember, onCloseHandler }) => {

  const [comment, setComment] = useState();
  const [postId, setPostId] = useState();
  const [socialWall, setSocialWall] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const brandColor = localStorage.getItem("subadminBrandColor");

  useEffect(() => {
    $(document).ready(function () {
      $('.socialCreateModal').attr('id', 'socialCreateModal');
    })
    setPostId(postIdd);
    setSocialWall(socialWallId);
    setIsOpen(showModal);
  }, [showModal])

  const commentChange = (e) => {
    setComment(e.target.value);
  }

  const enterPressedComment = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      savePostcomment();
    }
  }

  const savePostcomment = async () => {
    if (comment) {
      setIsLoader(true)
      let user_id = localStorage.getItem('user_id');
      const commentData = {
        "postId": postId,
        "socialWallId": socialWall,
        "userId": user_id,
        "comment": comment
      }

      savePostcommentData(commentData).then((data) => {
        console.log(data, "social wall comment save successfully");
      });
      setComment("");
      selectCurrentWallPost(socialWall);
      closeModal();
      setIsLoader(false)
    }
  }

  const closeModal = () => {
    setIsOpen(false)
    onCloseHandler()
  };

  return (
    <>
      {isLoader ? <Loader /> : <></>}
      <Modal show={isOpen} onHide={closeModal} className="socialCreateModal" backdrop="static">
        <Modal.Header className="border-0 justify-content-center">
          <h5 className="modal-title mx-auto" style={{ color: 'black' }}>COMMENTS</h5>
          <button type="button" className="close ml-0 pl-0" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true"><img src={close} alt="close" /></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="label-title">Write your comment</label>
            <div className="topic">
              <div className="row">
                <div className="col-9">
                  <MentionsInput style={defaultStyle} placeholder="Type here"
                    onChange={commentChange} name="comment"
                    onKeyPress={enterPressedComment}
                    value={comment}>
                    <Mention data={specificWallMember}
                      markup='{{__display__:__id__}}'
                      displayTransform={(id, display) => `@${display}`}
                      className="mentions__mention"
                    />
                  </MentionsInput>
                </div>
                <div className="col-3" onClick={savePostcomment}>
                  <img src={Group72115} className="submit-img" alt="save" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CommentSection