import { isToday, getDayName } from "../../helper/dateTimeHelper";
import hexToRgba from "hex-to-rgba";

export default function HomeCalendar() {
  const BrandColor = localStorage.getItem("subadminBrandColor");
  var OpacityColor = hexToRgba(BrandColor, "0.7");

  function getDayElement(dayDate) {
    return (
      <div key={dayDate} className="mb-2">
        <div
          className="day text-white"
          style={
            isToday(dayDate)
              ? { backgroundColor: BrandColor }
              : { backgroundColor: OpacityColor }
          }
        >
          <span>{dayDate.getDate()}</span>
          <br />
          <span>{getDayName(dayDate.getDay())}</span>
        </div>
      </div>
    );
  }

  const daysToShow = 7;
  const dayElements = [];

  const dayDate = new Date();
  dayElements.push(getDayElement(dayDate));

  for (let i = 1; i < daysToShow; i++) {
    dayDate.setDate(dayDate.getDate() + 1);

    dayElements.push(getDayElement(dayDate));
  }

  return dayElements;
}
