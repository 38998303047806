import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function NoAccess() {
    const navigate = useNavigate();

    return (
        <div className="content-wrapper bg-white not-found-page">
            <div className="not-found-container">
                <h1 className="error-code">Hmmm...</h1>
                <p className="error-message">You do not have access to this resource. Try upgrading subscription or contact your instructor.</p>
                <button onClick={() => navigate("/")} className="btn btn-primary">Go Back</button>
            </div>
        </div>
    )
}
