import React, { useEffect, useState } from 'react'
import { subadminInfo, subadminSubscriptions, subscribefree,courseLessonCount, GetSalesPage, userPurcahseCourse, GetSalesPageCoursesListForMember } from "../../helper/service";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Mousewheel, Pagination } from "swiper/modules";
import "swiper/css/navigation";
const PurchaseCourses = ({ searchPurchaseCour }) => {

    const [purchaseCourse, setPurchaseCourse] = useState([])
    const fetchData = async () => {
        let subadminId = localStorage.getItem("subadminId")
        let userId = localStorage.getItem("user_id")
        try {
            const salesCour = await GetSalesPage(subadminId);
            if (salesCour.result.selectedCourse) {
                GetSalesPageCoursesListForMember(salesCour.result.selectedCourse).then(async (courses) => {
                    let purchaseCour = await userPurcahseCourse(userId)
                    if (courses.result.selectedCoursesSale) {
                        const purchaseCourseIds = purchaseCour?.result.map(user => user.purchaseCourseId);
                        // Filtering courses based on purchaseCourseIds
                        const filteredCourses = courses.result.selectedCoursesSale.filter(course => purchaseCourseIds.includes(course?._id));
                    
                        const courseWithLesson = await Promise.all(
                            filteredCourses.map(async (item) => {
                              const res = await courseLessonCount(item._id);
                              return { ...item, tLesson: res.result.length };
                            })
                          );
                          console.log(courseWithLesson, "purchase courses");
                          setPurchaseCourse(courseWithLesson);
                           
                    }

                })
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <Swiper
            modules={[Navigation, Mousewheel, Pagination]}
            navigation={true}
            spaceBetween={25}
            pagination={true}
            slidesPerView={1}
            breakpoints={{
                400: {
                    width: 400,
                    slidesPerView: 2,
                },
                768: {
                    width: 768,
                    slidesPerView: 3,
                },
            }}
        >
            
            {
            purchaseCourse?.filter(courseFilter =>
                courseFilter?.title?.toLowerCase()?.includes(searchPurchaseCour?.toLowerCase())
              )?.map((course) => {
                const numberOfStudents = course?.students?.length || 0;
                return (
                    <SwiperSlide
                        key={course._id}
                        className="discover-slider courses-slider owl-carousel owl-theme"
                    >
                        <div className="item">
                            <Link to={{ pathname: `/course-module/${course._id}`, state: course }}>
                                <div className="main-img w-100">
                                    <img src={course.courseImage} className="img-over w-100" alt={course.title} />
                                </div>
                                <h5 className="slide-title">{course.title}</h5>
                            </Link>

                            
                            <p className="slide-text">
                                <span>
                                    
                                    { course?.tLesson > 1 ? `${course?.tLesson} Lessons` : `${course?.tLesson} Lesson`}
                                </span>
                            </p>
                            <p className="slide-text float-left">
                                <span>
                                    {numberOfStudents} {numberOfStudents > 1 ? "Students" : "Student"}
                                </span>
                            </p>


                            <div className="clearfix"></div>
                        </div>
                    </SwiperSlide>
                );
            }
            )}
        </Swiper>

    );
}

export default PurchaseCourses
