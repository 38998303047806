import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Navbar, Button } from "react-bootstrap";
import Loader from "../Loader";
import axios from "axios";

import {
  subadminInfo
} from "../../helper/service";
import AnonymousFooter from "../common/AnonymousFooter";

export default function SubscribeConfirmation() {
  const { subadminId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [membershipName, setMembershipName] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");
  const [brandColor, setBrandColor] = useState("");
  const [countryCode, setCountryCode] = useState("");

  //get email from querystring
  const memberEmail = new URLSearchParams(window.location.search).get("email");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if(memberEmail) {
          try {
            const response = await 
            axios.post(`${process.env.REACT_APP_BACKEND_API_URL}subscriber/confirm`, {
              email: memberEmail
            });

            console.log(response, 'confirm subscriber response');
          }
          catch (error) {
            console.error("Error confirming subscriber:", error);
          }
        }
        const data = await subadminInfo(subadminId);
        let brandColor;
        if (data?.result?.validUser) {
          brandColor = data?.result?.validUser.brandColor;
        }

        setCountryCode(data.result.validUser.countryCode);
        setBrandColor(data.result.validUser.brandColor);
        localStorage.setItem(
          "partnerBrandColor",
          brandColor
        );
        setMembershipName(data.result.validUser.membershipName);

        var hasCompany =
          data.result.validUser.company &&
          data.result.validUser.company.length > 0;
        if (hasCompany) setCompanyName(data.result.validUser.company[0].name);

        var logo =
          hasCompany && data.result.validUser.company[0].logo
            ? data.result.validUser.company[0].logo
            : data.result.validUser.image;
        setLogo(logo);

        const email = hasCompany ? data.result.validUser.company[0].email : data.result.validUser.email;
        setEmail(email);

        const phone = hasCompany && data.result.validUser.company[0].phone ? data.result.validUser.company[0].phone : data.result.validUser.phoneNumber;
        setPhone(phone);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Navbar
        expand="lg"
        className="sales-navbar"
        style={{ backgroundColor: brandColor }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
        >
          <Navbar.Brand href="#">
            <img
              src={logo}
              alt="Company Logo"
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Brand href="#">
            <div>
              <div style={{ color: "white" }}>{companyName}</div>
              <div style={{ color: "white" }}>{membershipName}</div>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
        </div>
      </Navbar>
      <div style={{ padding: "0 2em", width: "100%", minHeight: "500px" }} className="d-flex justify-content-center align-items-center">
        <section className="mt-3">
          <h4>Subscribing</h4>
          <p className="text-justify">
            Thanks for confirming your subscription. You can now explore the products and services offered by {companyName}.
          </p>
          <Button variant="primary" href={`/login`} style={{ backgroundColor: brandColor }}>
            Continue
          </Button>
        </section>
      </div>
      <AnonymousFooter {...{ brandColor, companyName, email, phone, countryCode, subadminId }} />
    </>
  );
};