/* eslint-disable */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.png';
import companylogo from '../images/company-logo.svg';
import document from '../images/enduser/document.svg';
import home from '../images/enduser/home.svg';
import user from '../images/enduser/user.svg';
import calendar from '../images/enduser/calendar (11).svg';
import chat from '../images/enduser/chat (9)-1.svg';
import heart from '../images/enduser/heart (4).svg';
import folder from '../images/enduser/folder (1).svg';
import adminimg from '../images/dashboard/admin-img.png';
import image from '../images/enduser/Image.png';
import cmnt from '../images/enduser/cmnt.svg';
import like from '../images/enduser/like.svg';
import medalreward from '../images/enduser/medal-reward-svgrepo-com.svg';
import Group69330 from '../images/enduser/Group 69330.svg';
import Group69331 from '../images/enduser/Group 69331.svg';
import Group69335 from '../images/enduser/Group 69335.svg';
import Group69334 from '../images/enduser/Group 69334.svg';
import Group67949 from '../images/enduser/Group 67949.svg';
import image1 from '../images/enduser/Image-1.png';
import image2 from '../images/enduser/Image-2.png';
import image3 from '../images/enduser/Image-3.png';
import video from '../images/enduser/video-camera (2).svg';
import files from '../images/enduser/file (7).svg';
import Group69025 from '../images/enduser/Group 69025.svg';
import calendar1 from '../images/enduser/calendar (11)-1.svg';
import all1 from '../images/enduser/all-1.png';
import all2 from '../images/enduser/all-2.png';
import all3 from '../images/enduser/all-3.png';
import events from '../images/enduser/events.svg';
import socialwals from '../images/enduser/social wall.svg';
import library from '../images/enduser/library.svg';
import challenges from '../images/enduser/challenges.svg';
import courses from '../images/enduser/courses.svg'; 
import multimedia from '../images/enduser/multimedia.svg'; 
import Group69362 from '../images/enduser/Group 69362.svg';
import Group69361 from '../images/enduser/Group 69361.svg';
import ButtonIcon from '../images/enduser/Button-Icon.svg';
import Group68616 from '../images/enduser/Group 68616.svg';
import photo1 from '../images/enduser/Photo 1.png';
import photo2 from '../images/enduser/Photo 2.png';
import photo3 from '../images/enduser/Photo 3.png';
import photo4 from '../images/enduser/Photo 4.png';
import Group68618 from '../images/enduser/Group 68618.svg';
import heart4 from '../images/enduser/heart (4)-1.svg';
import chat9 from '../images/enduser/chat (9).svg';

import photo5 from '../images/enduser/photo 5.png';
import play from '../images/enduser/Play-Button.svg';
import likeo from '../images/enduser/like-o.svg';
import download from '../images/enduser/download.svg';

import photo6 from '../images/enduser/photo 6.png';

import likesmall from '../images/enduser/like-small.svg';
import book from '../images/admin/book2.svg'; 

import photo7 from '../images/enduser/photo 7.png';
import { getFolders } from '../helper/service'; 





import Header from './common/Header'; 


export default class Userdashboard extends Component {
    constructor(props){
      super(props)
      this.state = {
        folders:[]
      }
    }

    async componentDidMount(){
      let subadminId = localStorage.getItem('subadminId')
      let data = await getFolders(subadminId);
      this.setState({folders:data.result})
    }
    render() {  
     const { folders } = this.state;
     
      const BrandColor = localStorage.getItem('subadminBrandColor');
      const bgcolor = {backgroundColor : BrandColor}
      const fontcolor = {color : BrandColor}
      const bColor = BrandColor + 'b3';
      const bordercolor = {borderColor : bColor}
      const firstName =  localStorage.getItem('firstName');
      const subadminBusiness = localStorage.getItem('subadminBusiness')
      const membershipName = localStorage.getItem('membershipName');
      const subadminLogo = localStorage.getItem('subadminLogo');  
        return (
          
            <div className="wrapper">
            {/* Navbar */}
             <Header /> 
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-4">
                    <div className="col-sm-4">
                      <h2>HOME</h2>
                      <small>Welcome, {firstName}!</small>
                    </div>                                                              
                  </div>  
                </div>
              </div>
              <section className="explore-box">      
                <div className="company-info d-flex justify-content-center align-items-center">
                  <img src={subadminLogo} className="company-img mr-2 img-fluid" alt="" />
                  <p className="company-name">{subadminBusiness} <br /><small>{membershipName}</small></p>
                </div>
                <a href="#" className="explore-btn">Explore</a><br />
                <span><i className="fas fa-arrow-down" /></span>
              </section>                                                                          
              {/* <section className="news">      
                <div className="container-fluid">
                  <div className="row mb-4">
                    <div className="col-xl-1" />
                    <div className="col-xl-10 col-lg-12 col-md-12">
                      <h2 className="heading">NEWS <small>View all <i className="fas fa-angle-right" /></small></h2>
                      <div className="row">
                        <div className="col-md-4">
                          <h3 className="title">Future of customer support</h3>
                          <p className="date">20 august 2019</p>
                          <h3 className="title">Digital trends in next 2020</h3>
                          <p className="date">18 august 2019</p>
                          <h3 className="title">New iPhone leaked</h3>
                          <p className="date">15 august 2019</p>
                          <h3 className="title">iPhone has a new feature</h3>
                          <p className="date">14 august 2019</p>
                        </div>                                             
                        <div className="col-md-4">    
                          <div className="card mb-3">
                            <img src={image} className="card-img-top" alt="image" />
                            <div className="comment">         
                              <img src={cmnt} alt="comment-image" />
                              <p>23</p>
                            </div>
                            <div className="favourite">
                            <span><i className="fas fa-heart" style={fontcolor} /></span>
                            <p>23</p>           
                            </div>
                            <div className="event">
                              <img src={calendar} alt="event-image" />
                            </div>                                          
                            <h3 className="title">Business meeting</h3>
                            <p className="date">13 august 2019</p>
                          </div>                                                                                                                                                            
                        </div>                                                           
                        <div className="col-md-4">
                          <div className="card mb-3">
                            <img src={image} className="card-img-top" alt="image" />
                            <div className="comment">
                              <img src={cmnt} alt="comment-image" />
                              <p>23</p>
                            </div>
                            {/* <div className="favourite">
                              <img src={like} alt="fav-image" />
                              </div> 
                              <div className="favourite">
                              <span><i className="fas fa-heart" style={fontcolor} /></span>
                              <p>23</p>
                              </div>
                            <div className="reward">
                              <img src={medalreward} alt="reward-image" />
                            </div>
                            <h3 className="title">Never giving up</h3>
                            <p className="date">11 august 2019</p>
                          </div>
                        </div>
                      </div>
                    </div>                             
                  </div>
                </div>
              </section>       */}

              <section className="show-content">
                <div className="container">
                  <div className="template">
                    <div className="row">
                      <div className="col-3">
                        <img src={book} width={288} height={315} className="img-fluid"/>
                      </div>
                      <div className="col-6">
                        <h3 className="title">Miss Make Over</h3>
                        <p className="text">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galleyLorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>
                      <div className="col-3">
                        <img src={book} width={288} height={315} className="img-fluid"/>
                      </div>
                    </div>
                  </div>
                  <div className="template2">
                    <div className="row">
                      <div className="col-8">
                        <img src={book} width={650} height={315} className="img-fluid"/>
                      </div>
                      <div className="col-4">
                        <h3 className="title">Miss Make Over</h3>
                        <p className="text">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galleyLorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>
                    </div>
                  </div>
                  <div className="template3">
                    <div className="row">
                    <div className="col-4">
                        <h3 className="title">Miss Make Over</h3>
                        <p className="text">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galleyLorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>
                      <div className="col-4">
                        <img src={book} width={288} height={315} className="img-fluid"/>
                      </div>
                      <div className="col-4">
                        <h3 className="title">Miss Make Over</h3>
                        <p className="text">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galleyLorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>                      
                    </div>
                  </div>
                </div>

              </section>
              <section className="info-card">
                <div className="d-flex align-items-center justify-content-left flex-wrap">
                <div className="card">
                <div className="card-body">
                <div className="img-border mx-auto d-block" style={bordercolor}>
                  <div className="img-bg" style={bgcolor}>
                    <img src={events} className="card-img-top" alt="" />
                  </div>
                </div>                                 
                <h5 className="info-title">Events</h5>
                </div>
                </div>
                <div className="card">
                <div className="card-body">
                <div className="img-border mx-auto d-block" style={bordercolor}>
                  <div className="img-bg" style={bgcolor}>
                    <img src={socialwals} className="card-img-top" alt="" />
                  </div>
                </div>
                <h5 className="info-title">Social Wall</h5>
                </div>                                       
                </div>
                <div className="card">
                <div className="card-body">
                <div className="img-border mx-auto d-block" style={bordercolor}>
                  <div className="img-bg" style={bgcolor}>
                    <img src={library} className="card-img-top" alt="" />
                  </div>
                </div>
                <h5 className="info-title">Library</h5>
                </div>       
                </div>
                <div className="card">
                <div className="card-body">
                <div className="img-border mx-auto d-block" style={bordercolor}>
                  <div className="img-bg" style={bgcolor}>
                    <img src={challenges} className="card-img-top" alt="" />
                  </div>
                </div>
                <h5 className="info-title">Challenges</h5>
                </div>    
                </div>                                      
                <div className="card">
                <div className="card-body">
                <div className="img-border mx-auto d-block" style={bordercolor}>
                  <div className="img-bg" style={bgcolor}>
                    <img src={courses} className="card-img-top" alt="" />
                  </div>
                </div>
                <h5 className="info-title">Courses</h5>
                </div>                              
                </div>
                <div className="card">
                <div className="card-body">
                <div className="img-border mx-auto d-block" style={bordercolor}>
                  <div className="img-bg" style={bgcolor}>
                    <img src={multimedia} className="card-img-top" alt="" />
                  </div>                           
                </div>
                <h5 className="info-title">Multimedia</h5>
                </div>
                </div>
                </div>                   
                </section>

              {/* <section className="info-card">
                <div className="d-flex align-items-center justify-content-left flex-wrap">
                  <div className="card">
                    <div className="card-body">
                      <img src={Group69330} className="card-img-top" alt="" />
                      <h5 className="info-title">Events</h5>
                    </div>                              
                  </div>                                                                                                                         
                  <div className="card">                       
                    <div className="card-body">
                      <img src={Group69331} className="card-img-top" alt="" />
                      <h5 className="info-title">Social Wall</h5>
                    </div>
                  </div>               
                  <div className="card">                            
                    <div className="card-body">
                      <img src={Group69330} className="card-img-top" alt="" />
                      <h5 className="info-title">Library</h5>
                    </div>                         
                  </div>                                 
                  <div className="card">
                    <div className="card-body">
                      <img src={Group69330} className="card-img-top" alt="" />
                      <h5 className="info-title">Challenges</h5>
                    </div>                                                         
                  </div>                         
                  <div className="card">
                    <div className="card-body">
                      <img src={Group69335} className="card-img-top" alt="" />
                      <h5 className="info-title">Courses</h5>
                    </div>                                           
                  </div>                                                       
                  <div className="card">
                    <div className="card-body">
                      <img src={Group69334} className="card-img-top" alt="" />
                      <h5 className="info-title">Multimedia</h5>
                    </div>                                                                                
                  </div>                                    
                </div>                                                                                   
              </section> */}                              



              <section className="latest">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="heading">Latest on {subadminBusiness}</h2>
                      <h3 className="sub-heading">{membershipName}</h3>
                    </div>                               
                  </div>
                  <div className="row">
                    <div className="col-xl-8 col-lg-12">
                      <ul className="nav nav-tabs" id="latestTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <a className="nav-link active" id="youFav-tab" data-toggle="tab" href="#youFav" role="tab" aria-controls="youFav" aria-selected="true">Your Favourites</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="suggEvent-tab" data-toggle="tab" href="#suggEvent" role="tab" aria-controls="suggEvent" aria-selected="false">Suggested Events</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="latestCont-tab" data-toggle="tab" href="#latestCont" role="tab" aria-controls="latestCont" aria-selected="false">Latest Content</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="viewAll-tab" data-toggle="tab" href="#" role="tab" style={fontcolor}>View all <i className="fas fa-angle-right" /></a>
                        </li>                              
                                                        </ul>                             
                      <div className="tab-content" id="latestTabContent">
                        <div className="tab-pane fade show active" id="youFav" role="tabpanel" aria-labelledby="youFav-tab">
                          <div className="d-flex align-items-center justify-content-left flex-wrap">
                            <div className="card">                         
                              <img src={image1} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={calendar} />
                              </div>                                 
                              <span className="badge badge-over">1 ticket left</span>
                              <h5 className="title">Why choose a theme that ...</h5>
                              <p className="text">EVENT</p>
                              <p className="text">Sep 17, 2020 - 9:20AM</p>
                            </div>                  
                            <div className="card">
                              <img src={image2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={video} />
                              </div>                                                                                                                        
                              <h5 className="title">How to choose color</h5>
                              <p className="text">VIDEO</p>
                              <p className="text">Sep 17, 2020 - 10:35 min</p>
                            </div>
                            <div className="card">
                              <img src={image3} className="card-img-top" alt="" />
                              <div className="img-over">                            
                                <img src={files} />
                              </div>                   
                              <h5 className="title">Choose a theme</h5>
                              <p className="text">PAGE</p>
                              <p className="text">Sep 17, 2020 - 3 min read</p>
                            </div>
                            <div className="card">
                              <img src={image2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={Group69025} />
                              </div>
                              <h5 className="title">How to choose color</h5>
                              <p className="text">CHALLENGE</p>
                              <p className="text">Sep 17, 2020 - 9:20AM</p>
                            </div>
                            <div className="card">
                              <img src={image3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={files} />
                              </div>
                              <h5 className="title">Choose a theme</h5>
                              <p className="text">CONTENT</p>
                              <p className="text">Sep 17, 2020 - 5 pages</p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="suggEvent" role="tabpanel" aria-labelledby="suggEvent-tab">
                          <div className="d-flex align-items-center justify-content-left flex-wrap">
                            <div className="card">
                              <img src={image1} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={calendar1} />
                              </div>
                              <span className="badge badge-over">1 ticket left</span>
                              <h5 className="title">Why choose a theme that ...</h5>
                              <p className="text">EVENT</p>
                              <p className="text">Sep 17, 2020 - 9:20AM</p>
                            </div>
                            <div className="card">  
                              <img src={image2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={video} />
                              </div>
                              <h5 className="title">How to choose color</h5>
                              <p className="text">VIDEO</p>
                              <p className="text">Sep 17, 2020 - 10:35 min</p>
                            </div>                                                                    
                            <div className="card">
                              <img src={image3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={files} />
                              </div>
                              <h5 className="title">Choose a theme</h5>
                              <p className="text">PAGE</p>
                              <p className="text">Sep 17, 2020 - 3 min read</p>
                            </div>
                            <div className="card">
                              <img src={image2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src="images/enduser/Group 69025.svg" />
                              </div>
                              <h5 className="title">How to choose color</h5>
                              <p className="text">CHALLENGE</p>
                              <p className="text">Sep 17, 2020 - 9:20AM</p>
                            </div>                                                             
                            <div className="card">
                              <img src={image3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={files} />
                              </div>
                              <h5 className="title">Choose a theme</h5>
                              <p className="text">CONTENT</p>
                              <p className="text">Sep 17, 2020 - 5 pages</p>
                            </div>                                                                                
                          </div>                                                       
                        </div>
                        <div className="tab-pane fade" id="latestCont" role="tabpanel" aria-labelledby="latestCont-tab">
                          <div className="d-flex align-items-center justify-content-left flex-wrap">
                            <div className="card">
                              <img src={image1} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={calendar1} />
                              </div>                                             
                              <span className="badge badge-over">1 ticket left</span>
                              <h5 className="title">Why choose a theme that ...</h5>
                              <p className="text">EVENT</p>
                              <p className="text">Sep 17, 2020 - 9:20AM</p>
                            </div>                                                                                        
                            <div className="card">
                              <img src={image2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={video} />
                              </div>                                         
                              <h5 className="title">How to choose color</h5>
                              <p className="text">VIDEO</p>
                              <p className="text">Sep 17, 2020 - 10:35 min</p>
                            </div>                                        
                            <div className="card">
                              <img src={image3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={files} />
                              </div>                                     
                              <h5 className="title">Choose a theme</h5>
                              <p className="text">PAGE</p>
                              <p className="text">Sep 17, 2020 - 3 min read</p>
                            </div>
                            <div className="card">
                              <img src={image2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src="images/enduser/Group 69025.svg" />
                              </div>
                              <h5 className="title">How to choose color</h5>
                              <p className="text">CHALLENGE</p>
                              <p className="text">Sep 17, 2020 - 9:20AM</p>
                            </div>
                            <div className="card">
                              <img src={image3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={files} />
                              </div>                              
                              <h5 className="title">Choose a theme</h5>
                              <p className="text">CONTENT</p>
                              <p className="text">Sep 17, 2020 - 5 pages</p>
                            </div>                                              
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                      <div className="card calendar">
                        <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                          <h3 className="card-title pt-3">Calendar</h3>
                          <select className="form-control">
                            <option>2 Sep, 20 - 7 Sep, 20</option>
                            <option>2 Sep, 20 - 7 Sep, 20</option>
                          </select>
                          <img src={Group67949} width={52} height={52} />
                        </div>                                                                    
                        <div className="card-body">
                          <div className="d-flex justify-content-left text-center flex-wrap">
                            <div className="mb-2">
                              <div className="day active">
                                <span>2</span><br />
                                <span>Mon</span>
                              </div>
                              <span className="dots active" />
                            </div>
                            <div className="mb-2">
                              <div className="day">
                                <span>3</span><br />
                                <span>Tue</span>
                              </div>
                              <span className="dots" />
                            </div>
                            <div className="mb-2">
                              <div className="day">
                                <span>4</span><br />
                                <span>Wed</span>
                              </div>
                            </div>
                            <div className="mb-2">
                              <div className="day">
                                <span>5</span><br />
                                <span>Thu</span>
                              </div>
                            </div>
                            <div className="mb-2">
                              <div className="day">
                                <span>6</span><br />
                                <span>Fri</span>
                              </div>
                              <span className="dots" />
                            </div>
                            <div className="mb-2">
                              <div className="day">
                                <span>7</span><br />
                                <span>Sat</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> 

              <section className="suggested">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="sub-heading">Suggested</h3>
                      <ul className="nav nav-tabs" id="suggestedTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <a className="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">All</a>
                        </li>                                                                                                                         
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="post-tab" data-toggle="tab" href="#post" role="tab" aria-controls="post" aria-selected="false">Posts</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="videos-tab" data-toggle="tab" href="#videos" role="tab" aria-controls="videos" aria-selected="false">Videos</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="photos-tab" data-toggle="tab" href="#photos" role="tab" aria-controls="photos" aria-selected="true">Photos</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="courses-tab" data-toggle="tab" href="#courses" role="tab" aria-controls="courses" aria-selected="false">Courses</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="challenges-tab" data-toggle="tab" href="#challenges" role="tab" aria-controls="challenges" aria-selected="false">Challenges</a>
                        </li>                                    
                      </ul>
                      <div className="tab-content" id="suggestedTabContent">
                        <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                          <div className="d-flex align-items-center justify-content-left flex-wrap">
                            {folders.length !== 0 ? 
                               folders.slice(0,8).map((folder) => {
                                 return (<div className="card">                                                
                                 <img src={folder.image} className="card-img-top" alt="" />
                                 <div className="img-over">
                                   {/* <img src={Group69361} /> */}
                                 </div>                       
                                 <h5 className="title">{folder.title}</h5>
                                 <p className="text">{folder.subtitle}</p>
                               </div>)
                               }) : ""  
                          }
                            {/* <div className="card">                                                
                              <img src={all1} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={calendar1} />
                              </div>                       
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>                                                        
                            <div className="card">
                              <img src={all2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={video} />
                              </div>
                              <h5 className="title">The Journey of Dreaming</h5>
                              <p className="text">Sita Rosida</p>
                            </div>
                            <div className="card">
                              <img src={all3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={files} />
                              </div>
                              <h5 className="title">Make: Getting Started With Pro...</h5>
                              <p className="text">Casey Reas &amp; Ben Fry</p>
                            </div>
                            <div className="card">
                              <img src={all1} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={video} />
                              </div>
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>
                               <div className="card">
                              <img src={all2} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={Group69362} />
                              </div>
                              <h5 className="title">The Journey of Dreaming</h5>
                              <p className="text">Sita Rosida</p>
                            </div>
                            <div className="card">
                              <img src={all3} className="card-img-top" alt="" />
                              <div className="img-over">
                                <img src={Group69361} />
                              </div>
                              <h5 className="title">Make: Getting Started With Pro...</h5>
                              <p className="text">Casey Reas &amp; Ben Fry</p>
                            </div> */}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="post" role="tabpanel" aria-labelledby="post-tab">
                          <div className="row">
                            <div className="col-xl-8">
                              <h5 className="title">POST NOW</h5>
                              <div className="card post-now">
                                <div className="card-body">
                                  <div className="d-flex align-items-start">
                                    <img src={adminimg} className="mr-2" alt="User" />
                                    <textarea className="form-control" rows={6} placeholder="What are you thinking?" defaultValue={""} />
                                    <img src={Group68618} className="ml-2" alt="option" />                          
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <img src={ButtonIcon} className="mr-2" alt="post" />
                                    <img src={ButtonIcon} className="mr-2" alt="video" />                          
                                    <img src={ButtonIcon} className="mr-2" alt="add" /> 
                                    <select className="form-control ml-auto">
                                      <option>Groups you're in</option>
                                      <option>Groups you're in</option>
                                    </select>                         
                                    <img src={Group68616} alt="submit" width={65} height={38} /> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4">
                              <h5 className="title">LATEST POSTS <a href='#'>View all <i className="fas fa-angle-right" /></a></h5>  
                              <div className="card latest-post">
                                <div className="card-body">
                                  <div className="media mb-1">
                                    <img src={adminimg} className="mr-3" alt="user" width={38} height={38} />
                                    <div className="media-body">
                                      <h5 className="mt-0 title">Dustin Houston <span className="course-no">Course 101</span></h5>
                                      <p className="text">5min ago</p>
                                    </div>
                                  </div>
                                  <p className="content">Whether its a driving tour, a cruise or a bus, leaf viewing is a great way to spend a fall vacation</p>
                                  <div className="card-columns mb-2">
                                    <img src={photo1} alt="photo-1"/>
                                    <img src={photo2} alt="photo-2"/>
                                    <img src={photo3} alt="photo-3"/>
                                    <img src={photo4} alt="photo-4"/>
                                    <img src={photo2} alt="photo-5"/>
                                  </div>
                                  <div className="d-flex align-items-center mb-3">
                                    <div className="like mr-3">
                                      <img src={chat9} alt="post" /> 326
                                    </div>
                                    <div className="comment mr-3">
                                      <img src={chat9} alt="video" /> 148
                                    </div>  
                                    <div className="photo mr-3">                        
                                      <img src={chat9} alt="add" /> 15
                                    </div>
                                    <img src={heart4} className="ml-auto" alt="like" /> 
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <input type="text" name className="form-control" placeholder="Leave a comment" />
                                    <img src={Group68616} alt="submit" width={65} height={38} /> 
                                  </div>                   
                                </div>                          
                              </div>   
                              <div className="card latest-post">
                                <div className="card-body">
                                  <div className="media mb-1">
                                    <img src={adminimg} className="mr-3" alt="user" width={38} height={38} />
                                    <div className="media-body">
                                      <h5 className="mt-0 title">Sam Brighton <span className="course-no">Interface Challenge</span></h5>
                                      <p className="text">7min ago</p>
                                    </div>
                                  </div>
                                  <p className="content">Weekend is here! Enjoy your research and challenge for next week.</p>
                                  <div className="d-flex align-items-center mb-3">
                                    <div className="like mr-3 d-none">
                                      <img src={chat9} alt="post" /> 326
                                    </div>
                                    <div className="comment mr-3 d-none">
                                      <img src={chat9} alt="video" /> 148
                                    </div>  
                                    <div className="photo mr-3 d-none">                        
                                      <img src={chat9} alt="add" /> 15
                                    </div>
                                    <img src={heart4} className="ml-auto" alt="like" /> 
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <input type="text" name className="form-control" placeholder="Leave a comment" />
                                    <img src={Group68616} alt="submit" width={65} height={38} /> 
                                  </div>
                                </div>                                                              
                              </div>               
                            </div>                                                                    
                          </div>
                        </div>
                        <div className="tab-pane fade" id="videos" role="tabpanel" aria-labelledby="videos-tab">
                          <div className="d-flex flex-wrap">
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo5} className="video-img" alt="video" />
                                <img src={play} className="play-btn" alt="play-btn" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>
                              </div>
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>                                                        
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo6} className="video-img" alt="video" />
                                <img src={play} className="play-btn" alt="play-btn" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likesmall} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>
                              </div>                     
                              <h5 className="title">The Journey of Dreaming</h5>
                              <p className="text">Sita Rosida</p>
                            </div>                                                                 
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo7} className="video-img" alt="video" />
                                <img src={play} className="play-btn" alt="play-btn" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>
                              </div>
                              <h5 className="title">Make: Getting Started With Pro...</h5>
                              <p className="text">Casey Reas &amp; Ben Fry</p>
                            </div>
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo5} className="video-img" alt="video" />
                                <img src={play} className="play-btn" alt="play-btn" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download"/> 
                                </div>
                              </div>
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>                                                    
                          </div>
                        </div>
                        <div className="tab-pane fade" id="photos" role="tabpanel" aria-labelledby="photos-tab">
                          <div className="d-flex flex-wrap">
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo5} className="photo" alt="video" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>                                               
                              </div>
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo6} className="photo" alt="video" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likesmall} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>
                              </div>
                              <h5 className="title">The Journey of Dreaming</h5>
                              <p className="text">Sita Rosida</p>
                            </div>
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo7} className="photo" alt="video" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>                                        
                              </div>
                              <h5 className="title">Make: Getting Started With Pro...</h5>
                              <p className="text">Casey Reas &amp; Ben Fry</p>
                            </div>
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo5} className="photo" alt="video" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>                                
                              </div>
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo5} className="photo" alt="video" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>
                              </div>
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>                             
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo6} className="photo" alt="video" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likesmall} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>                    
                              </div>                       
                              <h5 className="title">The Journey of Dreaming</h5>
                              <p className="text">Sita Rosida</p>
                            </div>
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo7} className="photo" alt="video" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>                                     
                              </div>                                                
                              <h5 className="title">Make: Getting Started With Pro...</h5>
                              <p className="text">Casey Reas &amp; Ben Fry</p>
                            </div>                                                                                   
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-left">
                                <img src={photo5} className="photo" alt="video" />
                                <div className="d-flex flex-column side-icon ml-3">
                                  <img src={likeo} alt="like" />
                                  <img src={download} alt="download" /> 
                                </div>                                                                          
                              </div>                                                              
                              <h5 className="title">Feel Free To Focus</h5>
                              <p className="text">Michael Hyaat</p>
                            </div>                            
                          </div>
                        </div>
                        <div className="tab-pane fade" id="courses" role="tabpanel" aria-labelledby="courses-tab">
                          <div className="d-flex flex-wrap">
                            <div className="card">
                              <img src="images/enduser/Mask group.png" className="course-img" alt="course" />
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="user">
                                  <img src="images/enduser/user 2.svg" alt="like" /> 25
                                </div>
                                <div className="like">
                                  <img src={likesmall} alt="download" /> 28,500
                                </div>
                                <div className="lessons">
                                  <img src="images/enduser/lesson.svg" alt="download" /> 36 Lessons
                                </div>
                              </div>
                              <p className="timing">Sep 17, 2020 - 9:20AM</p>
                              <h5 className="title">Everything You Need to Know About Business</h5>
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="trainee">
                                  <img src="images/enduser/Rectangle 22.png" className="mr-2" alt="like" />
                                  <span>Nicole Brown</span>
                                </div>
                                <p className="fees">$49.65</p>
                              </div>          
                            </div>
                            <div className="card">
                              <img src="images/enduser/Mask group 1.png" className="course-img" alt="course" />
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="user">
                                  <img src="images/enduser/user 2.svg" alt="like" /> 25
                                </div>
                                <div className="like">
                                  <img src={likesmall} alt="download" /> 28,500
                                </div>                   
                                <div className="lessons">
                                  <img src="images/enduser/lesson.svg" alt="download" /> 36 Lessons
                                </div>                            
                              </div>
                              <p className="timing">OPEN</p>
                              <h5 className="title">Statistics Data Science and Business Analysis</h5>
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="trainee">
                                  <img src="images/enduser/Rectangle 22.png" className="mr-2" alt="like" />
                                  <span>Nicole Brown</span>
                                </div>                           
                                <p className="fees">FREE</p>
                              </div>                               
                            </div>                                           
                            <div className="card">
                              <img src="images/enduser/Mask group 2.png" className="course-img" alt="course" />
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="user">
                                  <img src="images/enduser/user 2.svg" alt="like" /> 25
                                </div>
                                <div className="like">
                                  <img src={likesmall} alt="download" /> 28,500
                                </div>
                                <div className="lessons">
                                  <img src="images/enduser/lesson.svg" alt="download" /> 36 Lessons
                                </div>
                              </div>                                    
                              <p className="timing">OPEN</p>
                              <h5 className="title">AWS Certified Solutions Architect Associate</h5>
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="trainee">
                                  <img src="images/enduser/Rectangle 22.png" className="mr-2" alt="like" />
                                  <span>Nicole Brown</span>
                                </div>                                                              
                                <p className="fees">$49.65</p>
                              </div>                                         
                            </div>                                               
                            <div className="card">
                              <img src="images/enduser/Mask group 2.png" className="course-img" alt="course" />
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="user">
                                  <img src="images/enduser/user 2.svg" alt="like" /> 25
                                </div>
                                <div className="like">
                                  <img src={likesmall} alt="download" /> 28,500
                                </div>
                                <div className="lessons">
                                  <img src="images/enduser/lesson.svg" alt="download" /> 36 Lessons
                                </div>                                                                                                       
                              </div>                                                                                                                                                   
                              <p className="timing">OPEN</p>
                              <h5 className="title">AWS Certified Solutions Architect Associate</h5>
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="trainee">
                                  <img src="images/enduser/Rectangle 22.png" className="mr-2" alt="like" />
                                  <span>Nicole Brown</span>
                                </div>                                    
                                <p className="fees">$49.65</p>
                              </div>                                                                                 
                            </div>                                                                            
                          </div>
                        </div>
                        <div className="tab-pane fade" id="challenges" role="tabpanel" aria-labelledby="challenges-tab">
                          <div className="d-flex flex-wrap">
                            <div className="card">
                              <img src="images/enduser/Mask group.png" className="course-img" alt="course" />
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="user">
                                  <img src="images/enduser/user 2.svg" alt="like" /> 25
                                </div>
                                <div className="like">
                                  <img src={likesmall} alt="download" /> 28,500
                                </div>
                                <div className="lessons">
                                  <img src="images/enduser/calendar-small.svg" alt="download" /> 6 Days
                                </div>
                              </div>
                              <p className="timing">Sep 17, 2020 - 9:20AM</p>
                              <h5 className="title">Everything You Need to Know About Business</h5>
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="trainee">
                                  <img src="images/enduser/Rectangle 22.png" className="mr-2" alt="like" />
                                  <span>Nicole Brown</span>
                                </div>
                                <p className="fees">$49.65</p>
                              </div>
                            </div>
                            <div className="card">
                              <img src="images/enduser/Mask group 1.png" className="course-img" alt="course" />
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="user">
                                  <img src="images/enduser/user 2.svg" alt="like" /> 25
                                </div>
                                <div className="like">
                                  <img src={likesmall} alt="download" /> 28,500
                                </div>
                                <div className="lessons">
                                  <img src="images/enduser/calendar-small.svg" alt="download" /> 6 Days
                                </div>
                              </div>
                              <p className="timing">OPEN</p>
                              <h5 className="title">Statistics Data Science and Business Analysis</h5>
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="trainee">
                                  <img src="images/enduser/Rectangle 22.png" className="mr-2" alt="like" />
                                  <span>Nicole Brown</span>
                                </div>
                                <p className="fees">FREE</p>
                              </div>
                            </div>
                            <div className="card">
                              <img src="images/enduser/Mask group 2.png" className="course-img" alt="course" />
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="user">
                                  <img src="images/enduser/user 2.svg" alt="like" /> 25
                                </div>
                                <div className="like">
                                  <img src={likesmall} alt="download" /> 28,500
                                </div>
                                <div className="lessons">
                                  <img src="images/enduser/calendar-small.svg" alt="download" /> 6 Days
                                </div>                                 
                              </div>                
                              <p className="timing">OPEN</p>
                              <h5 className="title">AWS Certified Solutions Architect Associate</h5>
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="trainee">
                                  <img src="images/enduser/Rectangle 22.png" className="mr-2" alt="like" />
                                  <span>Nicole Brown</span>
                                </div>                
                                <p className="fees">$49.65</p>
                              </div>                                 
                            </div>                                                 
                            <div className="card">
                              <img src="images/enduser/Mask group 2.png" className="course-img" alt="course" />
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="user">                                                         
                                  <img src="images/enduser/user 2.svg" alt="like" /> 25
                                </div>                                                                                 
                                <div className="like">
                                  <img src={likesmall} alt="download" /> 28,500
                                </div>
                                <div className="lessons">                                                         
                                  <img src="images/enduser/calendar-small.svg" alt="download" /> 6 Days
                                </div>
                              </div>
                              <p className="timing">OPEN</p>
                              <h5 className="title">AWS Certified Solutions Architect Associate</h5>
                              <div className="d-flex justify-content-between align-items-center align-content-center">
                                <div className="trainee">
                                  <img src="images/enduser/Rectangle 22.png" className="mr-2" alt="like" />
                                  <span>Nicole Brown</span>
                                </div>
                                <p className="fees">$49.65</p>
                              </div>                                                   
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )
    }
}
