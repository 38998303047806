import React from 'react'
import searchDark from "../../images/enduser/search-dark.svg";

export default function EventSearchBox({ searchData }) {
  return (
    <div className="col-md-12">
      <div className=" top-buttons">
        <div className="d-flex justify-content-left flex-wrap">
          <div className="member-search">
            <input
              type="text"
              className="form-control"
              placeholder="Search Events"
              name="search"
              onChange={searchData}
            />
            <img
              src={searchDark}
              className="search-icon"
              onClick={searchData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
