const shortenFileName = (fileName) => {
    const maxLength = 30;
    if (fileName.length <= maxLength) return fileName;
    const start = fileName.substring(0, 20);
    const end = fileName.substring(fileName.length - 7);
    return `${start}...${end}`;
};
function stripHtml(html) {
    const noImagetext = removeImages(html);
  
    const text = noImagetext.replace(/<\/?[^>]+(>|$)/g, "");
      const cleanedText = text.trim() === '<p><br></p>' ? '' : text;
  
    return cleanedText;
  }
  
  function removeImages(html) {
    return html.replace(/<img[^>]*>/g, "");
  }
  
export { shortenFileName , stripHtml };