import React from 'react'
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player'

const supportedImageFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
const supportedVideoFileTypes = ["mp4", "webm", "mov", "wmv", 'mpeg', "mkv", "ts", "avi", "wbem", 'x-ms-wma'];
const supportedAudioFileTypes = ['wma', "mpeg", "mp3", "ogg", "oga", "wav", "aac",]

// #region Styles

const thumbnail = {
    width: '250px',
    height: 'auto',
    objectFit: 'cover'
}

// #endregion

export default function MediaView({ url, variation="thumbnail" }) {
    if (!url) return

    const type = url.split('.').pop()

    if (supportedImageFileTypes.includes(type)) {
        if (variation === 'thumbnail') {
            return <img src={url} alt="media"  style={thumbnail} />
        } else {
            return <img src={url} alt = "media" className='image-fluid' />
        }
    } else if (supportedVideoFileTypes.includes(type)) {
        return (
            <ReactPlayer
                config={{
                    file: { attributes: { controlsList: "nodownload" } },
                }}
                url={url}
                controls={true}
                playing={false}
                type="video/mov"
            // width="423"
            // height="353"
            />
        )
    } else if (supportedAudioFileTypes.includes(type)) {
        return (
            <ReactAudioPlayer src={url} controls={true} />
        )
    } else {
        return
    }
}
