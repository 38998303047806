import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useSearchQuery } from "../../contexts/SearchContext";
import { getContentBySubs } from "../../helper/service";
import { IgetContentBySubsResponse } from "../../interfaces/interfaces";

function ContentList() {
  const getContentBySubsQuery = useQuery<IgetContentBySubsResponse>({
    queryKey: ["getContentBySubs"],
    queryFn: () => {
      let subadminId = localStorage.getItem("subadminId");
      return getContentBySubs(subadminId);
    },
  });
  const [searrchQuery, setSearchQuery] = useSearchQuery();

  if (getContentBySubsQuery.isLoading) return <Spinner animation="border" />;

  const contentIsAvailable =
    getContentBySubsQuery.isSuccess &&
    Array.isArray(getContentBySubsQuery.data?.result) &&
    getContentBySubsQuery.data?.result?.length > 0;

  const filteredContents = () => {
    if (!contentIsAvailable) return [];

    return getContentBySubsQuery.data?.result?.filter((content) => {
      return content.propertise
        ?.at(0)
        ?.title?.toLowerCase()
        .includes(searrchQuery.toLowerCase());
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <tbody>
          {contentIsAvailable &&
            filteredContents()?.map((content) => {
              const coverImgUrl = content.propertise?.at(0)?.cover_image;
              const title = content.propertise?.at(0)?.title;
              let hiddenForMemberCeck = content?.folderId?.hiddenForMembers;
              let scheduleDate = content.propertise?.at(0)?.schedule;
              let scheduleDateCheck;
              if (scheduleDate && !isNaN(Date.parse(scheduleDate))) {
                scheduleDateCheck = new Date(scheduleDate);
              } else {
                scheduleDateCheck = null; // or any default value you want to use
              }
              let currentDate = new Date();

              // const schedule = content?.schedule;
              // let timeInfo = "";
              // if (schedule)
              // timeInfo = isToday(schedule)
              //   ? "Live"
              //   : format(new Date(content?.schedule || ""), "dd-MMMM-yyyy");
              // if(contentCreatedDate<formattedDateTime){
              if (
                (currentDate > scheduleDateCheck ||
                  scheduleDate == null ||
                  scheduleDate == undefined ||
                  scheduleDate == "") &&
                !hiddenForMemberCeck
              ) {
                return (
                  <tr key={content._id}>
                    <td>
                      <a
                        href={`/view-content?id=${content._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={coverImgUrl}
                          width={54}
                          height={54}
                          className="rounded-circle"
                          alt={title}
                        />
                      </a>
                    </td>
                    <td>
                      <h6 className="title">{title}</h6>
                    </td>
                    {/* {content?.schedule && (
                    <td>
                      <h6 className="title">{timeInfo}</h6>
                    </td>
                  )} */}
                  </tr>
                );
              }
              //  }
            })}
        </tbody>
      </table>
    </div>
  );
}

export default ContentList;
