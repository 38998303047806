import React, { useState } from "react";
import Header from "../common/Header";
import Sidebar from "./Sidebar";
import { updatePassword } from "../../helper/service";
import Loader from "../Loader";
import SuccessPopup from "./SuccessPopup";

export default function Changepassword() {
  const [isLoader, setIsLoader] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSuccess, setisSuccess] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();

    if (newPassword === confirmPassword) {
      setIsLoader(true);
      setisSuccess(false);
      const userId = localStorage.getItem("user_id");
      let data = {
        currentPassword: currentPassword,
        newPassword: newPassword,
        conPassword: confirmPassword,
        userId: userId,
      };
      updatePassword(data)
        .then((response) => {
          if (response.data.status === 200) {
            setisSuccess(true);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
          } else {
            alert("Please fill correct current Password");
          }
        })
        .catch((err) => {
          alert("Please fill correct current Password");
        })
        .finally(() => {
          setIsLoader(false);
        });
    } else {
      alert("New password and confirm password should be same.");
    }
  };

  return (
    <div className="wrapper">
      <Header />
      {isLoader ? <Loader /> : <></>}
      {isSuccess ? (
        <SuccessPopup message="Password changed successfully" />
      ) : (
        <></>
      )}
      <div className="content-wrapper">
        <section className="profile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-4 pr-0 pl-lg-3 pl-0">
                <Sidebar />
              </div>
              <div className="col-xl-9 col-lg-8 pl-0 pr-lg-3 pr-0">
                <div className="tab-content" id="profile-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="change-password"
                    role="tabpanel"
                    aria-labelledby="change-password-tab"
                  >
                    <h2 className="title">Change Password</h2>
                    <form name="form" onSubmit={submitForm}>
                      <div className="form-group">
                        <label>Enter Current Password</label>
                        <input
                          required
                          type="password"
                          className="form-control"
                          id
                          placeholder="********"
                          name="currentPassword"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          value={currentPassword}
                        />
                      </div>
                      <div className="form-group">
                        <label>Enter New Password</label>
                        <input
                          required
                          type="password"
                          className="form-control"
                          id
                          placeholder="********"
                          name="newPassword"
                          onChange={(e) => setNewPassword(e.target.value)}
                          value={newPassword}
                        />
                      </div>
                      <div className="form-group">
                        <label>Enter Confirm/Repeat New Password</label>
                        <input
                          required
                          type="password"
                          className="form-control"
                          id
                          placeholder="********"
                          name="conPassword"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </div>
                      <div className="d-flex justify-content-center flex-wrap flex-sm-nowrap">
                        <button type="submit" className="btn btn-blue">
                          Set new password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
