import React from 'react'
import AllCoursesFoldersList from "./AllCoursesFoldersList";
import magnifiyingGlass from "../../images/admin/magnifiying-glass.svg";
import EnrolledCoursesFoldersList from './EnrolledCoursesFoldersList';
import searchDark from '../../images/enduser/search-dark.svg';
function EnrolledCoursesSection() {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  return (
    <section className="courses-folders">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex items-content-center">
            <h5 className="fs-6 ">Your Courses</h5>
            <hr className="flex-fill ml-4" />
          </div>
          <div className="col-4">
            <div className="search-course">
              <input
                type="text"
                className="form-control"
                placeholder="Search your course"
                name="search"
                value={searchQuery}
                onChange={(e) => handleSearch(e)}
              />
              <span className="search-icon">
                <img src={searchDark} alt="Search" />
              </span>
            </div>
          </div>
          <div className="col-md-12">
            <EnrolledCoursesFoldersList searchQuery={searchQuery} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default EnrolledCoursesSection