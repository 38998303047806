import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

export default function EventTiming({ eventDate, eventEndDate }) {
    const eventStart = new Date(eventDate);
    const eventEnd = new Date(eventEndDate);
    const startDate = eventStart.toLocaleDateString();
    const startTime = eventStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const endDate = eventEnd.toLocaleDateString();
    const endTime = eventEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return (
        <div className="mt-3 d-flex justify-content-between align-items-start flex-wrap">
            <FontAwesomeIcon icon={faCalendar} className='mr-1 branded-text' />
            <div className="event-date mr-auto">
                {startDate} - {endDate} ({startTime} - {endTime})
            </div>
        </div>
    )
}
