import { useQuery } from "@tanstack/react-query";
import { type } from "os";
import React from "react";
import { Spinner } from "react-bootstrap";
import { getContentByFolderId } from "../../helper/service";
import { IgetContentByFolderIdResponse } from "../../interfaces/interfaces";

type ContentListByFolderProps = {
  folderId: string | undefined;
};

function ContentListByFolder({ folderId }: ContentListByFolderProps) {
  const getContentByFolderIdQuery = useQuery<IgetContentByFolderIdResponse>({
    queryKey: ["getContentByFolderId", folderId],
    queryFn: () => {
      return getContentByFolderId(folderId);
    },
  });

  if (getContentByFolderIdQuery.isLoading)
    return <Spinner animation="border" />;

  if (getContentByFolderIdQuery.data?.result?.content?.length === 0)
    return <div className="no-content">No content found</div>;

  return (
    <>
      {getContentByFolderIdQuery.data?.result?.content?.map((content) => {
        return (
          <a
            href={`/view-content?id=${content._id}`}
            target="_blank"
            rel="noreferrer"
            key={content._id}
          >
            <div className="uploaded-file row align-items-center">
              <div
                className="col-2" /*onClick={this.viewcontent.bind(this, vid._id)}*/
              >
                <img
                  src={content.propertise?.at(0)?.cover_image}
                  className="img-fluid"
                  width={67}
                  height={67}
                  alt={content?.propertise?.at(0)?.title}
                />
              </div>
              <div className="col-10">
                <h5 className="file-title">
                  {content?.propertise?.at(0)?.title} -{" "}
                  {content?.propertise?.at(0)?.short_description}{" "}
                </h5>
              </div>
            </div>
          </a>
        );
      })}
    </>
  );
}

export default ContentListByFolder;
