import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import membership from '../images/membership-bg.svg';
import { createPasswords } from '../helper/service';
import ConfirmationModal from './Setting/ConfirmationModal';
const initialState = {
  fields: {},
  errors: {},
  isSignup: false,
  error: false,
  firstName: '',
  lastName: '',
  maskEmail: '',
  maskPhone: '',
  is_redirect: false,
  newPasswordType: "password",
  conPasswordType: "password",
  showModal: false,
}

export default class Passwordupdate extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  onChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  submitForm(e) {
    e.preventDefault();
    const { password, conpassword, } = this.state.fields;

    if (password === conpassword) {

      createPasswords(password).then((err, res) => {
        this.setState({ is_redirect: true })
      });


    } else {
      this.setState({
        showModal:true,
      });
    }
  }

  validate = () => {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Username is required";
    } else {
      errors["email"] = "";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Password is required";
    } else {
      errors["password"] = "";
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  showNewPassword() {
    if (this.state.newPasswordType === "text") {
      this.setState({ newPasswordType: "password" })
    } else {
      this.setState({ newPasswordType: "text" })
    }
  }

  showConPassword() {
    if (this.state.conPasswordType === "text") {
      this.setState({ conPasswordType: "password" })
    } else {
      this.setState({ conPasswordType: "text" })
    }
  }
  handleCloseModal = () => {
    this.setState({ showModal: false });
  }
  render() {

    if (this.state.is_redirect) {
      return <Navigate to="/welcome" />;
    }

    document.body.style.backgroundImage = "url(" + membership + ")";
    const brandColor = localStorage.getItem("subadminBrandColor") ?? "";
    if(this.state.showModal)
    {
      return(
        <>
          <ConfirmationModal type="warning" title="Password Not Matched!!" message="New and Confirm Password should be same" onClose={this.handleCloseModal}/>
        </>
      )
    }
    return (

      <section id="create-password" className="d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h2 className="create-title">Create Password</h2>
                  <form name="form" onSubmit={this.submitForm}>
                    <div className="form-group mb-4">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="fas fa-lock" /></span>
                        </div>
                        <input type={this.state.newPasswordType} className="form-control" placeholder="New Password" required name="password" onChange={this.onChange} />
                        <div className="input-group-append" onClick={this.showNewPassword.bind(this)}>
                          <div className="input-group-text">
                            <span className="fas fa-eye" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="fas fa-lock" /></span>
                        </div>
                        <input type={this.state.conPasswordType} className="form-control" placeholder="Confirm Password" required name="conpassword" onChange={this.onChange} />
                        <div className="input-group-append" onClick={this.showConPassword.bind(this)}>
                          <div className="input-group-text">
                            <span className="fas fa-eye" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button type="submit" className="btn btn-info" style={{backgroundColor: brandColor}}>Confirm</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      //    <section id="user-membership-login" className="d-flex align-items-center">
      //     <div className="container">
      //       <div className="row justify-content-center">
      //         <div className="col-lg-5">
      //           <div className="card">
      //             <div className="card-body text-center">

      //             <div className="login-logo">
      //               <img src={logo} className="img-fluid mx-auto d-block" alt="logo" />
      //             </div> 

      //               <p className="user-membership-login-text">Create Password</p>          
      //             <form name="form" onSubmit={this.submitForm}> 
      //             <div className="form-group"> 
      //                 <div className="input-group"> 
      //                   <input type="password" className="form-control" placeholder="Password" name="password" onChange={this.onChange}/>
      //                   <span className="errormessage">{this.state.errors.email}</span>
      //                 </div>
      //                 </div>

      //                 <div className="form-group"> 
      //                 <div className="input-group input2"> 
      //                   <input type="password" className="form-control" placeholder="Confirm Password" name="conpassword" onChange={this.onChange} />
      //                   <span className="errormessage">{this.state.errors.conpassword}</span>
      //                 </div>
      //                 </div>
      //                 <div className="row"> 
      //                   <div className="col-12 text-center"> 
      //                     <button type="submit" className="btn btn-info">Confirm</button>
      //                   </div>
      //                 </div>
      //               </form>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </section>
    )
  }
}