import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Loader from "../Loader";
import { NavLink } from "react-router-dom";
import TopSearchResultComponent from "./TopSearchResultComponent";
import { challengesDetailsApi } from "../../helper/service";
import { useParams } from "react-router-dom";

export default function ChallengeDetailPage(props) {
  const urlParams = useParams();

  const [is_loader, setIsLoader] = useState(false);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [tLesson, setTLesson] = useState(0);
  const [tUser, setTUser] = useState(0);
  const [challangeId, setChallangeId] = useState(urlParams.id);

  useEffect(() => {
    const idd = urlParams.id;
    setChallangeId(idd);

    challengesDetailsApi(idd).then((data) => {
      const filteredChallenge = data.result.filter((item) => item._id == idd);
      console.log(filteredChallenge, "iddata");
      setImage(filteredChallenge[0].challengeImage);
      setTitle(filteredChallenge[0].title);
      setDesc(filteredChallenge[0].description);
      setPrice(filteredChallenge[0].amount);
      setTUser(filteredChallenge[0].tUser);
    });
    handleLessonCount();
  }, []);

  const handleLessonCount = (count) => {
    setTLesson(count);
  };

  return (
    <div className="wrapper">
      <Header />
      {is_loader ? <Loader /> : <></>}

      <div className="content-wrapper admin-courses">
        <section className>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-7">
                  <h2 className="title">
                    {title}{" "}
                    <NavLink to="/challenges-library">
                      Return to Challenge
                    </NavLink>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="course-detail">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="course-img text-center">
                  <img src={image} className="img-fluid course-detail-img" />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap">
              <p className="course-info">
                {" "}
                {tLesson} Activities | {tUser} Students{" "}
              </p>
              <p className="price ml-auto">
                {price ? (
                  <>
                    Price : <span>$ {price}</span>
                  </>
                ) : (
                  ""
                )}
              </p>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div
                  className="description-content text"
                  dangerouslySetInnerHTML={{ __html: desc }}
                ></div>
              </div>
            </div>
          </div>
        </section>

        <TopSearchResultComponent
          type="details"
          chlessonid={challangeId}
          handleLessonCount={handleLessonCount}
        />
      </div>
    </div>
  );
}
