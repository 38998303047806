import React, { useEffect, useState, useCallback } from "react";
import { getAllCourses } from "../../helper/service";
import { Spinner, Row, Col} from "react-bootstrap";
import CourseCard from "./CourseCard";

function CourseGrid({ searchQuery }) {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [brandColor, setBrandColor] = useState("");
  const [visibleCourses, setVisibleCourses] = useState(10);

  useEffect(() => {
    const storedBrandColor = localStorage.getItem("subadminBrandColor");
    if (storedBrandColor) {
      setBrandColor(storedBrandColor);
    }
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await getAllCourses();
        const userId = localStorage.getItem("user_id");
        const filteredCourses = response.result.filter(
          (course) => !course?.students?.includes(userId)
        );
        
        setCourses(filteredCourses);
      } catch (err) {
        setError("Something went wrong! Failed to load courses.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);


  const displayedCourses = courses
  .filter((course) => {
    return (
      !searchQuery ||
      course?.title?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  })
  .slice(0, visibleCourses);

  const handleLoadMore = () => {
    setVisibleCourses((prev) => prev + 5);
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <p className="text-danger p-5">{error}</p>;
  }

  if (displayedCourses.length === 0) {
    return (
      <p className="p-5">
        No courses found
        {searchQuery && (
          <span>
            {" "}
            for <i className="text-danger">"{searchQuery}"</i>
          </span>
        )}
      </p>
    );
  }

  return (
    <div className="course-grid-container ml-4">
      <div className="courses-container">
        {displayedCourses.map((course) => {
          const userId = localStorage.getItem("user_id");
          const studentEnroll = course?.students?.includes(userId);
          const lessonCount =
            typeof course?.tLesson === "number" && course?.tLesson > 0;

          if (
            !studentEnroll &&
            lessonCount &&
            course?.enrollment !== "Paused"
          ) {
            return (
              <div
                key={course._id}
                className="course-item courses-slider highlight-card mt-3"
              >
                <CourseCard
                  course={course}
                  showEnrollBtn={true}
                  showLink={false}
                />
              </div>
            );
          }
          return null;
        })}
      </div>

      {courses.length > visibleCourses && (
        <Row className="justify-content-center mt-4">
          <Col xs="auto">
            <button
              className="load-more-button"
              style={{
                backgroundColor: `${brandColor !== null ? brandColor : ""}`,
              }}
              onClick={handleLoadMore}
              variant="primary"
            >
              Load More
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default CourseGrid;