import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getCourseLessonById } from "../../helper/service";
import { IgetCourseLessonByIDResponse } from "../../interfaces/interfaces";
import Header from "../common/Header";
import Loader from "../Loader";
import AttachedLessonFiles from "./AttachedLessonFiles";
import LessonCommentSection from "./LessonCommentSection";
import ReactPlayer from "react-player";
import { startOrCompleteLesson, getNextLessonDetails, getNextOrPrevLessonDirect } from '../../helper/service'
import CommonModal from "../common/CommonModal";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LessonsSidebar from "./LessonsSidebar";
import ConfirmationModal from "../Setting/ConfirmationModal";
function LessonDetailPage() {
  let navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [lessonId, setLessonId] = useState('');
  const [lesson, setLesson] = useState({});
  const [courseName, setCourseName] = useState('');
  const [lessonTitle, setLessonTitle] = useState('');
  const [lessonFile, setLessonFile] = useState('');
  const [previousLessonIdForBack, setPreviousLessonIdForBack] = useState('');
  const [lessonCompletionRestriction, setLessonCompletionRestriction] = useState(false);
  const [checkForGetUpdatedData, setCheckForGetUpdatedData] = useState(false);
  const [previousLesson, setPreviousLesson] = useState('');
  const [lessonModuleId, setLessonModuleId] = useState('');
  const [lessonCourse, setLessonCourse] = useState({});
  const [lessonStatus, setLessonStatus] = useState('');
  const [lessonDescription, setLessonDescription] = useState('');
  const [lessonGoal, setLessonGoal] = useState('');
  const [resourceFiles, setResourceFiles] = useState([]);
  const [specificCourseId, setSpecificCourseId] = useState('');
  const [lessonOrder, setLessonOrder] = useState(0);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [lastClicked, setLastClicked] = useState(false);
  const [lastLessonClickedId, setLastLessonClickedId] = useState();
  const [courseTotalLesson, setCourseTotalLesson] = useState(0);
  const [courseCompletedLesson, setCourseCompletedLesson] = useState(0);




  const supportedVideoFileTypes = ["mp4", "webm", "mov", "wmv", 'mpeg', "mkv", "ts", "avi", "wbem", 'x-ms-wma'];
  const supportedAudioFileTypes = ['wma', "mpeg", "mp3", "ogg", "oga", "wav", "aac",]

  const [type, setType] = useState('video');
  const [courseId, setCourseId] = useState('');
  const [isMargin, setIsMargin] = useState(false);


  useEffect(() => {

    let lessonIdSp = localStorage.getItem("lessonId");
    let count = localStorage.getItem("lessonCountOrder");
    let num = 0
    if (count && id != lessonIdSp) {
      num = Number(count) + 1;
    }
    else {
      num = 1
    }
    localStorage.setItem("lessonCountOrder", num.toString());
    localStorage.setItem("lessonId", id as string);;
    bindLessonDetails(id);
  }, [id])
  let [showNextLessonModal, setShowNextLessonModal] = useState(false)
  let [nextLessonId, setNextLessonId] = useState()

  let brandColor = localStorage.getItem("subadminBrandColor");
  var goToBackCourseOrModule = localStorage.getItem("goToBackCourseOrModule");

  useEffect(() => {
    if (lessonFile) {
      const lastIndex = lessonFile.lastIndexOf('.');
      const lastElement = lessonFile.substring(lastIndex + 1);
      if (supportedVideoFileTypes.includes(lastElement)) {
        setType("video");
      }
      else if (supportedAudioFileTypes.includes(lastElement)) {
        setType("audio");
      }
      else {
        setType('');
      }
    }
  }, [lessonFile])


  const bindLessonDetails = async (id) => {
    setPageIsLoading(true);
    await getCourseLessonById(id).then((data) => {

      if (data.result.status === 200 && data.result.validUser) {
        setLesson(data.result.validUser);
        setLessonId(data?.result?.validUser?._id);
        setCourseName(
          typeof data?.result?.validUser?.courseId !==
            "string"
            ? data?.result?.validUser?.courseId?.title
            : "");
        setLessonTitle(data?.result?.validUser?.title);
        setLessonFile(data?.result?.validUser?.lessonsFile);
        setPreviousLessonIdForBack(data?.result?.validUser?.previousLesson);
        setLessonCompletionRestriction(data?.result?.validUser?.lessonCompletionRestriction);
        setPreviousLesson(data?.result?.validUser?.previousLesson);
        setLessonModuleId(data?.result?.validUser?.moduleId);
        setLessonCourse(data?.result?.validUser?.courseId);
        setCourseId(data?.result?.validUser?.courseId?._id);
        setLessonStatus(data?.result?.validUser?.lessonStatus);
        setLessonDescription(data?.result?.validUser?.description);
        setLessonGoal(data?.result?.validUser?.goal); 
        setResourceFiles(data?.result?.validUser?.newResource);
        setSpecificCourseId(typeof data?.result?.validUser?.courseId !== "string" ? data?.result?.validUser?.courseId?._id : "");
        setLessonOrder((data?.result?.validUser?.lessonOrder !== undefined && data?.result?.validUser?.lessonOrder !== null && typeof data?.result?.validUser?.lessonOrder !== "string") ? data?.result?.validUser?.lessonOrder : "");
      }
    }).catch((err) => {
      console.log("LessonDetailPage - bindLessonDetails - " + err);
    })
      .finally(() => {
        setPageIsLoading(false);
      });;
  }
  // let startAndCompleteLesson = async () => {
  //   let subscriptionId = localStorage.getItem('subscriptionId');
  //   if (!lessonOrder || lessonOrder == undefined || lessonOrder == null) {
  //     if (!previousLesson || previousLesson == " ") {
  //       const data = {
  //         id: id,
  //         lessonStatus: 'started',
  //         previousLesson: previousLesson,
  //         subscriptionId: subscriptionId,
  //       }
  //       let res = await startOrCompleteLesson(data);
  //       console.log(res, "start and complete")
  //       if (res.status == 200) {
  //         setLessonStatus('started')
  //         alert("Lesson started successfully")
  //       }
  //     }
  //     else if (previousLesson && previousLesson != " " && lessonCompletionRestriction == false) {
  //       const data = {
  //         id: id,
  //         lessonStatus: 'started',
  //         previousLesson: previousLesson,
  //         subscriptionId: subscriptionId,
  //       }
  //       let res = await startOrCompleteLesson(data);
  //       console.log(res, "start and complete")
  //       if (res.status == 200) {
  //         setLessonStatus('started')
  //         alert("Lesson started successfully")
  //       }
  //     }

  //     else if (previousLesson != " " && lessonCompletionRestriction == true) {
  //       const data = {
  //         id: id,
  //         previousLesson: previousLesson,
  //         lessonStatus: 'started',
  //         subscriptionId: subscriptionId,
  //       }
  //       let res = await startOrCompleteLesson(data);
  //       console.log(res, "start and complete")
  //       if (res.status == 200) {
  //         setLessonStatus('started')
  //         alert("Lesson started successfully")
  //       }
  //     }
  //   }
  //   else if (!lessonModuleId || lessonModuleId == undefined || lessonModuleId == null) {

  //     if (lessonOrder == 1) {
  //       const data = {
  //         id: id,
  //         lessonStatus: 'started',
  //         lessonCompletionRestriction: false,
  //         previousLesson: previousLesson,
  //         subscriptionId: subscriptionId,
  //       }
  //       let res = await startOrCompleteLesson(data);
  //       console.log(res, "start and complete")
  //       if (res.status == 200) {
  //         setLessonStatus('started')
  //         alert("Lesson started successfully")
  //       }
  //     }
  //     else if (lessonOrder && lessonCompletionRestriction == false) {
  //       const data = {
  //         id: id,
  //         lessonStatus: 'started',
  //         lessonCompletionRestriction: false,
  //         previousLesson: previousLesson,
  //         subscriptionId: subscriptionId,
  //       }
  //       let res = await startOrCompleteLesson(data);
  //       console.log(res, "start and complete")
  //       if (res.status == 200) {
  //         setLessonStatus('started')
  //         alert("Lesson started successfully")
  //       }
  //     }
  //     else if (lessonOrder && lessonCompletionRestriction == true) {
  //       if (typeof lessonCourse === 'object' && lessonCourse !== null) {
  //         const data = {
  //           id: id,
  //           lessonOrder: lessonOrder,
  //           courseId: courseId,
  //           lessonStatus: 'started',
  //           lessonCompletionRestriction: true,
  //           previousLesson: previousLesson,
  //           subscriptionId: subscriptionId,
  //         }
  //         let res = await startOrCompleteLesson(data);
  //         console.log(res, "start and complete")
  //         if (res.data.result.status != 201) {
  //           setLessonStatus('started')
  //           alert("Lesson started successfully")
  //         }
  //         else {
  //           alert(res.data.result.message)
  //         }
  //       }


  //     }
  //   }
  //   else {
  //     let courseModuleOrder = localStorage.getItem('courseModuleOrder');
  //     console.log("not module")
  //     let data;
  //     if (typeof lessonCourse === 'object' && lessonCourse !== null) {
  //       data = {
  //         id: id,
  //         lessonStatus: 'started',
  //         lessonOrder: lessonOrder,
  //         moduleId: lessonModuleId,
  //         lessonCompletionRestriction: false,
  //         courseModuleOrder: courseModuleOrder,
  //         courseId: courseId,
  //         previousLesson: previousLesson,
  //         subscriptionId: subscriptionId,
  //       }
  //     }

  //     if (lessonOrder == 1) {
  //       let res = await startOrCompleteLesson(data);
  //       console.log(res, "start and complete")
  //       if (res.status == 200) {
  //         alert("Lesson started successfully")
  //       }
  //     }
  //     else if (lessonOrder && lessonCompletionRestriction == false) {
  //       let res = await startOrCompleteLesson(data);
  //       console.log(res, "start and complete")
  //       if (res.status == 200) {
  //         alert("Lesson started successfully")
  //       }
  //     }
  //     else if (lessonOrder && lessonCompletionRestriction == true) {
  //       data.lessonCompletionRestriction = true;
  //       let res = await startOrCompleteLesson(data);
  //       console.log(res, "start and complete")
  //       if (res.data.result.status != 201) {
  //         alert("Lesson started successfully")
  //       }
  //       else {
  //         alert(res.data.result.message)
  //       }
  //     }
  //   }
  // }
  let completeLesson = async () => {
    let courseModuleOrder = localStorage.getItem('courseModuleOrder');
    let subscriptionId = localStorage.getItem('subscriptionId');
    let userId = localStorage.getItem('user_id');
    //  if (lessonStatus == "started") {
    let data;
    if (typeof lessonCourse === 'object' && lessonCourse !== null) {
      data = {
        lessonId: id,
        userId: userId,
        lessonCompletedStatus: 'completed',
        lessonOrder: lessonOrder,
        moduleId: lessonModuleId,
        courseModuleOrder: courseModuleOrder,
        courseId: courseId,
        previousLesson: previousLesson,
        subscriptionId: subscriptionId,
        lessonCompletionRestriction: lessonCompletionRestriction,
      }
    }
    else {
      data = {
        lessonId: id,
        userId: userId,
        lessonCompletedStatus: 'completed',
        subscriptionId: subscriptionId,
        lessonCompletionRestriction: lessonCompletionRestriction,
      }
    }

    let res = await startOrCompleteLesson(data);
    console.log(res, "new modification")
    let result = res?.data;
    let courseLessonList;
    let moduleLessonList;
    let moduleLessonId;
    let courseLessonId
    if (result?.message == "Please complete first previous lesson") {
      alert("Please complete the previous lesson");
    }
    else {
      if (result?.courseLesson?.length > 0) {
        courseLessonList = result?.courseLesson;
        courseLessonId = courseLessonList[0]?._id ? courseLessonList[0]?._id : courseLessonList?._id
      }
      else if (result?.moduleLesson?.length > 0) {
        moduleLessonList = result?.moduleLesson
        moduleLessonId = moduleLessonList[0]?._id ? moduleLessonList[0]?._id : moduleLessonList?._id
      }

      let nextCourseModOrder = result?.courseModOrder;


      if (nextCourseModOrder != undefined || nextCourseModOrder != null) {
        localStorage.setItem('courseModuleOrder', nextCourseModOrder)
      }

      if (moduleLessonId) {
        navigate(`/lesson/${moduleLessonId}`)
      }
      else if (courseLessonId) {
        navigate(`/lesson/${courseLessonId}`)
      }

       else {
        if(id==lastLessonClickedId && courseCompletedLesson!=courseTotalLesson && courseCompletedLesson !=0){
          setLastClicked(true)
        setCheckForGetUpdatedData(!checkForGetUpdatedData)
        }
     
       }
       
    }
    let lessonDetailsElement = document.getElementById('lessonDetailsId');
    if (lessonDetailsElement) {
      lessonDetailsElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Element with ID lessonDetailsId not found.');
    }
  }
  let hideLssonModal = () => {
    setShowNextLessonModal(false)
  }
  let goToNextLesson = () => {
    setShowNextLessonModal(false)
    navigate(`/lesson/${nextLessonId}`)
  }
  // let goToNextLessonDirect = async () => {
  //   if (!lessonOrder) {
  //     let nextLesson = await getNextLessonDetails(id)
  //     if (nextLesson?.data?.result?._id) {
  //       window.location.href = `/lesson/${nextLesson}`
  //     }
  //   } else {
  //     let data;
  //     if (typeof lessonCourse === 'object' && lessonCourse !== null) {
  //       data = {
  //         id: id,
  //         lessonOrder: lessonOrder,
  //         courseId: courseId,
  //         lesson: "next"
  //       }
  //       if (courseId == lessonModuleId) {
  //         data.moduleId = ''
  //       }
  //       else {
  //         data.moduleId = lessonModuleId
  //       }
  //     }
  //     getNextOrPrevLessonDirect(data).then(nextLesson => {
  //       let nextLessonId;
  //       console.log(nextLesson.data.result, "next lesson resp")
  //       if (nextLesson?.data?.result?.courseLesson?.length > 0 || nextLesson?.data?.result?.moduleLesson?.length > 0) {
  //         if (nextLesson?.data?.result?.courseLesson?.length > 0) {
  //           nextLessonId = nextLesson.data.result.courseLesson[0]?._id
  //         }
  //         else {
  //           nextLessonId = nextLesson.data.result.moduleLesson[0]?._id
  //         }
  //         window.location.href = `/lesson/${nextLessonId}`
  //       }
  //       else if (nextLesson?.data?.result?.message == "This is the last lesson of course or module") {
  //         alert("Succeess! You successfully completed the course.")
  //       }
  //     }).catch(err => {
  //       console.log("next lesson error " + err)
  //     })
  //   }

  // }
  // let goToPrevLessonDirect = () => {
  //   if (previousLessonIdForBack) {
  //     window.location.href = `/lesson/${previousLessonIdForBack}`
  //   }
  //   else {
  //     let data;
  //     if (typeof lessonCourse === 'object' && lessonCourse !== null) {
  //       data = {
  //         id: id,
  //         lessonOrder: lessonOrder,
  //         moduleId: lessonModuleId,
  //         courseId: courseId,
  //         lesson: "prev"
  //       }
  //       if (courseId == lessonModuleId) {
  //         data.moduleId = ''
  //       }
  //       else {
  //         data.moduleId = lessonModuleId
  //       }
  //     }
  //     getNextOrPrevLessonDirect(data).then(nextLesson => {
  //       let prevLessonId;
  //       if (nextLesson?.data?.result?.courseLesson?.length > 0 || nextLesson?.data?.result?.moduleLesson?.length > 0) {
  //         if (nextLesson.data.result.courseLesson.length > 0) {
  //           prevLessonId = nextLesson.data.result.courseLesson[0]?._id
  //         }
  //         else {
  //           prevLessonId = nextLesson.data.result.moduleLesson[0]?._id
  //         }
  //         window.location.href = `/lesson/${prevLessonId}`
  //       }
  //       else if (nextLesson?.data?.result?.message == "This is the first lesson of course or module") {
  //         alert("You can't go more back its first lesson.")
  //       }
  //     }).catch(err => {
  //       console.log("prev lesson error " + err)
  //     })
  //   }
  // }

  let goToCourseOrModulePage = () => {
    if (goToBackCourseOrModule === "Module") {
      window.location.href = `/course-module/module-details/${lessonModuleId}`;
    } else if (goToBackCourseOrModule === "Course") {
      let backToCourse = localStorage.getItem("lessonCountOrder")
      // window.location.href = `/course-module/${specificCourseId}`;
      navigate(-Number(backToCourse));
      localStorage.setItem("lessonCountOrder", "")
    }
  };
  return (

    <>

        <div className="wrapper" id="lessonDetailsId">
          <Header />
          {pageIsLoading ? <Loader /> : <></>}
          {showNextLessonModal &&
            <Modal show={showNextLessonModal} onHide={hideLssonModal}>
              <Modal.Title style={{ padding: '.7em' }}>Lesson Completed</Modal.Title>
              <p style={{ padding: '.7em' }}>Lesson completed successfully. If you want to continue to next lesson then click on continue button  </p>
              <div style={{ display: "flex", flexDirection: "row", marginLeft: "30%", marginRight: "30%", justifyContent: "space-between", marginBottom: "1em" }}>
                <Button variant="secondary" style={{ width: "45%", textAlign: "center" }} onClick={hideLssonModal}>
                  Cancel
                </Button>

                <Button variant="primary" style={{ width: "45%", textAlign: "center" }} onClick={goToNextLesson}>
                  Continue
                </Button>

              </div>
            </Modal>
          }
          <div className={`content-wrapper pr-4 ${isMargin ? 'for-content-wrapper' : ''}`}>

            {(courseCompletedLesson === courseTotalLesson && courseCompletedLesson !== 0 && lastClicked) &&
              <ConfirmationModal type="continue" title="Course Completion" message="Congratulations you have successfully completed the course" certificateLink="true" courseId={courseId} />
            }
            {(courseCompletedLesson !== courseTotalLesson && courseCompletedLesson !== 0 && lastClicked) &&
              <ConfirmationModal type="info" title="Course Completion" message="Complete all lessons to get course certificate" courseId={courseId} />
            }
            {courseId &&
              <LessonsSidebar moduleId={lessonModuleId} lessonId={lessonId} setCourseCompletedLesson={setCourseCompletedLesson}
                setCourseTotalLesson={setCourseTotalLesson} checkForGetUpdatedData={checkForGetUpdatedData} setLastLessonClickedId={setLastLessonClickedId} />
            }
            <div className="container-fluid p-4">
              <div className="row">
                <div className="col-3">
                  <div className="d-flex">
                    <p style={{ cursor: 'pointer', color: 'gray' }} onClick={goToCourseOrModulePage}>
                      Return to {goToBackCourseOrModule}
                    </p>
                  </div>
                </div>
                <div className="col-9">
                  <hr />
                </div>
                <div className="col-12">
                  <h1 className="flex-fill ">{courseName}</h1>
                </div>
                {/* <div className="d-flex flex-row ml-3 mt-2">
              {(lessonStatus == "" || lessonStatus == " " || lessonStatus == undefined || lessonStatus == null) &&
                <>
                  <i className="fa-solid fa-circle-play" style={{ color: `${brandColor}`, fontSize: '1.5em' }} onClick={startAndCompleteLesson}>
                  </i>
                  <span className="ml-2">Start</span>
                </>}
              {(lessonStatus == "completed") &&
                <>
                  <i className="fa-solid fa-circle-play" style={{ color: `${brandColor}`, fontSize: '1.5em' }} onClick={startAndCompleteLesson}>
                  </i>
                  <span className="ml-2">Completed</span>
                </>}
              {lessonStatus == 'started' &&
                <>
                  <i className="fa-solid fa-circle-stop" style={{ color: `${brandColor}`, fontSize: '1.5em' }} onClick={completeLesson}></i>
                  <span className="ml-2">Started</span>
                </>
              }
            </div> */}
              </div>
              <div className="row">
                <div className="col-3 d-flex flex-row">
                  <div>
                    <AttachedLessonFiles files={resourceFiles} />
                  </div>

                </div>

                <div className="col-9">


                  <div>
                    <h2>{lessonTitle}</h2>
                    <p className="my-5">Goal: {lessonGoal}</p>
                    {(type === "audio" || type === "video") ? (
                      <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        url={lessonFile}
                        controls={true}
                        playing={false}
                        width="100%"
                        height={type === "audio" ? "60px" : "353px"}
                      />
                    ) : (
                      <img
                        className="img-fluid rounded my-2"
                        src={lessonFile}
                        alt={lessonTitle}
                      />
                    )}
                    {/* <img
                  className="img-fluid rounded w-100 my-2"
                  src={lessonFile}
                  alt={lessonTitle}
                /> */}
                    {/* <p className="my-5">{lessonDescription}</p> */}
                    <p className="my-5" dangerouslySetInnerHTML={{ __html: lessonDescription ?? '' }}></p>
                    <div className="w-75 d-flex flex-row justify-content-between mb-3">
                      {/* <Button className="lesson-prev-next-btn" onClick={goToPrevLessonDirect}>
                  RETURN TO PREVIOUS LESSON
                </Button>
                <Button className="lesson-prev-next-btn" onClick={goToNextLessonDirect}>
                  PROCEED TO NEXT LESSON
                </Button> */}
                      <Button className="lesson-prev-next-btn" onClick={completeLesson}>
                        Complete and Continue
                      </Button>
                    </div>
                    {lessonId && <LessonCommentSection lessonId={lessonId} />}
                    {pageIsLoading ? <></> : !lessonId && <>lesson not found!</>}
                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default LessonDetailPage;
