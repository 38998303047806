import hexToRgba from 'hex-to-rgba';
class CommonUtils {
  redirect(url) {
    window.location.href = url;
  }

  reload() {
    window.location.reload();
  }

  BASEURL() {
    //return 'http://18.221.200.184/api/';
    //return 'https://apimemberapp.abcxyz.in/api/';
    return process.env.REACT_APP_BACKEND_API_URL;
  }

  getHashedDigest(plainText) {
    const crypto = require("crypto");
    const hash = crypto.createHash("sha512");
    hash.update(plainText);
    return hash.digest("hex");
  }

  getCurrentMemberDetails() {
    const BrandColor = localStorage.getItem("subadminBrandColor");
    var OpacityColor = hexToRgba(BrandColor, "0.7");

    const member = {
      bgcolor: { backgroundColor: BrandColor },
      fontcolor: { color: BrandColor },
      adminImage: localStorage.getItem("subadminImage"),
      adminFirstname: localStorage.getItem("subadminFirstName"),
      adminLastName: localStorage.getItem("subadminLastName"),
      subadminBusiness: localStorage.getItem("subadminBusiness"),
      membershipName: localStorage.getItem("membershipName"),
      subadminLogo: localStorage.getItem("subadminLogo"),
      subadminImage: localStorage.getItem("subadminImage"),
      companyName: localStorage.getItem("companyName"),
      secondaryColor: { backgroundColor: OpacityColor },
    };

    return member;
  }
}

const commonUtil = new CommonUtils();

export { commonUtil };
