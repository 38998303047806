import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { enrollChallenge } from "../../helper/service";
import SuccessPopup from "../Setting/SuccessPopup";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
let brandColor = localStorage.getItem("subadminBrandColor");
function ChallengeEnrollBtn({ className, challengeId, challenge }) {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [BrandColor, setBrandColor] = useState(brandColor);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const enrollChallngeMutation = useMutation({
    mutationKey: ["enrollChallenge", challengeId],
    mutationFn: () => {
      return enrollChallenge(challengeId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getRecentChallenges"]);
      setShowSuccessPopup(true);
    },
  });

  if (!challengeId) return null;

  const handleEnroll = () => {
    let subsId=localStorage.getItem("subscriptionId");
  
    if (challenge?.acceptIndPayment && !challenge?.memberAccessLevel?.includes(subsId)) {
      navigate(`/challengecheckout/${challengeId}`)
    }
    else {
      const confirm = window.confirm("Are you sure you want to enroll?");
    if (!confirm) return;
    enrollChallngeMutation.mutate();
    }

  };

  return (
    <>
      {showSuccessPopup ? (
        <SuccessPopup message="You have been enrolled succesfully and added to social wall for the course/challenge." />
      ) : (
        <></>
      )}
      {!challenge?.acceptPaymentSwitch &&
        <Button
          className="purchase-button"
          style={{
            backgroundColor: BrandColor ? BrandColor : "",
            minWidth: "150px",
          }}
          onClick={handleEnroll}
        >
          {enrollChallngeMutation.isPending ?
            <span> Enrolling... </span>
            :
            <span> Enroll</span>
          }
        </Button>

      }
      {challenge?.acceptPaymentSwitch &&
        <Button
          className="purchase-button"
          style={{
            backgroundColor: `${BrandColor !== null ? BrandColor : ""
              }`,
            minWidth: "150px",
          }}
          onClick={handleEnroll}
        >
          <span>Purchase at 15</span>
        </Button>
      }

    </>
  );
}

export default ChallengeEnrollBtn;
