import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import Loader from "../Loader";
import { Link, useParams, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import {
  PopularSocialList,
  getPostByWallId,
  usercreateSocialPost,
  subadminInfo,
  socialWallUsers,
  geteEnrolledWallMemById,
  PopularSocialListOnCourseBase,
  deletePostCommentByUserId,
  editPostCommentByUserId,
} from "../../helper/service";
import LikeIcon from "../../images/enduser/Icon-Checkbox-Empty.svg";
import heart from "../../images/audio/heart (4).svg";
import avatar from "../../images/avatar.jpeg";
import Group72115 from "../../images/enduser/submit-button.svg";
import Group69488 from "../../images/enduser/close-icon.svg";
import closeIcon from "../../images/enduser/modal-close-icon.svg";
import CommentSection from "./CommentSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import defaultStyle from "../../js/default.js";
import { MentionsInput, Mention } from "react-mentions";
import SuccessPopup from "../Setting/SuccessPopup";
import ConfirmationModal from "../Setting/ConfirmationModal";
import { monthNames } from "../../helper/constants";
const SocialwallDetailPage = () => {
  const { id } = useParams();
  const imgs1 = localStorage.getItem("memberImg")
    ? localStorage.getItem("memberImg")
    : localStorage.getItem("subadminImage");
  const brandColor = localStorage.getItem("subadminBrandColor");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [socialList, setSocialList] = useState([]);
  const [wallData, setWallData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allposts, setAllPosts] = useState(null);
  const [socialWall, setSocialWall] = useState("");
  const [content, setContent] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const [files, setFiles] = useState([]);
  const [specificWallMember, setSpecificWallMember] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [postId, setPostId] = useState();
  const [liked, setLiked] = useState(heart);
  const [message, setMessage] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [warningModalTitle, setWarningModalTitle] = useState("");
  const [deleteCommentId, setDeleteCommentId] = useState(null);
  const [deletePostId, setDeletePostId] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [commentToEditId, setCommentToEditId] = useState(null);
  const [commentToEditValue, setCommentToEditValue] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("user_id");
        const subscriptionId = localStorage.getItem("subscriptionId");
        if (subscriptionId != "" && subscriptionId != undefined) {
          const data = await PopularSocialList(userId);
          setSocialList(data.result);
          setLoading(false);
          selectCurrentWallPost(id);
        } else {
          let social = await PopularSocialListOnCourseBase(userId);
          setSocialList(social.result);
          setLoading(false);
          selectCurrentWallPost(id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    $(document).ready(function () {
      $(".socialCreateModal").attr("id", "socialCreateModal");
    });

    setPostId(postId);
    setSocialWall(socialWall);
    setIsEditModalVisible(isEditModalVisible);
  }, [isEditModalVisible]);

  useEffect(() => {
    if (socialList.length > 0) {
      const data = socialList.find((item) => item._id === id);
      setWallData(data);
      getSelectedWallEnrollMember(data);
    }
  }, [id, socialList]);

  useEffect(() => {
    const datas = files.map((imgs, key) => {
      let img2 = URL.createObjectURL(imgs);
      return (
        <div className="uploaded-img" key={key}>
          <img src={img2} width={238} height={160} className="img" alt="img" />
          <img
            src={Group69488}
            width={25}
            height={25}
            className="cross"
            alt="close"
            onClick={() => removeSelected(key)}
          />
        </div>
      );
    });
    setUploadedImage(datas);
  }, [files]);
  const handleDelete = (postId, commentId) => {
    setWarningModal(true);
    setWarningModalTitle("Confirm Deletion");
    setMessage("Are you sure you want to delete this comment?");
    setModalType("delete");
    setDeleteCommentId(commentId);
    setDeletePostId(postId);
  };
  const deleteComment = async () => {
    if (deleteCommentId && deletePostId) {
      try {
        setIsLoader(true);
        await deletePostCommentByUserId(deletePostId, deleteCommentId);
        selectCurrentWallPost(socialWall);
        setWarningModal(false);
      } finally {
        setIsLoader(false);
      }
    }
  };
  const handleEditComment = (postId, commentId, currentComment,userId) => {
    const formattedComment = currentComment.replace(
      /<span class="social-wall-mention">@?([^<]+)<\/span>/g, 
      (_, display) => `{{${display}:${userId}}}` 
    );
    setPostId(postId);
    setCommentToEditId(commentId);
    setCommentToEditValue(formattedComment);
    setIsEditModalVisible(true);
  };
  const handleCommentEditChange = (event) => {
    setCommentToEditValue(event.target.value);
  };
  const handleCommentEditSave = async () => {
    try {
      await editPostCommentByUserId(
        postId,
        commentToEditId,
        commentToEditValue
      );
      setCommentToEditId(null);
      setCommentToEditValue("");
      setIsEditModalVisible(false);
      selectCurrentWallPost(socialWall);
    } catch (error) {
      console.error("Failed to edit comment:", error);
      alert("There was an error saving your comment. Please try again.");
    }
  };
  const handleCancelEdit = () => {
    setCommentToEditId(null);
    setCommentToEditValue("");
    setIsEditModalVisible(false);
  };
  const selectCurrentWallPost = (id) => {
    let user_id = localStorage.getItem("user_id");
    setSocialWall(id);
    getPostByWallId(id).then((data) => {
      if (data.result.length === 0) {
        setAllPosts(
          <p className="mt-2 text-sm text-secondary">
            The wall has no posts yet, be the first to post. Click "Create a
            Post"
          </p>
        );
      } else {
        var postData = data.result.map((res, index) => {
          var d = new Date(res.createdAt);
          var months = parseInt(d.getUTCMonth());

          var schedule =
            d.getUTCDate() +
            "-" +
            monthNames[months] +
            "-" +
            d.getUTCFullYear();

          var times = d.getHours() + ":" + d.getMinutes();
          if (res.likedByUserId.length > 0) {
            let user_id = localStorage.getItem("user_id");
            var item = res.likedByUserId.find(
              (item) => item.userId === user_id
            );
            if (item) {
              if (item.userId) {
                setLiked(LikeIcon);
              } else {
                setLiked(LikeIcon);
              }
            }
          } else {
            setLiked(LikeIcon);
          }

          if (res.description != "undefined") {
            var desc = res.description;
          } else {
            var desc = "";
          }

          var fname = "";
          var lname = "";
          var img = avatar;

          if (res.subadminId) {
            fname = res.subadminId.firstName;
            lname = res.subadminId.lastName;
            if (
              res.subadminId.company[0].logo &&
              res.subadminId.company.length > 0
            ) {
              img = res.subadminId.company[0].logo;
            } else if (res.subadminId.image) {
              img = res.subadminId.image;
            } else {
              img = avatar;
            }
          }

          if (res.userId) {
            fname = res.userId.firstName;
            lname = res.userId.lastName;
            img =
              !res.userId.image || res.userId.image === ""
                ? avatar
                : res.userId.image;
          }
          return (
            <div className="posts">
              <div className="row">
                <div className="col-12">
                  <div className="media">
                    <img
                      src={img}
                      className="rounded-lg mr-3"
                      width={52}
                      height={52}
                    />
                  </div>
                </div>
                <div className="col-xl-10">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: desc.replace(
                        /<span/g,
                        `<span style="color: #12aeb4;font-weight: 500"`
                      ),
                    }}
                    className="post-text"
                  ></p>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-10">
                  <div className="show-media d-flex justify-content-left">
                    <div className="d-flex justify-content-left flex-wrap">
                      {res.imagesId.map((sub, subindex) =>
                        sub?.imgUrl && sub.imgUrl !== "N/A" ? (
                          <img
                            src={sub.imgUrl}
                            className="sm-img img-fluid"
                            alt="img"
                          />
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="comment-bg"
                style={{
                  width:
                    res.commentByUserId.length === 0 ? "fit-content" : "100%",
                }}
              >
                {res.commentByUserId.length === 0 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-chat-left-text"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                  </svg>
                )}
                {res.commentByUserId.map((sub, subindex) => {
                  var fname = "";
                  var lname = "";

                  if (sub.userId) {
                    fname = sub.userId.firstName;
                    lname = sub.userId.lastName;
                    img =
                      !sub.userId.image || sub.userId.image == ""
                        ? avatar
                        : sub.userId.image;
                  }
                  if (sub.subadminId) {
                    fname = sub.subadminId.firstName;
                    lname = sub.subadminId.lastName;
                    if (
                      sub.subadminId.company[0].logo &&
                      sub.subadminId.company.length > 0
                    ) {
                      img = sub.subadminId.company[0].logo;
                    } else if (sub.subadminId.image) {
                      img = sub.subadminId.image;
                    } else {
                      img = avatar;
                    }
                  }

                  var d1 = new Date(sub.createdAt);
                  var months1 = parseInt(d.getUTCMonth());

                  var schedule1 =
                    d1.getUTCDate() +
                    "-" +
                    monthNames[months1] +
                    "-" +
                    d1.getUTCFullYear();

                  var times1 = d1.getHours() + ":" + d1.getMinutes();
                  return (
                    <div className="row">
                      <div className="col-12">
                        <div className="media">
                          <img
                            src={img}
                            className="rounded-lg mr-3"
                            width={52}
                            height={52}
                          />
                          <div className="media-body">
                            {sub.userId && sub.userId._id === user_id && (
                              <div className="dropdown dropleft">
                                <i
                                  className="fas fa-ellipsis-v"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                />
                                <div className="dropdown-menu">
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleEditComment(res._id, sub._id, sub.comment, sub.userId._id);
                                    }}
                                  >
                                    Edit
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDelete(res._id, sub._id);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            )}
                            <h5 className="media-title comment-name">
                              {fname} {lname}
                            </h5>
                            <p className="media-text">
                              {schedule1} at {times1}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-12">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sub.comment.replace(
                              /<span/g,
                              '<span style="color: #12aeb4;font-weight: 500"'
                            ),
                          }}
                          className="post-text"
                        ></p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="row" onClick={() => commentNow(res._id)}>
                <div className="col-9">
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    name="comment"
                  />
                </div>
                <div className="col-1">
                  <img src={Group72115} className="submit-img" />
                </div>
              </div>
            </div>
          );
        });
        setAllPosts(postData);
        setOpenCommentModal(false);
      }
    });
  };

  const getSelectedWallEnrollMember = async (socialWallUserData) => {
    let enrollSocialWallMem = [];
    const currentUserID = localStorage.getItem("user_id");
    const subadminId = localStorage.getItem("subadminId");
    if (subadminId) {
      const data = await subadminInfo(subadminId);
      if (data) {
        const subadminMem = {
          id: `${data.result.validUser._id}|partner`,
          display: `${
            data.result.validUser.firstName +
            " " +
            data.result.validUser.lastName
          }`,
        };
        enrollSocialWallMem.push(subadminMem);
      }
    }

    let allowedUsers = [];

    let selectedUsers = socialWallUserData.userId.map((user) => user.userid);
    let socialWallUsersAndMemAccessLevel = {
      users: selectedUsers,
      memberAccessLevel: socialWallUserData.memberAccessLevel,
    };
    const res = await socialWallUsers(socialWallUsersAndMemAccessLevel);
    if (res?.data.result && res?.data.result.length > 0) {
      let users = res.data.result[0];
      if (Array.isArray(users)) {
        allowedUsers = res.data.result[0].map((x) => x._id);
      } else {
        allowedUsers = res.data.result._id;
      }
    }

    if (allowedUsers && Array.isArray(allowedUsers)) {
      await Promise.all(
        allowedUsers.map((item) => {
          geteEnrolledWallMemById(item)
            .then((res) => {
              const dataMem = {
                id: `${res?.result.validUser._id}`,
                display: `${
                  res?.result.validUser.firstName +
                  " " +
                  res.result.validUser.lastName
                }`,
              };
              if (currentUserID && currentUserID !== dataMem.id) {
                enrollSocialWallMem.push(dataMem);
              }
            })
            .catch((err) => {
              console.log(
                err,
                "error while getting members data for social wall"
              );
            });
        })
      );
    } else {
      console.log("allowedUsers is either undefined or not an array");
    }
    setSpecificWallMember(enrollSocialWallMem);
  };

  const commentNow = (id) => {
    setPostId(id);
    setOpenCommentModal(true);
  };

  const removeSelected = (key) => {
    const res = files.splice(key, 1);
    let datas = files.map(function (imgs, key) {
      let img2 = URL.createObjectURL(imgs);
      return (
        <div className="uploaded-img" key={key}>
          <img src={img2} width={238} height={160} className="img" alt="img" />
          <img
            src={Group69488}
            width={25}
            height={25}
            className="cross"
            alt="close"
            onClick={() => removeSelected(key)}
          />
        </div>
      );
    });
    setUploadedImage(datas);
  };

  const fileSelectedHandler = (e) => {
    const newFiles = [...files, ...e.target.files];
    setFiles(newFiles);
  };

  const onPostChange = (e) => {
    setContent(e.target.value);
  };

  const submitPost = async (event) => {
    event.preventDefault();

    if (!content) {
      setWarningModal(true);
      setMessage("Please add description to create a post.");
      setModalType("warning");
      setWarningModalTitle("Warning!");
      return;
    }
    setLoading(true);
    const subadminId = localStorage.getItem("subadminId");
    const obj = {
      socialWallId: socialWall,
      description: content,
      subadminId: subadminId,
      imagesId: files,
    };
    const res = await usercreateSocialPost(obj);
    if (res.data.message == "Success!") {
      setShowSuccessPopup(true);
    }
    selectCurrentWallPost(socialWall);
    reset();
    setLoading(false);
  };

  const reset = () => {
    setContent("");
    setUploadedImage("");
    setFiles([]);
    setShowForm(false);
  };

  const closeCommentModal = () => {
    setOpenCommentModal(false);
  };

  return (
    <div className="wrapper enduser-social-wall">
      <Header />
      {showSuccessPopup && (
        <SuccessPopup message="Post created successfully." />
      )}
      {loading && <Loader />}
      {showWarningModal && (
        <ConfirmationModal
          type={modalType}
          title={warningModalTitle}
          message={message}
          onConfirm={() => {
            if (deleteCommentId) {
              deleteComment(deleteCommentId);
            }
            setWarningModal(false);
            setWarningModalTitle("");
            setModalType("");
            setMessage("");
          }}
          onClose={() => {
            setWarningModal(false);
            setMessage("");
            setModalType("");
            setWarningModalTitle("");
          }}
        />
      )}
      <CommentSection
        showModal={openCommentModal}
        socialWallId={socialWall}
        postIdd={postId}
        selectCurrentWallPost={() => selectCurrentWallPost(id)}
        specificWallMember={specificWallMember}
        onCloseHandler={closeCommentModal}
      />
      <div className="content-wrapper admin-courses">
        <section>
          <div className="content header pt-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h2
                    className="title"
                    style={{
                      cursor: "pointer",
                      color: "gray",
                      marginTop: "8px",
                    }}
                  >
                    <Link
                      to={"/social-wall"}
                      style={{ textDecoration: "none", fontSize: "16px" }}
                    >
                      Return to Social Walls
                    </Link>
                  </h2>
                  <h3
                    className="d-flex justify-content-center"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "normal",
                      gap: "0.5rem",
                    }}
                  >
                    <p
                      style={{
                        color: `${brandColor}`,
                        fontWeight: "bold",
                        marginBottom: "0px",
                      }}
                    >
                      {wallData?.title}
                    </p>
                  </h3>
                  <div className="col-lg-4 col-md-4 col-sm-6 mx-auto">
                    <img
                      src={wallData?.image}
                      className="rounded img-fluid course-detail-img"
                      alt="Social Wall Main Image"
                    />
                  </div>
                  <div className="mt-3 w-100">
                    <h4>Description</h4>
                    <p>{wallData?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="content">
            <div className="container-fluid mt-3">
              {!showForm && (
                <div className="d-flex justify-content-center mb-3">
                  <button
                    className="btn text-white"
                    style={{
                      background: `${brandColor}`,
                      borderRadius: "1rem",
                      width: "14rem",
                    }}
                    onClick={() => setShowForm(true)}
                  >
                    CREATE A POST
                  </button>
                </div>
              )}

              {showForm && (
                <section>
                  {socialWall === "" ? (
                    ""
                  ) : (
                    <form name="form" onSubmit={submitPost}>
                      <div className="post-area">
                        <div className="row">
                          <div className="col-2 col-sm-1 pr-0">
                            <img
                              src={imgs1}
                              className="rounded-lg img-fluid ml-2"
                              width={52}
                              height={52}
                            />
                          </div>
                          <div className="col-8 pl-0">
                            <MentionsInput
                              style={defaultStyle}
                              placeholder="Post your first message and start the conversation"
                              onChange={onPostChange}
                              name="content"
                              value={content}
                            >
                              <Mention
                                data={specificWallMember}
                                markup="{{__display__:__id__}}"
                                displayTransform={(id, display) =>
                                  `@${display}`
                                }
                                className="mentions__mention"
                              />
                            </MentionsInput>
                          </div>
                        </div>
                        <div className="row align-items-end mt-2">
                          <div className="col-2 col-sm-1 pr-0 pointer">
                            <label htmlFor="choose-file">
                              <FontAwesomeIcon
                                icon={faCamera}
                                className="camera-icon ml-2 pointer"
                              />
                              <p className="text-muted text-xs">Add Photo</p>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="choose-file"
                              multiple
                              onChange={fileSelectedHandler}
                            />
                          </div>
                          <div className="col-6 col-sm-8 pl-0">
                            <div className="d-flex justify-content-left flex-wrap">
                              {uploadedImage}
                            </div>
                          </div>
                          <div className="d-flex jutify-content-space-between gap-2 col-4 col-sm-3">
                            <button type="submit" className="btn btn-custom">
                              Post Now
                            </button>
                            <button
                              type="button"
                              className="btn btn-custom"
                              onClick={() => {
                                setShowForm(false);
                                reset();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </section>
              )}

              <div className="enduser-social-wall">
                <h4>POST</h4>
                {allposts}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        className="socialCreateModal"
        show={isEditModalVisible}
        onHide={handleCancelEdit}
        backdrop="static"
      >
        <Modal.Header className="border-0 justify-content-center">
          <h5 className="modal-title mx-auto" style={{ color: "black" }}>
            EDIT COMMENT
          </h5>
          <button
            type="button"
            className="close ml-0 pl-0"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCancelEdit}
          >
            <span aria-hidden="true">
              <img src={closeIcon} alt="close" />
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="label-title">Edit your comment</label>
            <div className="topic">
              <div className="row">
                <div className="col-9">
                  <MentionsInput
                    style={defaultStyle}  
                    onChange={handleCommentEditChange}
                    value={commentToEditValue}
                    onFocus={(e) => e.preventDefault()}
                    markup="{{__display__}}"
                  >
                    <Mention
                      data={specificWallMember}
                      markup="{{__display__:__id__}}"
                      displayTransform={(id, display) => `@${display}`}
                      className="mentions__mention"
                    />
                  </MentionsInput>
                </div>
                <div className="col-3" onClick={handleCommentEditSave}>
                  <img src={Group72115} className="submit-img" alt="save" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SocialwallDetailPage;
