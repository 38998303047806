import React, { useEffect, useRef, useState } from 'react';
import Header from '../common/Header';
import { Button } from 'react-bootstrap';
import { userInfo, getCourseById } from '../../helper/service';
import { useNavigate, useParams } from 'react-router-dom';
import logo from "../../images/new-logo.png";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const CertificatePage = () => {
    const { id } = useParams();
    const brandColor = localStorage.getItem('subadminBrandColor');
    const [courseData, setCourseData] = useState({});
    const goToBackCourseOrModule = localStorage.getItem("goToBackCourseOrModule");
    const pdfRef = useRef();
    const [userInfor, setUserInfo] = useState({});
    const [userSubadminInfo, setUserSubadminInfo] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        getUserData();
        getCourseData();
    }, []);

    const getUserData = () => {
        let userId = localStorage.getItem("user_id");
        userInfo(userId).then((user) => {
            setUserInfo(user?.result?.validUser);
            let subadmin = user?.result?.validUser.subadminsId.find(subadmin => subadmin.subadminId._id == localStorage.getItem("subadminId"));
            setUserSubadminInfo(subadmin?.subadminId);
        }).catch((err) => {
            console.log("user info error is ", err);
        });
    };

    const getCourseData = () => {
        getCourseById(id)
            .then((res) => {
                if (res.result.length > 0) {
                    setCourseData(res.result[0]);
                }
            })
            .catch((error) => {
                console.error("Error fetching courses:", error);
                setCourseData({});
            });
    };

    const downloadCertificate = () => {
        const input = document.getElementById("downloadCourseCertificate");
        html2canvas(input, {
            useCORS: true,
            scale: 1.5 // Increase the scale for better quality
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdfWidth = canvas.width / 2.5;
            const pdfHeight = canvas.height / 2;
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'px',
                format: [pdfWidth, pdfHeight]
            });
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('certificate.pdf');
        });
    };

    return (
        <div className="wrapper">
            <Header />
            <div className="container-fluid p-4">
                <div className="row">
                    <div className='col-3'></div>
                    <div className='col-9'>
                        <p style={{ cursor: 'pointer', color: 'gray' }} onClick={() => navigate(`/course-module/${localStorage.getItem('couseIdForLesson')}`)}>
                            Return to {goToBackCourseOrModule}
                        </p>
                        <div className="certificate-setion">
                            <label className='top-left-border' style={{ borderTop: `7px solid ${brandColor}`, borderLeft: `7px solid ${brandColor}` }}></label>
                            <div className="certificate-border-all">
                                <label className='top-right-border' style={{ borderTop: `2px solid ${brandColor}`, borderRight: `2px solid ${brandColor}` }}></label>
                                <div className="certificate-main-content" ref={pdfRef} id='downloadCourseCertificate'>
                                    <h1 className='my-3 text-capitalize'>
                                        {userSubadminInfo?.membershipName}
                                    </h1>
                                    <h1 className='my-3'>Certificate of Excellence</h1>
                                    <label className='certificate-common-style'>Presented to</label>
                                    <h3 className='mb-4 text-capitalize' style={{ color: `${brandColor}` }}>{userInfor?.firstName} {userInfor?.lastName}</h3>
                                    <label className='certificate-common-style'>For successfully completing an online course</label>
                                    <h4 className='mb-4 text-capitalize' style={{ color: `${brandColor}` }}>{courseData?.title}</h4>
                                    <div className='d-flex flex-column'>
                                        <small>Provided by</small>
                                        <label className='certificate-common-style text-capitalize'>
                                            {userSubadminInfo?.company && Array.isArray(userSubadminInfo.company) && userSubadminInfo.company.length > 0 ? userSubadminInfo.company[0].name : 'No company name available'}
                                        </label>
                                    </div>
                                </div>
                                <label className='bottom-left-border' style={{ borderLeft: `2px solid ${brandColor}`, borderBottom: `2px solid ${brandColor}` }}></label>
                            </div>
                            <label className='bottom-right-border' style={{ borderBottom: `7px solid ${brandColor}`, borderRight: `7px solid ${brandColor}` }}></label>
                        </div>
                        <Button
                            className="download-certificate-button"
                            style={{
                                backgroundColor: `${brandColor !== null ? brandColor : ""}`,
                            }}
                            onClick={downloadCertificate}
                        >
                            <span>Download Certificate</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CertificatePage;
