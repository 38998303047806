import React, { useState } from "react";
import logo from "../images/logo.png";
import { forgotPwd } from "../helper/service";
import bgImage from "../images/body-bg.svg";
import { NavLink } from "react-router-dom";

export default function Forgotpassword() {
  const [email, setEmail] = useState();
  const [error, setError] = useState("");

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (validate()) {
      let data = {
        email: email,
      };

      try {
        forgotPwd(data)
          .then((data) => {
            alert("Password reset link has been sent to your email.");
          })
          .catch((error) => {
            alert(error);
          });
      } catch (err) {
        alert("Email does not exist.");
        console.log(err);
      }
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      setError("*Email is required");
    } else {
      setError("");
    }

    return formIsValid;
  };

  document.body.style.backgroundImage = "url(" + bgImage + ")";
  return (
    <section id="forgot" className="d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body text-center">
                <div className="forgot-logo">
                  <img
                    src={logo}
                    className="img-fluid mx-auto d-block"
                    alt="logo"
                  />
                </div>
                <h2 className="forgot-title">Forgot Password</h2>
                <p className="forgot-msg">
                  Enter your Email Address and we will send <br />
                  you a link to reset your password
                </p>
                <form name="form" onSubmit={submitForm}>
                  <div className="form-group mb-3 text-left">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                      name="email"
                      onChange={onChange}
                    />
                    {error && error != "" && (
                      <span className="errormessage">{error}</span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <NavLink to="/login">
                        <button type="button" className="btn btn-info">
                          Back
                        </button>
                      </NavLink>
                    </div>
                    <div className="col-6">
                      <button type="submit" className="btn btn-info">
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
