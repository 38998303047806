import { QueryClient } from "@tanstack/react-query";

const getQueryClient = () => {
  if (window?.queryClient) {
    return window.queryClient;
  } else {
    const queryClient = new QueryClient();
    window.queryClient = queryClient;
    return queryClient;
  }
};

export { getQueryClient };