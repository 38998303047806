import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
const SocialWallMembersModal = ({ show, onHide, membersList =[], title }) => { 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    setCurrentPage(1);
  }, [show]);
  const brandColor = localStorage.getItem("subadminBrandColor");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = membersList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(membersList.length / itemsPerPage);

  const shouldShowPagination = membersList.length >= 10;

  return (
    <Modal
      className="createFolder"
      show={show}
      onHide={onHide}
      backdrop="static"
    >
      <Modal.Header className="d-flex flex-column border-0 pb-0">
        <div className="d-flex w-100 justify-content-end align-items-end">
          <h5 className="modal-title bold" id="wantToCreateModalLabel">
            {title}
          </h5>
          <button
            onClick={onHide}
            type="button"
            className="close ml-5"
            data-dismiss="modal"
            aria-label="Close"
          >
          <FontAwesomeIcon icon={faRectangleXmark} className="close-modal" />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="d-flex flex-wrap justify-content-center align-items-center text-center">
          <div className="audio ml-md-3">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan="3">No Users Found</td>
                  </tr>
                ) : (
                  currentItems.map((user) => (
                    <tr key={user.id}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      {shouldShowPagination && (
        <div className="d-flex justify-content-center px-5">
          <span className="page-count mt-4">{`Page ${currentPage} of ${totalPages}`}</span>
          <nav className="mx-auto">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" style={{ backgroundColor: currentPage === 1 ? '#a7253fb3' : brandColor,textAlign:'center', border: 'none', color: 'white', width: '5.4em', height: '2em', borderRadius: '5px', marginTop: '15px' }} onClick={() => paginate(currentPage - 1)}>Previous</button>
              </li>
            </ul>
          </nav>
          <nav className="ml-auto">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" style={{ backgroundColor: currentPage === totalPages ? '#a7253fb3' : brandColor, border: 'none', color: 'white', width: '5em', height: '2em', borderRadius: '5px', marginTop: '15px' }} onClick={() => paginate(currentPage + 1)}>Next</button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </Modal>
  );
};

export default SocialWallMembersModal;
