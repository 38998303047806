import React from 'react';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="content-wrapper bg-white not-found-page">
          <div className="not-found-container">
            <h1 className="error-code">404</h1>
            <p className="error-message">Page Not Found</p>
            <button onClick={() => navigate("/")} className="home-button">Go Home</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;