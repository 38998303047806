import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Header from "../common/Header";
import {
  verifyAndCompleteSubscription, verifyAndCompleteCourse,enrollChallenge,
  verifyAndCompleteEvent, joinEvent, enrollCourse,verifyAndCompleteChallenge
} from "../../helper/service";

const CompleteSubscription = () => {
  const [brandColor, setBrandColor] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [courseId, setCourseId] = useState('');
  const [eventId, setEventId] = useState('');
  const [challengeId, setChallengetId] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState('');
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    setBrandColor(localStorage.getItem('subadminBrandColor'));

    const paymentIntent = new URLSearchParams(window.location.search).get(
      'payment_intent'
    );
    const subscriptionId = new URLSearchParams(window.location.search).get(
      'subscriptionId'
    );
    setSubscriptionId(subscriptionId)
    const courseId = new URLSearchParams(window.location.search).get(
      'courseId'
    );
    setCourseId(courseId);
    const eventid = new URLSearchParams(window.location.search).get(
      'eventId'
    );
    setEventId(eventid)
    const challengeid = new URLSearchParams(window.location.search).get(
      'challengeId'
    );
    setChallengetId(challengeid)
    const flow = new URLSearchParams(window.location.search).get(
      'flow'
    );

    if (!flow || flow !== "registration") {
      setShowHeader(true);
    }
    if (subscriptionId) {
      let subData = {
        paymentId: paymentIntent,
        subscriptionId: subscriptionId,
        subadminId: localStorage.getItem("subadminId"),
        prevSubs: localStorage.getItem("subscriptionId"),
      }
      verifyAndCompleteSubscription(subData)
        .then((data) => {
          if (data.status === 200 && data.result && data.result.status === 200) {
            localStorage.setItem("subscriptionId", subscriptionId)
            setIsSuccess(true);
            setPlan(data.result.planName);
          }
          else {
            setIsSuccess(false);
          }
        })
        .catch((err) => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    else if (courseId) {
      verifyAndCompleteCourse({ paymentId: paymentIntent, courseId: courseId })
        .then(async (data) => {
          if (data.status === 200 && data.result && data.result.status === 200) {
            setIsSuccess(true);
            setPlan(data.result.planName);
            let enrollUser = await enrollCourse(courseId)
          }
          else {
            setIsSuccess(false);
          }
        })
        .catch((err) => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    else if (eventid) {
      verifyAndCompleteEvent({ paymentId: paymentIntent, eventId: eventid })
        .then(async (data) => {
          if (data.status === 200 && data.result && data.result.status === 200) {
            setIsSuccess(true);
            setPlan(data.result.planName);
            let enrollUser = await joinEvent(eventid)
          }
          else {
            setIsSuccess(false);
          }
        })
        .catch((err) => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    else if (challengeid) {
      verifyAndCompleteChallenge({ paymentId: paymentIntent, challengeId: challengeid })
        .then(async (data) => {
          if (data.status === 200 && data.result && data.result.status === 200) {
            setIsSuccess(true);
            setPlan(data.result.planName);
            let enrollUser = await enrollChallenge(challengeid)
          }
          else {
            setIsSuccess(false);
          }
        })
        .catch((err) => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

  }, []);

  const boxStyle = {
    backgroundColor: brandColor,
    color: 'white'
  }

  return (
    <div className="main-subs-expired-div ">
      {showHeader ? <Header /> : <></>}
      <p className="support-img">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="920px"
          viewBox="0 0 255 282.984"
        >
        </svg>
      </p>
      {
        isLoading ?
          <div className="inner-subs-div">
            <div className="payment-failed">
              <p className="text" style={{ color: brandColor }}>
                Wisdome gives memberships, courses and coaching programs access
                to many content sharing features....
              </p>
              {subscriptionId && <div className="failed-payment-div" style={boxStyle}>
                <h2>SUBSCRIPTION SETUP</h2>
                <label className='px-3'>Please wait while we are setting up your subscription.</label>
              </div>
              }
              {courseId &&
                <div className="failed-payment-div" style={boxStyle}>
                  <h2>Course SETUP</h2>
                  <label className='px-3'>Please wait while we are setting up your course.</label>
                </div>
              }
              {eventId &&
                <div className="failed-payment-div" style={boxStyle}>
                  <h2>Event SETUP</h2>
                  <label className='px-3'>Please wait while we are setting up your event.</label>
                </div>
              }
            </div>
          </div>
          :
          <div className="inner-subs-div">
            {isSuccess ? <div className="payment-failed">
              <p className="text" style={{ color: brandColor }}>
                Wisdome gives memberships, courses and coaching programs access
                to many content sharing features....
              </p>
              <div className="failed-payment-div" style={boxStyle}>
                <h2>CONGRATULATIONS</h2>
                {subscriptionId &&
                  <label className='px-3'>{`You have successfully subscribed to "${plan}"`}</label>
                }
                {courseId &&
                  <label className='px-3'>{`You have successfully purchased "${plan}" course.`}</label>
                }
                {eventId &&
                  <label className='px-3'>{`You have successfully purchased "${plan}" event.`}</label>
                }
                {challengeId &&
                  <label className='px-3'>{`You have successfully purchased "${plan}" challenge.`}</label>
                }
              </div>
              {subscriptionId && <a onClick={() => window.location.href = "/account-details"} className="back-to-subs">
                <label style={{ color: brandColor, cursor: 'pointer' }}>GO TO PROFILE</label>
              </a>
              }
              {courseId &&
                <a onClick={() => window.location.href = "/courses"} className="back-to-subs">
                  <label style={{ color: brandColor, cursor: 'pointer' }}>GO TO COURSES</label>
                </a>
              }
              {eventId &&
                <a onClick={() => window.location.href = "/events"} className="back-to-subs">
                  <label style={{ color: brandColor, cursor: 'pointer' }}>GO TO EVENTS</label>
                </a>
              }
            </div>
              :
              <div className="payment-failed">
                <p className="text" style={{ color: brandColor }}>
                  Wisdome gives memberships, courses and coaching programs access
                  to many content sharing features....
                </p>
                <div className="failed-payment-div" style={boxStyle}>
                  <h2>Hmmmmm</h2>
                  <label>That didn't work?</label>
                  <label>Please try again</label>
                </div>
                <Link to="/account-details" className="back-to-subs">
                  <label style={{ color: brandColor }}>GO TO PROFILE</label>
                </Link>
              </div>
            }
          </div>
      }

    </div>
  );
}

export default CompleteSubscription;