import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "./contexts/auth";

const PrivateRoute = () => {
  const { user } = useAuth();
  let userData = user;
  if (!userData) {
    userData = JSON.parse(localStorage.getItem("userData"));
  }

  const subscriptionId = localStorage.getItem("subscriptionId");
  const selectedSubscription = userData?.subscriptionId?.find(
    (subs) => subs?.subsId?._id === subscriptionId
  );
  const hasSubscription =
    selectedSubscription && selectedSubscription.status !== "Invited"
      ? true
      : false;

  return userData ? (
    hasSubscription ? (
      <Outlet />
    ) : (
      <Navigate to="/subscribe-status" replace />
    )
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
