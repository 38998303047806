import React, { Component } from 'react';
import logo from '../images/logo.png';
//import membership from '../images/membership-bg.svg';

// import { login } from "../helper/service";

export default class Signup extends Component {
  render() {  

   // document.body.style.backgroundImage = "url("+membership+")";

    return (
        <section id="register" className="d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body text-center">
                  <div className="register-logo">
                    <img src={logo} className="img-fluid mx-auto d-block" alt="logo" />
                  </div>
                  <h2 className="register-title">Welcome</h2>
                  <p className="register-msg">Hello there, register your account!</p>
                  <form action method="post">
                    <div className="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <input type="text" className="form-control" id="firstName" placeholder="First name" required />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <input type="text" className="form-control" id="lastName" placeholder="Last name" required />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <input type="email" className="form-control" placeholder="Your email" required />
                    </div>
                    <div className="form-group mb-3">
                      <input type="text" className="form-control" placeholder="Business name" required />
                    </div>
                    <div className="form-group mb-3">
                      <input type="tel" className="form-control" placeholder="Phone number" required />
                    </div>
                    <div className="input-group mb-3">
                      <input type="password" className="form-control" placeholder="Password" required />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-eye" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-left">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="agree" required />
                          <label className="custom-control-label" htmlFor="agree">I agree to term &amp; conditions</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-info">register</button>
                      </div>
                      <div className="col-12">
                        <p className="footer-text text-left">You'll receive a confirmation email in your inbox with a link to activate your account</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
