import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { getRecentChallenges } from "../../helper/service";
import ChallengeEnrollBtn from "./ChallengeEnrollBtn";
import { Link } from "react-router-dom";
import placeholder from "../../images/placeholder.jpg";
import { Spinner } from "react-bootstrap";
import { challengeinsidelesson } from "../../helper/service";
import { Button } from "react-bootstrap";
function ChallengesGrid({ searchChallenge }) {
  const [lessonCount, setLessonCount] = useState({});
  const [visibleChallenges, setVisibleChallenges] = useState(10);
  const [brandColor, setBrandColor] = useState("");
  const getRecentChallengesQuery = useQuery({
    queryKey: ["getRecentChallenges"],
    queryFn: () => {
      return getRecentChallenges();
    },
  });

  const isLoading = getRecentChallengesQuery.isLoading;
  const challenges = useMemo(() => {
    return searchChallenge
      ? getRecentChallengesQuery.data?.result.filter((course) =>
          course?.title?.toLowerCase().includes(searchChallenge.toLowerCase())
        )
      : getRecentChallengesQuery.data?.result;
  }, [searchChallenge, getRecentChallengesQuery.data]);

  const calculateLessonCounts = async (challenges) => {
    const counts = {};
    for (const challenge of challenges) {
      const data = await challengeinsidelesson(challenge._id);
      const datas = {
        res: data.result,
      };
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      if (Array.isArray(datas.res)) {
        const filteredRes = datas.res.filter((item) => {
          const scheduleDate = item.schedule ? new Date(item.schedule) : null;
          return !scheduleDate || scheduleDate <= currentDate;
        });
        counts[challenge._id] = filteredRes.length;
      } else {
        counts[challenge._id] = 0;
      }
    }
    return counts;
  };

  useEffect(() => {
    if (Array.isArray(challenges)) {
      calculateLessonCounts(challenges).then((counts) => {
        setLessonCount(counts);
      });
    }
  }, [challenges]);

  useEffect(() => {
    const color = localStorage.getItem("subadminBrandColor") || "#919294";
    setBrandColor(color);
  }, []);

  const handleLoadMore = () => {
    setVisibleChallenges((prev) => prev + 5);
  };

  if (!challenges?.length) {
    return (
      <p className="p-5">
        You have not enrolled in any challenge
        {searchChallenge && (
          <span>
            {" "}
            for <i className="text-danger">"{searchChallenge}"</i>
          </span>
        )}
      </p>
    );
  }

  if (isLoading) return <Spinner />;

  console.log("challengesx", lessonCount);

  return (
    <>
      <div className="d-flex">
        {Array.isArray(challenges) &&
          challenges.map((challenge, index) => {
            if (lessonCount[challenge._id] > 0) {
              const lesson =
                lessonCount[challenge._id] > 1 ? "Activities" : "Activity";
              const Students = challenge.tUser > 1 ? "Students" : "Student";
              const imgs = challenge?.challengeImage || placeholder;
              const userId = localStorage.getItem("user_id");
              const isEnrolled = challenge?.students?.includes(userId);
              const isPaused =
                challenge?.enrollment?.toUpperCase() === "PAUSED";

              let challengeSchedule = challenge?.schedule;
              let challengeCreatedDate = "";
              let formattedDateTime = "";
              if (challengeSchedule) {
                var originalDate = new Date(challengeSchedule);
                var selectedTimeZone = localStorage.getItem("websiteTimeZone");
                if (selectedTimeZone) {
                  challengeCreatedDate = originalDate.toLocaleString("en-US", {
                    timeZone: selectedTimeZone,
                  });

                  var currentDateTime = new Date();
                  formattedDateTime = currentDateTime.toLocaleString("en-US", {
                    timeZone: selectedTimeZone,
                  });
                }
              }
              if (challengeSchedule) {
                if (
                  new Date(challengeCreatedDate) < new Date(formattedDateTime) ||
                  (isEnrolled && isPaused)
                ) {
                  return (
                    <div className="highlight-card mr-3">
                      <div className="slide-card__root">
                        {isEnrolled ? (
                          <Link to={`/challenges-library/${challenge._id}`}>
                            <div className="slide-card__img_wrapper">
                              <img
                                src={imgs}
                                className="slide-card__img_wrapper__img"
                                alt="challenge"
                              />
                            </div>
                            <h5 className="slide-card__title text-black">
                              {challenge.title}
                            </h5>
                            <div>
                              <p className="slide-card__text">
                                {lessonCount[challenge._id]} {lesson}
                              </p>
                              <p className="slide-card__text">
                                {challenge.tUser} {Students}
                              </p>
                            </div>
                          </Link>
                        ) : (
                          <div
                            onClick={() =>
                              alert("Please enroll first to access challenge")
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="slide-card__img_wrapper">
                              <img
                                src={imgs}
                                className="slide-card__img_wrapper__img"
                                alt="challenge"
                              />
                            </div>
                            <h5 className="slide-card__title">
                              {challenge.title}
                            </h5>
                            <div>
                              <p className="slide-card__text">
                                {lessonCount[challenge._id]} {lesson}
                              </p>
                              <p className="slide-card__text">
                                {challenge.tUser} {Students}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="slide-card__actions">
                          {isEnrolled ? (
                            <span className="enrolled-span">Enrolled</span>
                          ) : (
                            !isPaused && (
                              <ChallengeEnrollBtn
                                className="common-enroll-btn"
                                challengeId={challenge._id}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              } else {
                return (
                  <div className="highlight-card mr-3">
                    <div className="slide-card__root">
                      {isEnrolled ? (
                        <Link to={`/challenges-library/${challenge._id}`}>
                          <div className="slide-card__img_wrapper">
                            <img
                              src={imgs}
                              className="slide-card__img_wrapper__img"
                              alt="challenge"
                            />
                          </div>
                          <h5 className="slide-card__title text-black">
                            {challenge.title}
                          </h5>
                          <div>
                            <p className="slide-card__text">
                              {lessonCount[challenge._id]} {lesson}
                            </p>
                            <p className="slide-card__text">
                              {challenge.tUser} {Students}
                            </p>
                          </div>
                        </Link>
                      ) : (
                        <div
                          onClick={() =>
                            alert("Please enroll first to access challenge")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="slide-card__img_wrapper">
                            <img
                              src={imgs}
                              className="slide-card__img_wrapper__img"
                              alt="challenge"
                            />
                          </div>
                          <h5 className="slide-card__title">
                            {challenge.title}
                          </h5>
                          <div>
                            <p className="slide-card__text">
                              {lessonCount[challenge._id]} {lesson}
                            </p>
                            <p className="slide-card__text">
                              {challenge.tUser} {Students}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="slide-card__actions">
                        {isEnrolled ? (
                         <Button
                         className="purchase-button"
                         style={{
                           backgroundColor: `${brandColor !== null ? brandColor : ""
                             }`,
                           minWidth: "150px",
                         }}
                       >
                         <span>Enrolled</span>
                       </Button>
                        ) : (
                          !isPaused && (
                            <ChallengeEnrollBtn
                              className="common-enroll-btn"
                              challengeId={challenge._id}
                              challenge={challenge}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            }
          })}
      </div>
      {visibleChallenges <= challenges?.length && (
        <div className="flex d-flex justify-content-center mt-3">
          <button
            className="btn-load-more"
            style={{ backgroundColor: brandColor }}
            onClick={handleLoadMore}
          >
            Load More
          </button>
        </div>
      )}
    </>
  );
}

export default ChallengesGrid;
