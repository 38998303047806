import React, { Component } from "react";
import { Link } from "react-router-dom";
import Group68026 from "../../images/admin/Group 68026.svg";
import Group68025 from "../../images/admin/Group 68025.svg";
import { Modal, Button } from "react-bootstrap";
import close from "../../images/enduser/modal-close-icon.svg";
import goback from "../../images/dashboard/go-back.svg";
import submit from "../../images/enduser/submit-button.svg";
import studentImg from "../../images/dashboard/elm-general-photo-5.png";
import $ from "jquery";
import ReactPlayer from "react-player";
import placeholder from "../../images/placeholder.jpg";
import {
  getLiveLessonsModules,
  purchasecourseLessons,
} from "../../helper/service";

import {
  totalLessonOfCourse,
  getCourseLessonById,
  saveCoursecomment,
  coursecommentlist,
  livelesson,
  draftlesson,
  schedulelesson,
  getLessonById,
  CourseLatestlesson,
  CourseSchedulelesson,
  getLatestLesson,
  getScheduleLesson,
} from "../../helper/service";

export default class TopSearchResultComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      live: "",
      draft: "",
      schedule: "",
      isOpen: false,
      currentLesson: "",
      currentCourse: "",
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  // listData(){
  //     const userId = localStorage.getItem('subadminId');
  //     livelesson(userId).then(data => {
  //         this.iterateFolders(data.result,'live');
  //     })
  //     draftlesson(userId).then(data => {
  //         this.iterateFolders(data.result,'draft');
  //     })
  //     schedulelesson(userId).then(data => {
  //         const datas1 = {
  //             res:data.result
  //         }
  //         this.iterateFolders(datas1,'schedule');
  //     })
  // }

  listData() {
    const userId = localStorage.getItem("user_id");
    var lessonId = this.props.lessonId1;
    if (this.props.purchaseLessonCour) {
      purchasecourseLessons(lessonId).then((data) => {
        const res = {
          res: data.result,
        };
        this.iterateFolders(res, "live");
      });
    } else {
      if (lessonId) {
        localStorage.setItem("goToBackCourseOrModule", "Course");
        //CourseLatestlesson(lessonId).then((data) => {

        totalLessonOfCourse(lessonId).then((data) => {
          const res = {
            res: data.result,
          };
          this.iterateFolders(res, "live");
          console.log(data, "course_data");
        });

        // CourseSchedulelesson(lessonId).then((data) => {
        //   const res = {
        //     message: " Exist",
        //     status: 200,
        //     res: data.result,
        //   };
        //   this.iterateFolders(res, "schedule");

        // });
      } else if (this.props.courseId && this.props.userId && this.props.id) {
        localStorage.setItem("goToBackCourseOrModule", "Module");
        getLiveLessonsModules(
          this.props.userId,
          this.props.courseId,
          this.props.id
        ).then((moduleLesson) => {
          console.log(moduleLesson.result.res, "moduleLesson.result.res");
          this.iterateFolders(moduleLesson.result, "live");
        });
      } else {
        getLatestLesson(userId).then((data) => {
          const datas = {
            res: data.result,
          };
          this.iterateFolders(datas, "live");
        });

        // getScheduleLesson(userId).then((data) => {
        //   const datas = {
        //     res: data.result,
        //   };
        //   this.iterateFolders(datas, "schedule");
        // });

        // livelesson(userId).then((data) => {
        //   console.log("Elsee data.result===",data.result)
        //   this.iterateFolders(data.result, 'live')
        // })

        // if (this.props.details) {
        //   var datas = {
        //     res:this.props.courseLesson
        //   }

        //   var keys = this.props.keySearchData;
        //   let filteredNames = datas.res.filter(ele => ele.title.toLowerCase().includes(keys.toLowerCase()));
        //   var datas = {
        //     res:filteredNames
        //   }
        //   this.iterateFolders(datas, 'draft');
        // }

        // else {
        //   draftlesson(userId).then((data) => {
        //     this.iterateFolders(data.result, 'draft')
        //   })
        // }

        // schedulelesson(userId).then((data) => {
        //   const res = {
        //     message: ' Exist',
        //     status: 200,
        //     res: data.result,
        //   }
        //   this.iterateFolders(res, 'schedule')
        // })
      }
    }
  }

  async componentDidMount() {
    this.listData();
  }

  componentWillReceiveProps(nextProps) {
    this.listData();
  }

  deleteContent(id) {
    //     this.setState({is_loader:true})
    //     deletecourse(id).then(data=> {
    //       this.listData();
    //       alert("Post Deleted Successfully");
    //    },this)
  }

  iterateFolders = async (datas, types) => {
    if (datas?.res === undefined) return;
    if (!datas?.res?.hasOwnProperty("status")) {
      var i = 0;
      let sortedLesson = datas.res.sort((a, b) => {
        return a.lessonOrder - b.lessonOrder;
      });

      var folders = sortedLesson.map(function (subs) {
        var currentDate = new Date();
        var scheduleDate = new Date(subs.schedule);
        var textData = "";
        if (currentDate.getTime() > scheduleDate.getTime()) {
          textData = "Live";
        } else {
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          var months = parseInt(scheduleDate.getUTCMonth());
          textData =
            scheduleDate.getUTCDate() +
            1 +
            "-" +
            monthNames[months] +
            "-" +
            scheduleDate.getUTCFullYear();
        }
        if (!subs?.schedule) {
          textData = "";
        }
        if (!subs.hideForMember) {
          if (
            subs.courseId == subs.moduleId ||
            subs.courseId._id == subs.moduleId ||
            subs.moduleId == undefined ||
            subs.moduleId == null
          ) {
            i++;
            if (
              currentDate.getTime() > scheduleDate.getTime() ||
              subs.schedule == ""
            ) {
              return (
                <tr key={subs._id}>
                  <td>
                    <h3 className="table-title">{i}</h3>
                  </td>
                  <td>
                    <h3 className="table-title">{subs.title.length > 30 ? `${subs.title.substring(0, 20)}..........`: subs.title}</h3>
                  </td>
                  <td>
                    {/* {subs.goal} */}
                    <div dangerouslySetInnerHTML={{ __html: `${subs?.goal.length > 30 ? `${subs?.goal.substring(0, 30)}..........`: subs?.goal}` }}></div>
                  </td>
                  <td>{textData}</td>
                  <td>
                    {this.props.isEnrolled ? (
                      <Link
                        className="btn btn-live"
                        to={`/lesson/${(this, subs._id)}`}
                      >
                        View Lesson
                      </Link>
                    ) : (
                      <div
                        className="btn btn-live"
                        onClick={() =>
                          alert(
                            "You can't access lesson until enroll in course."
                          )
                        }
                      >
                        View Lesson
                      </div>
                    )}
                  </td>
                  <td className="text-right">
                    {/*<img src={Group68026} className="" />*/}
                    {/*<img src={Group68026} className="" />*/}
                    {/* <img src={Group68025} className="ml-2" onClick={() => {
                        const confirmBox = window.confirm(
                        "Do you want to Delete the Content?"
                        )
                        if (confirmBox === true) {
                        this.deleteContent(subs._id)
                        }
                        }}/> */}
                  </td>
                </tr>
              );
            } else if (
              currentDate.getTime() < scheduleDate.getTime() &&
              types == "schedule"
            ) {
              return (
                <tr key={subs._id}>
                  <td>
                    <h3 className="table-title">{i}</h3>
                  </td>
                  <td>
                    <h3 className="table-title">{subs.title.length > 30 ? `${subs.title.substring(0, 20)}..........`: subs.title}</h3>
                  </td>
                  <td>
                    {/* {subs.goal} */}
                    <div dangerouslySetInnerHTML={{ __html: `${subs?.goal.length > 30 ? `${subs?.goal.substring(0, 30)}..........`: subs?.goal}` }}></div>
                  </td>
                  <td>{textData}</td>
                  <td>
                    {this.props.isEnrolled ? (
                      <Link
                        className="btn btn-live"
                        to={`/lesson/${(this, subs._id)}`}
                      >
                        View Lesson
                      </Link>
                    ) : (
                      <div
                        className="btn btn-live"
                        onClick={() =>
                          alert(
                            "You can't access lesson until enroll in course."
                          )
                        }
                      >
                        View Lesson
                      </div>
                    )}
                  </td>
                  <td className="text-right">
                    {/*<img src={Group68026} className="" />*/}
                    {/*<img src={Group68026} className="" />*/}
                    {/* <img src={Group68025} className="ml-2" onClick={() => {
                        const confirmBox = window.confirm(
                        "Do you want to Delete the Content?"
                        )
                        if (confirmBox === true) {
                        this.deleteContent(subs._id)
                        }
                        }}/> */}
                  </td>
                </tr>
              );
            }
          }
        }
      }, this);
    } else {
      var folders = "No records found.";
    }

    if (types == "live") {
      this.setState({ live: folders });
    }
    if (types == "draft") {
      this.setState({ draft: folders });
    }
    if (types == "schedule") {
      this.setState({ schedule: folders });
    }
  };

  lessonDetails(lessonId) {
    getCourseLessonById(lessonId).then((data) => {
      if (data) {
        console.log(data, "data");
        var files = data.result.validUser.lessonsFile[0]["fileUrl"];
        var fileName = files.substring(
          files.lastIndexOf("/") + 1,
          files.lastIndexOf(".")
        );

        var fileExt = files.substring(files.lastIndexOf(".") + 1);

        var types = "";
        if (fileExt == "png" || fileExt == "jpeg" || fileExt == "jpg") {
          types = "image";
        }
        if (fileExt == "mp4" || fileExt == "mov") {
          types = "video";
        }
        if (fileExt == "mp3") {
          types = "audio";
        }

        this.coursecommentlist(lessonId);

        const resource = data.result.validUser.newResource.map(function (
          resource
        ) {
          var filename = resource.resourceUrl.split("/").pop();

          return (
            <div className="resourcesLists">
              <a href={resource.resourceUrl} target="_blank">
                {filename}
              </a>
              {/* &nbsp;&nbsp; <a href="#" onClick={this.removeresource.bind(this,resource._id)}>X</a> */}
            </div>
          );
        },
        this);

        this.setState({
          title: data.result.validUser.title,
          description: data.result.validUser.description,
          goal: data.result.validUser.goal,
          fileName: fileName,
          types: types,
          files: files,
          currentLesson: lessonId,
          currentCourse: data.result.validUser.courseId._id,
          resource: resource,
        });
        this.openModal();
      }
    }, this);
  }

  async savevideocomment() {
    if (this.state.comment) {
      this.setState({ is_loader: true });
      let user_id = localStorage.getItem("user_id");

      const commentData = {
        lessonId: this.state.currentLesson,
        courseId: this.state.currentCourse,
        subadminId: user_id,
        comment: this.state.comment,
      };
      await saveCoursecomment(commentData);
      this.coursecommentlist(this.state.currentLesson);
      this.setState({ is_loader: false, comment: "" });
    }
  }

  iteratecomments(datas) {
    let user_id = localStorage.getItem("user_id");
    const comment = datas.commentByUserId.map(function (vid) {
      var imgs = "";
      var key = "";
      if (vid.userId) {
        key = vid.userId;
      } else {
        key = vid.subadminId;
      }

      if (key.image == "") {
        imgs = placeholder;
      } else {
        imgs = key.image;
      }

      return (
        <div className="media">
          <img src={imgs} className="user-img img-fluid mr-3" />
          <div className="media-body">
            <h5 className="mt-0 messanger-name">
              {key.firstName} {key.lastName}
            </h5>
            <p className="messanger-text">{vid.comment}</p>
          </div>
        </div>
      );
    }, this);
    if (datas.commentByUserId.length > 0) {
      this.setState({ videocomment: comment });
    } else {
      this.setState({ videocomment: "No records found." });
    }
  }

  async coursecommentlist(idd) {
    coursecommentlist(idd).then((data) => {
      this.iteratecomments(data.result);
    });
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.savevideocomment();
    }
  }

  onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    console.log(this.state.live, "live");
    $(document).ready(function () {
      $(".audioPreviewModal").attr("id", "audioPreviewModal");
    });
    return (
      <section className="courses-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              {Array.isArray(this.state.live) &&
              this.state.live?.length > 0 &&
              this.state.live.some((x) => x !== undefined) ? (
                <>
                  <ul className="nav nav-tabs" id="coursesTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="liveLessons-tab"
                        data-toggle="tab"
                        href="#liveLessons"
                        role="tab"
                        aria-controls="liveLessons"
                        aria-selected="true"
                      >
                        Lessons For Course
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="scheduledLessons-tab" data-toggle="tab" href="#scheduledLessons" role="tab" aria-controls="scheduledLessons" aria-selected="false">Scheduled Lessons</a>
                                </li> */}
                    {/* <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="draftLessons-tab" data-toggle="tab" href="#draftLessons" role="tab" aria-controls="draftLessons" aria-selected="false">Draft Lessons</a>
                                </li> */}
                  </ul>
                  <div className="tab-content" id="coursesTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="liveLessons"
                      role="tabpanel"
                      aria-labelledby="liveLessons-tab"
                    >
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <tbody>{this.state.live}</tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="scheduledLessons"
                      role="tabpanel"
                      aria-labelledby="scheduledLessons-tab"
                    >
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <tbody>{this.state.schedule}</tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="draftLessons"
                      role="tabpanel"
                      aria-labelledby="draftLessons-tab"
                    >
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <tbody>{this.state.draft}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <Modal
          className="audioPreviewModal"
          show={this.state.isOpen}
          onHide={this.closeModal}
          backdrop="static"
        >
          <Modal.Header className="modal-header border-0 pb-0 align-items-center">
            <h5 className="modal-title pl-0">Preview Lesson</h5>
            <button
              onClick={this.closeModal}
              type="button"
              className="close ml-5"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img src={close} />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center mb-2">
              {this.state.types == "image" ? (
                <img
                  src={this.state.files}
                  className="img-fluid mx-auto d-block mb-2"
                  height="180"
                />
              ) : (
                ""
              )}

              {this.state.types == "video" ? (
                <ReactPlayer
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  url={this.state.files}
                  controls={true}
                  playing={true}
                  width="100%"
                  height="353px"
                />
              ) : (
                ""
              )}

              {this.state.types == "audio" ? (
                <ReactPlayer
                  url={this.state.files}
                  controls={true}
                  playing={true}
                  width="100%"
                  height="50px"
                />
              ) : (
                ""
              )}

              {/* <label className='text-break'>{this.state.fileName}</label> */}
            </div>
            <h5 className="modal-title mr-0">{this.state.title}</h5>
            <p className="text-break">{this.state.description}</p>
            <h5 className="modal-title mr-0 mb-2">Resources</h5>
            <div className="scroll">
              <div className="resources mb-3">
                {this.state.resource != ""
                  ? this.state.resource
                  : "Resource not available."}
              </div>
            </div>

            <div className="comments">
              <div className="form-row mb-3 align-items-center">
                <div className="col-10">
                  <input
                    type="text"
                    className="form-control leave-comment"
                    placeholder="Leave a comment"
                    name="comment"
                    onChange={this.onChange}
                    onKeyPress={this.enterPressed.bind(this)}
                    value={this.state.comment}
                  />
                </div>
                <div className="col-2">
                  <img
                    src={submit}
                    className="img-fluid comment-img"
                    width={56}
                    height={38}
                    onClick={this.savevideocomment.bind(this)}
                  />
                </div>
              </div>

              <h4 className="title">
                Comments
                {/* <div className="custom-control custom-switch float-right">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="commentSwitch"
                            defaultChecked=""
                        />
                        <label className="custom-control-label" htmlFor="commentSwitch" />
                        </div> */}
              </h4>
              <div className="messages-list">{this.state.videocomment}</div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    );
  }
}
