import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Audiolibrary from "./components/Audio/Audiolibrary";
import Myplaylist from "./components/Audio/Myplaylist";
import Dashboard from "./components/Dashboard";
import Forgotpassword from "./components/Forgotpassword";
import Login from "./components/Login";
import Membership from "./components/Membership";
import MembershipLogin from "./components/MembershipLogin";
import Passwordupdate from "./components/Passwordupdate";
import Payment from "./components/Payment";
import Plandetails from "./components/Plandetails";
import Profile from "./components/Profile";
import Signup from "./components/Signup";
import Verify from "./components/Verify";
import Welcome from "./components/Welcome";
import SocialWall from "./components/Socialwall/Socialwall";
import Content from "./components/Content/Content";
import Contentlibrary from "./components/Content/Contentlibrary";
import ContentLibraryPage from "./components/Content/ContentLibraryPage";
import Userdashboard from "./components/Userdashboard";
import Videolibrary from "./components/Video/Videolibrary";
import AccountDetails from "./components/Setting/AccountDetails";
import EditAccount from "./components/Setting/EditAccount";
import Changepassword from "./components/Setting/Changepassword";
import NotificationSettings from "./components/Setting/NotificationSettings";
import Unsubscribe from "./components/Setting/Unsubscribe";
import Contentview from "./components/Content/Contentview";
import Events from "./components/Events/Events";
import Courses from "./components/Courses/CoursesLibrary";
import CoursesDetailPage from "./components/Courses/CoursesDetailPage";
import ChallengesLibrary from "./components/Challenges/ChallengesLibrary";
import ChallengeDetailPage from "./components/Challenges/ChallengeDetailPage";
import Resetpassword from "./components/Resetpassword";
import CoursesLibraryPage from "./components/Courses/CoursesLibraryPage";
import FolderDetailPage from "./components/Content/FolderDetailPage";
import LessonDetailPage from "./components/Courses/LessonDetailPage";
import CourseModule from "./components/Courses/CourseModule";
import PrivacyPolicy from "./components/Others/PrivacyPolicy";
import TermsAndConditions from "./components/Others/TermsAndConditions";
import SubscribeStatus from "../src/components/Setting/SubscribeStatus";
import CompleteSubscription from "../src/components/Setting/CompleteSubscription";
import Invoices from "./components/Setting/Invoices";
import AllNotifications from "./components/common/AllNotifications";
import Checkout from "./components/Setting/Checkout";
import CheckoutWithRegistration from "./components/Setting/CheckoutWithRegistration";
import SalesPage from "./components/Setting/SalesPage";
import SocialwallDetailPage from "./components/Socialwall/SocialwallDetailPage";
import Loader from "./loader";
import SubscribeConfirmation from "./components/Setting/SubscribeConfirmation";

import AuthProvider from "./contexts/auth";
import PrivateRoute from "./PrivateRoute";
import hexToRgba from "hex-to-rgba";
import CertificatePage from "./components/Courses/CertificatePage";
import UnsubscribeVerification from "./components/Others/UnsubscribeVerification";
import NotFound from "./components/common/NotFound";
function SuspenseWrapper({ children }) {
  return <React.Suspense fallback={<Loader />}>{children}</React.Suspense>;
}

function App() {
  const brandColor = localStorage.getItem("subadminBrandColor") ?? "#12aeb4";
  var r = document.querySelector(":root");
  r.style.setProperty("--brand-color", brandColor);
  var opacityColor1 = hexToRgba(brandColor, "0.5");
  var opacityColor2 = hexToRgba(brandColor, "0.5");
  r.style.setProperty("--brand-color-medium",opacityColor1);
  r.style.setProperty("--brand-color-light", opacityColor2);

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route exact path="/*" element={<Navigate to="/dashboard" />} />
            <Route
              exact
              path="/login"
              element={
                <SuspenseWrapper>
                  <Login />
                </SuspenseWrapper>
              }
            />
            <Route
              exact
              path="/signup"
              element={
                <SuspenseWrapper>
                  <Signup />
                </SuspenseWrapper>
              }
            />
            <Route exact path="/membership" element={<Membership />} />
            <Route exact path="/welcome" element={<Welcome />} />
            <Route exact path="/verify" element={<Verify />} />
            <Route exact path="/course/:id/certificate/" element={<CertificatePage />} />
            <Route
              exact
              path="/membership-login"
              element={<MembershipLogin />}
            />
            <Route exact path="/forgot-password" element={<Forgotpassword />} />
            {/* TODO: Remove this route as it is not being used after verification */}
            <Route exact path="/password-update" element={<Passwordupdate />} />
            <Route exact path="/account-details" element={<AccountDetails />} />
            <Route exact path="/edit-account" element={<EditAccount />} />
            <Route exact path="/change-password" element={<Changepassword />} />

            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/plan-details" element={<Plandetails />} />
            <Route exact path="/payment" element={<Payment />} />
            {/* <Route exact path="/payment/verify" element={PaymentVerify} /> */}
            <Route exact path="/audio-library" element={<Audiolibrary />} />
            <Route exact path="/my-playlist" element={<Myplaylist />} />
            <Route exact path="/social-wall" element={<SocialWall />} />
            <Route exact path="/content" element={<Content />} />
            <Route exact path="/userdashboard" element={<Userdashboard />} />
            <Route exact path="/video-library" element={<Videolibrary />} />
            <Route
              exact
              path="/notification-settings"
              element={<NotificationSettings />}
            />
            <Route exact path="/unsubscribe" element={<Unsubscribe />} />

            <Route exact path="/folder/:id" element={<FolderDetailPage />} />
            <Route
              exact
              path="/content-library-old"
              element={<Contentlibrary />}
            />
            <Route exact path="/view-content" element={<Contentview />} />

            <Route exact path="/courses" element={<CoursesLibraryPage />} />
            <Route
              exact
              path="/course-module/:courseId"
              element={<CourseModule />}
            />
            <Route exact path="/courses-old" element={<Courses />} />
            <Route
              exact
              path="/course-module/module-details/:id"
              element={<CoursesDetailPage />}
            />
            <Route exact path="/lesson/:id" element={<LessonDetailPage />} />

            <Route
              exact
              path="/reset-password/:id"
              element={<Resetpassword />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route exact path="/unsubscribe-email" element={<UnsubscribeVerification />} />
            <Route exact path="/invoices" element={<Invoices />} />
            <Route
              exact
              path="/checkout/:subscriptionId/:subscriptionFrequency"
              element={<Checkout />}
            />
            <Route
              exact
              path="/courseCheckout/:courseId"
              element={<Checkout />}
            />
            <Route
              exact
              path="/challengecheckout/:challengeId"
              element={<Checkout />}
            />
            <Route
              exact
              path="/eventcheckout/:eventId"
              element={<Checkout />}
            />
            <Route
              exact
              path="/checkout/:subadminId/:subscriptionId/:subscriptionFrequency"
              element={<CheckoutWithRegistration />}
            />
            <Route
              exact
              path="/coursecheckout/:subadminId/:courseId/:courseFrequency"
              element={<CheckoutWithRegistration />}
            />
            <Route
              exact
              path="/eventcheckout/:subadminId/:eventId/:eventFrequency"
              element={<CheckoutWithRegistration />}
            />
            <Route exact path="/notifications" element={<AllNotifications />} />
            <Route exact path="/sales/:subadminId" element={<SalesPage />} />
            <Route exact path="/email-subscribe-confirmation/:subadminId" element={<SubscribeConfirmation />} />

            <Route
              exact
              path="/subscribe-status"
              element={<SubscribeStatus />}
            />
            <Route
              exact
              path="/complete-subscription"
              element={<CompleteSubscription />}
            />
            <Route
              exact
              path="/complete-course"
              element={<CompleteSubscription />}
            />
            <Route
              exact
              path="/complete-event"
              element={<CompleteSubscription />}
            />
            <Route
              exact
              path="/complete-challenge"
              element={<CompleteSubscription />}
            />
            <Route
              exact
              path="/social-wall/wall-detail/:id"
              element={<SocialwallDetailPage />}
            />

            {/* <Route path="/events" element={<PrivateRoute />}> */}
              <Route exact path="/events/*" element={<Events />} />
            {/* </Route> */}
            <Route path="/content-library" element={<PrivateRoute />}>
              <Route
                exact
                path="/content-library"
                element={<ContentLibraryPage />}
              />
            </Route>
            <Route path="/challenges-library" element={<PrivateRoute />}>
              <Route
                exact
                path="/challenges-library"
                element={<ChallengesLibrary />}
              />
            </Route>
            <Route path="/challenges-library/:id" element={<PrivateRoute />}>
              <Route
                exact
                path="/challenges-library/:id"
                element={<ChallengeDetailPage />}
              />
            </Route>
            <Route exact path="/not-found" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
