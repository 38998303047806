import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../common/Header";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckoutForm from "./CheckoutForm";
import Loader from "../Loader";

import {
  createMemberPayment,
  createMemberCoursePayment,
  createMemberEventPayment,
  createMemberChallengePayment,
} from "../../helper/service";

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    subscriptionId,
    subscriptionFrequency,
    flow,
    courseId,
    courseFrequency,
    eventId,
    challengeId,
  } = useParams();
  const [showPayment, setShowPayment] = useState(false);
  const [stripeOptions, setStripeOptions] = useState({});
  const [stripePromise, setstripePromise] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState(false);

  useEffect(() => {
    // to handle rerendring
    let isMounted = true;
    setIsLoading(true);
    console.log("Subscription Frequency:", subscriptionFrequency);
    if (
      (!subscriptionId ||
        subscriptionId === undefined ||
        !subscriptionFrequency ||
        subscriptionFrequency === undefined) &&
      (!courseId || courseId === undefined) &&
      (!eventId || eventId === undefined)&&
      (!challengeId || challengeId === undefined)
    )
      return;
  
    const handlePayment = (paymentFn, id, frequency) => {
      console.log("Handling payment with frequency:", frequency); 
      paymentFn(id, frequency)
        .then((data) => {
          if (isMounted && data && data.success) {
            const ops = { clientSecret: data.clientSecret };
            setPaymentIntentId(data.paymentIntentId);
            setStripeOptions(ops);
            setstripePromise(loadStripe(data.publishableKey));
            setShowPayment(true);
          }
        })
        .catch((err) => {
          if (isMounted) setHasError(true);
          console.log("create member payment error: " + err);
        })
        .finally(() => {
          if (isMounted) setIsLoading(false);
        });
    };
  
    if (subscriptionId) {
      handlePayment(createMemberPayment, subscriptionId, subscriptionFrequency);
    } else if (courseId) {
      handlePayment(createMemberCoursePayment, courseId, courseFrequency);
    } else if (eventId) {
      handlePayment(createMemberEventPayment, eventId, "onetime");
    }
    else if (challengeId) {
      handlePayment(createMemberChallengePayment, challengeId, "onetime");
    }
  
    return () => {
      isMounted = false; 
    };
  }, [subscriptionId, subscriptionFrequency, courseId, courseFrequency, eventId, challengeId]);
  

  return (
    <>
      {flow && flow === "register" ? <></> : <Header />}
      {isLoading ? <Loader /> : <></>}
      {!hasError ? (
        <div className="content-wrapper admin-social-wall">
          <h3 style={{ margin: "1em 0em 0.5em 0.5em" }}>Checkout</h3>
          <p style={{ marginLeft: "1em" }}>
            {subscriptionId &&
              "YOU ARE NOW PAYING TO SUBSCRIBE TO A PAID PLAN"}
             {courseId && "YOU ARE NOW PAYING TO ENROLL IN A PAID COURSE"}
             {eventId && "YOU ARE NOW PAYING TO REGISTER IN A PAID EVENT"}
             {challengeId && "YOU ARE NOW PAYING TO ENROLL IN A PAID CHALLENGE"}
          </p>
          <Container>
            {showPayment ? (
              <Elements stripe={stripePromise} options={stripeOptions}>
                {subscriptionId && 
                  <CheckoutForm
                    subscriptionId={subscriptionId}
                    subscriptionFrequency={subscriptionFrequency}
                    paymentIntentId={paymentIntentId}
                  />
                }
                {courseId &&
                  <CheckoutForm
                    courseId={courseId}
                    courseFrequency={courseFrequency}
                    paymentIntentId={paymentIntentId}
                  />
                }
                {eventId &&
                  <CheckoutForm
                    eventId={eventId}
                    eventFrequency='onetime'
                    paymentIntentId={paymentIntentId}
                  />
                }
                {challengeId &&
                  <CheckoutForm
                    challengeId={challengeId}
                    challengeFrequency='onetime'
                    paymentIntentId={paymentIntentId}
                  />
                }
              </Elements>
            ) : (
              <></>
            )}
          </Container>
        </div>
      ) : (
        <div className="content-wrapper admin-social-wall">
          <h3 style={{ margin: "1em 0em 0.5em 0.5em" }}>Checkout</h3>
          <p style={{ marginLeft: "1em" }}>
            Error occured while processing payment. Please try again later or
            try contacting your instructor.
          </p>
          <button
            className="btn btn-primary"
            style={{ backgroundColor: localStorage.getItem("subadminBrandColor"), border: "none", marginLeft: "1em" }}
            onClick={() => window.history.back()}
          >
            Go Back
          </button>
        </div>
      )}
    </>
  );
};

export default Checkout;
