const getDayName = (dayIndex) => {
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return dayNames[dayIndex];
};

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

function isValidDateString(dateString) {
  // Ensure the input matches a YYYY-MM-DD format (basic check)
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) return false;

  // Parse the date and check if it's valid
  const date = new Date(dateString);
  return !isNaN(date.getTime()) && date.toISOString().startsWith(dateString);
}

const isItemLive = (schedule) => {
  if(!schedule || schedule === "") return true;
  
  const currentDate = new Date();
  const originalDate = new Date(schedule);
  const selectedTimeZone = localStorage.getItem('websiteTimeZone');
  const convertedDate = originalDate.toLocaleString('en-US', { timeZone: selectedTimeZone, hour12: true });
  const scheduleDate = new Date(convertedDate);
  return currentDate > scheduleDate;
}

export { getDayName, isToday, isValidDateString, isItemLive };