import React, { Component } from 'react'
import Header from '../common/Header'; 
import Exclusion from '../../images/audio/Exclusion 50.svg';
import Mask from '../../images/audio/Mask Group 351.png';
import gallery from '../../images/gallery.svg';
import { NavLink } from 'react-router-dom';


export default class Myplaylist extends Component {
    render() {
        return (
            <div className="wrapper">
            <Header/>
            <div className="content-wrapper">   
        <section className="my-playlist">       
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center border-line">
                  <h2 className="title">My Playlist #3 <NavLink to="/audio-library">Return to Audio Library</NavLink></h2>            
                </div>
              </div>
              <div className="col-lg-11">
                <div className="playlist float-right">
                  <small className="title">Playlists</small>
                  <div>
                    <img src={Exclusion} className="left-img" />
                    <div className="name">The Adventure</div> 
                    <img src={Mask} className="right-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-11">
                <div className="d-flex align-items-center playlist-board">
                  <div>
                    <label className="upload-file" htmlFor="choose-file">
                      <input type="file" className="form-control" id="choose-file" onchange="readURL(this);" />
                      <div id="preview_img" className="d-flex align-items-center">
                        <img src={gallery} className="img-fluid mx-auto d-block" id="uploaded-image" alt="uploaded image" />
                      </div>
                    </label>
                  </div>
                  <div>
                    <h2 className="title">My Playlist #3</h2>
                    <p className="desc">No Description......</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </div>
        )
    }
}
