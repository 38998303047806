import React from 'react'
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";

const locales = {
  "en-AU": require("date-fns/locale/en-AU"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export default function EventsCalendar({events, handleSelectEvent}) {
  return (
    <div className="col-lg-12">
      <Calendar
        localizer={localizer}
        events={events}
        // adding 1 second to end time so that the calendar shows the end time
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        tooltipAccessor={(e) => {
          let timeDate = `start: ${e.start}\nend: ${e.end}`;
          return timeDate;
        }}
        onSelectEvent={(event) => handleSelectEvent(event)}
      />
    </div>
  );
}
