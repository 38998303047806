import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faRectangleXmark } from "@fortawesome/free-solid-svg-icons";

const TinyTalkChat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const showChatbot = location.pathname.includes("67b7dbcb783be391f001479a");

  if (!showChatbot) return null; // Do not render chat if not on "/support"

  return (
    <div>
      {/* Floating Chat Button */}
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "15px",
          backgroundColor: localStorage.getItem("subadminBrandColor"),
          color: "white",
          padding: "12px 16px",
          borderRadius: "50%",
          border: "none",
          cursor: "pointer",
          fontSize: "18px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          zIndex: 9999,
        }}
      >
        {isOpen ?
          <FontAwesomeIcon icon={faRectangleXmark} className='mr-1' />

          :
          <FontAwesomeIcon icon={faComment} className='mr-1' />
        }
      </button>

      {/* Chatbot Container (Hidden when isOpen=false) */}
      <div
        style={{
          position: "fixed",
          bottom: "80px",
          right: "15px",
          width: "350px",
          height: "500px",
          backgroundColor: "white",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          overflow: "hidden",
          zIndex: 9999,
          display: isOpen ? "block" : "none", // Toggle visibility
        }}
      >
        <iframe
          src="https://dashboard.tinytalk.ai/bots/22ecbc31-0519-43c0-ab3f-d16cf86df25e/chat"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ borderRadius: "10px" }}
        ></iframe>
      </div>
    </div>
  );
};

export default TinyTalkChat;