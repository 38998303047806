import React, { Component } from 'react';
import Header from './common/Header'; 
import { NavLink } from 'react-router-dom';

export default class Plandetails extends Component {
    render() {
        return (
            <div className="wrapper">
       
            <Header/>
        <div className="content-wrapper">
          <section className="plan-details">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <h2 className="title">Plan Details <a href="#">Return to My Account</a></h2>            
                  </div>
                </div>
              </div>
            </div>       
            <div className="container-fluid">
              <div className="row mb-4">
                <div className="col-sm-12">
                  <div className="card">                
                    <div className="plan">
                      <div className="d-flex justify-content-between flex-wrap">
                        <div className="title">GOLD PLAN</div>
                        <div className="title">Monthly / $125</div>                    
                      </div>
                      <p className="content">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using. here are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
                    </div> 
                    <div className="tc">
                      <p className="text"><a href="#">Terms and conditions</a> apply</p>
                      <ul>
                        <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                        <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution</li>
                        <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</li>
                        <li>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even</li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center">
                      <NavLink to="/payment" className="btn btn-custom">Purchase Plan</NavLink>
                    </div>
                  </div> 
                </div>          
              </div>
            </div>
          </section>
        </div>
      </div>
        )
    }
}
