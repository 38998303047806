import { useContext, createContext, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");

  const loginAction = async (data) => {
    try {
      setUser(data.user);
      setToken(data.token);
      localStorage.setItem("token", data.token);
      return;
    } catch (err) {
      console.error(err);
    }
  };

  const logOutAction = () => {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    const rememberMe = localStorage.getItem("rememberMe");
    localStorage.clear();
    if (rememberMe === "true") {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
      localStorage.setItem("rememberMe", rememberMe);
    }
    setUser(null);
    setToken("");
  };

  return (
    <AuthContext.Provider
      value={{ token, user, setUser, loginAction, logOutAction }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  let context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  
  return context;
};
